import {
  ARCHIVE_MANY_ROOM,
  CREATE_ROOM,
  DELETE_MANY_ROOM,
  DELETE_ONE_ROOM,
  GET_DETAIL_ROOM,
  GET_LIST_ROOM,
  SET_LOADING_ROOM,
  UNARCHIVE_MANY_ROOM,
  UPDATE_DETAIL_ROOM,
} from '../../constants';
import {
  IArchiveManyPayloadRoom,
  IDeleteManyPayloadRoom,
  IDeleteOnePayloadRoom,
  ILoadingRoom,
  IUnarchiveManyPayloadRoom,
} from '../types';

export const getListRoom = (payload: any) => {
  return {
    type: GET_LIST_ROOM,
    payload,
  };
};

export const getDetailRoom = (payload: any) => {
  return {
    type: GET_DETAIL_ROOM,
    payload,
  };
};

export const updateDetailRoom = (payload: any) => {
  return {
    type: UPDATE_DETAIL_ROOM,
    payload,
  };
};

export const createRoom = (payload: any) => {
  return {
    type: CREATE_ROOM,
    payload,
  };
};

export const deleteOneRoom = (payload: IDeleteOnePayloadRoom) => {
  return {
    type: DELETE_ONE_ROOM,
    payload,
  };
};

export const deleteManyRoom = (payload: IDeleteManyPayloadRoom) => {
  return {
    type: DELETE_MANY_ROOM,
    payload,
  };
};

export const archiveManyRoom = (payload: IArchiveManyPayloadRoom) => {
  return {
    type: ARCHIVE_MANY_ROOM,
    payload,
  };
};

export const unarchiveManyRoom = (payload: IUnarchiveManyPayloadRoom) => {
  return {
    type: UNARCHIVE_MANY_ROOM,
    payload,
  };
};

export const setLoadingRoom = (payload: ILoadingRoom) => {
  return {
    type: SET_LOADING_ROOM,
    payload,
  };
};
