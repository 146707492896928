import { useLocalStorageState } from "ahooks";
import { useCallback, useEffect, useState } from "react";
import {
  RootStateOrAny,
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import { NOTIFICATION } from "../../constants";
import { updateFilters, updateProfile } from "../../redux/actions/profile";
import { UserSetting } from "../../types";
import { SavedFilter } from "./SavedFilters";

export const usePresets = (page: string) => {
  const dispatch = useDispatch();
  const [presets, setPresets] = useState<SavedFilter[] | undefined>([]);
  // const [settings, setSettings] = useLocalStorageState<UserSetting[] | null>(
  //   "SETTINGS",
  //   undefined
  // );

  const settings: UserSetting[] = useSelector(
    (state: RootStateOrAny) => state?.profile?.data?.filters || [],
    shallowEqual
  );

  useEffect(() => {
    const preset = settings
      ?.filter((e) => e?.type === "preset" && e?.data.page === page)
      .map((e) => e.data as SavedFilter);
    setPresets(preset);
  }, [page, settings]);

  const savePreset = useCallback(
    (page: string, label: string, data: Record<string, any>) => {
      const setting: UserSetting = {
        id: String(Math.random() * 1000),
        type: "preset",
        data: {
          id: String(Math.random() * 1000),
          data,
          name: label,
          page,
        },
      };
      dispatch(updateFilters({ filters: [...settings, setting] }));

      // setSettings(settings ? [...settings, setting] : [setting]);

      // @TODO save this data to user settings
      dispatch({
        type: NOTIFICATION,
        payload: {
          title: `Lưu thành công`,
          message: `Đã lưu tìm kiếm với tên ${label}`,
          type: "success",
        },
      });
    },
    [dispatch, settings]
  );

  const deletePreset = useCallback(
    (id: string) => {
      // setSettings(settings?.filter((e) => e?.data.id !== id));
      dispatch(
        updateFilters({ filters: settings?.filter((e) => e?.data.id !== id) })
      );

      // @TODO save this data to user settings
      dispatch({
        type: NOTIFICATION,
        payload: {
          title: `Đã xoá`,
          message: ``,
          type: "success",
        },
      });
    },
    [dispatch, settings]
  );

  return { presets, savePreset, deletePreset };
};
