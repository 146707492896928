/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useNavigate, useParams } from "react-router-dom";
import size from "lodash/size";
import { useForm } from "react-hook-form";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
  htmlIdGenerator,
} from "@elastic/eui";
import { validation } from "../../../utils/validation";
import CustomizeForm from "../../../components/common/form";
import {
  getDetailPermission,
  updatePermission,
} from "../../../redux/actions/permission";
import { getListAction } from "../../../redux/actions/action";
import { ActionTypes, Paths } from "../../../constants";
import { Warning } from "../Warning";
interface IFormInput {
  name: string;
  key: string;
}

interface IAddAction {
  add: boolean;
  reload: boolean;
}

const Edit: FC = () => {
  const dispatch = useDispatch();
  const [action, setAction] = useState<any>([]);
  const [addAction, setAddAction] = useState<IAddAction>({
    add: false,
    reload: false,
  });
  const navigate = useNavigate();
  const { id }: any = useParams();

  const permissionDetail = useSelector(
    (state: RootStateOrAny) => state.permission.detail.permissions
  );

  const actionOfPermission = useSelector(
    (state: RootStateOrAny) => state.action.list.actions
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { name: "", key: "" },
    resolver: validation.permission.edit(action),
  });

  const resetFormWhenHasActionOfPermission = () => {
    if (actionOfPermission) {
      let typeReset: any = {};
      let newAction: any = [];
      for (let i = 0; i < size(actionOfPermission); i++) {
        const code = htmlIdGenerator()();
        typeReset[`name${code}`] = actionOfPermission[i].name;
        typeReset[`key${code}`] = actionOfPermission[i].key;
        typeReset[`url${code}`] = actionOfPermission[i].url;
        typeReset[`method${code}`] = actionOfPermission[i].method;
        newAction.push({
          isDelete: false,
          data: [
            { name: `name${code}`, label: "Tên", type: "input" },
            { name: `key${code}`, label: "Từ khóa", type: "input" },
            { name: `url${code}`, label: "Url", type: "input" },
            {
              name: `method${code}`,
              label: "Phương thức",
              type: "select",
              options: [
                { value: "GET", text: "GET" },
                { value: "POST", text: "POST" },
                { value: "PUT", text: "PUT" },
                { value: "PATCH", text: "PATCH" },
                { value: "DELETE", text: "DELETE" },
              ],
            },
          ],
        });
      }
      reset({
        name: permissionDetail.name,
        key: permissionDetail.key,
        ...typeReset,
      });
      setAction(newAction);
    }
  };

  useEffect(() => {
    resetFormWhenHasActionOfPermission();
  }, [actionOfPermission]);

  useEffect(() => {
    dispatch(getDetailPermission(id));
  }, []);

  useEffect(() => {
    if (!isEmpty(permissionDetail?.actionIds) && permissionDetail._id === id) {
      dispatch(
        getListAction({ ids: (permissionDetail?.actionIds || []).join(",") })
      );
    }
  }, [permissionDetail]);

  useEffect(() => {
    if (addAction.add) {
      let typeReset: any = {};
      for (let i = 0; i < size(action); i++) {
        typeReset[action[i].data[0].name] =
          control._fields[action[i].data[0].name]?._f.value;
        typeReset[action[i].data[1].name] =
          control._fields[action[i].data[1].name]?._f.value;
        typeReset[action[i].data[2].name] =
          control._fields[action[i].data[2].name]?._f.value;
        typeReset[action[i].data[3].name] =
          control._fields[action[i].data[3].name]?._f.value || "POST";
      }
      reset({
        name: control._fields.name?._f.value,
        key: control._fields.key?._f.value,
        ...typeReset,
      });
    }
  }, [addAction]);

  const checkActionValidate = (actions: any) => {
    if (!actions) {
      return false;
    }
    for (let i = 0; i < actions.length; i++) {
      if (
        !actions[i]?.key ||
        !actions[i]?.method ||
        !actions[i]?.name ||
        !actions[i]?.url
      ) {
        return false;
      }
    }

    return true;
  };

  const onSubmit = (data: any) => {
    const deleteIds = action
      .filter((item: any) => item.isDelete)
      .map((item: any) => `${item.data[0].name}`);
    const typeActions = action.reduce((result: any, item: any) => {
      if (!deleteIds.includes(item.data[0].name)) {
        result.push(`${item.data[0].name.slice(4, 100)}`);
      }
      return result;
    }, []);
    const actions = typeActions.reduce((result: any, item: any) => {
      result.push({
        key: data[`key${item}`],
        method: data[`method${item}`],
        name: data[`name${item}`],
        url: data[`url${item}`],
      });
      return result;
    }, []);

    if (!checkActionValidate(actions)) {
      return dispatch({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: "Có lỗi xảy ra",
          message: "Vui lòng nhập đầy đủ thông tin",
          type: "danger",
        },
      });
    }

    dispatch(
      updatePermission({
        permissionId: id,
        actionListing: actions,
        key: data.key,
        name: data.name,
      })
    );
  };

  const ars = [
    { name: "name", label: "Tên", type: "input" },
    { name: "key", label: "Từ khóa", type: "input" },
  ];

  const onAddAction = () => {
    const code = htmlIdGenerator()();
    setAction([
      ...action,
      {
        isDelete: false,
        data: [
          { name: `name${code}`, label: "Tên", type: "input" },
          { name: `key${code}`, label: "Từ khóa", type: "input" },
          { name: `url${code}`, label: "Url", type: "input" },
          {
            name: `method${code}`,
            label: "Phương thức",
            type: "select",
            options: [
              { value: "GET", text: "GET" },
              { value: "POST", text: "POST" },
              { value: "PUT", text: "PUT" },
              { value: "PATCH", text: "PATCH" },
              { value: "DELETE", text: "DELETE" },
            ],
          },
        ],
      },
    ]);
    setAddAction({ add: true, reload: !addAction.reload });
  };

  const onDeleteAction = (index: any) => {
    setAction(
      action.reduce((result: any, quote: any, key: number) => {
        result.push({
          ...quote,
          isDelete: key === index ? true : quote.isDelete,
        });
        return result;
      }, [])
    );
  };

  return (
    <>
      <Warning />
      <EuiSpacer size="s" />
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Quản lý quyền hạn"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.PERMISSION.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer size="s" />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {ars.map((item, index: number) => (
              <CustomizeForm
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
              />
            ))}
            <EuiFlexGroup direction="column">
              {action.map((item: any, iIndex: number) => (
                <>
                  {!item.isDelete && (
                    <EuiFlexGroup>
                      {item.data.map((quote: any, jIndex: number) => (
                        <EuiFlexItem grow={3}>
                          <CustomizeForm
                            key={jIndex}
                            name={quote.name}
                            control={control}
                            label={quote.label}
                            errors={errors}
                            type={quote.type}
                            options={quote.options}
                          />
                        </EuiFlexItem>
                      ))}
                      <EuiFlexItem
                        grow={1}
                        css={{ display: "flex", justifyContent: "end" }}
                      >
                        <EuiButton
                          size="s"
                          fill
                          color="danger"
                          onClick={() => onDeleteAction(iIndex)}
                        >
                          Xóa
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  )}
                </>
              ))}

              <EuiFlexItem grow={1}>
                <EuiButton size="s" fill color="primary" onClick={onAddAction}>
                  Thêm thao tác
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiButton type="submit">Lưu</EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Edit;
