/** @jsxImportSource @emotion/react */

import {
  EuiAvatar,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiHeaderLogo,
  EuiHeaderSectionItemButton,
  EuiPopover,
} from "@elastic/eui";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Images, Paths } from "../../constants";
import { routeBar } from "../../routes";
import { useAuthContext } from "../auth";
import SideLeft from "./SideLeft";

interface Props {
  navIsOpen: boolean;
  setNavIsOpen(isOpen: boolean): void;
}

interface Props {
  navIsOpen: boolean;
  setNavIsOpen(isOpen: boolean): void;
}

const getBreadcrumbs = (basepath: string) => {
  const parent = routeBar.find((e) =>
    e.children.find((i: any) => basepath.includes(i.basepath))
  );
  const child = parent?.children.find((e) => basepath.includes(e.basepath));

  return parent && child ? [parent, child] : undefined;
};

export const Header: React.FC<Props> = (props) => {
  const [openProfile, setOpenProfile] = useState(false);
  const { logout } = useAuthContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const breadcrumbs = getBreadcrumbs(
    matchPath({ path: location.pathname, end: false }, location.pathname)
      ?.pathnameBase ?? ""
  );

  const headerRightListing = (open: boolean, setOpen: any, dispatch: any) => [
    // {
    //   id: 1,
    //   component: (
    //     <EuiHeaderSectionItemButton
    //       aria-label="2 Notifications"
    //       notification={"2"}
    //     >
    //       <EuiIcon type="cheer" size="m" />
    //     </EuiHeaderSectionItemButton>
    //   ),
    // },
    {
      id: 2,
      component: (
        <EuiPopover
          button={
            <button onClick={() => setOpen(!open)}>
              <EuiAvatar name="AD" size="s" />
            </button>
          }
          isOpen={open}
          closePopover={() => setOpen(false)}
        >
          <EuiContextMenuPanel
            size="s"
            items={[
              <EuiContextMenuItem
                key="logout"
                onClick={() => navigate(Paths.PROFILE.DETAIL)}
              >
                Thông tin cá nhân
              </EuiContextMenuItem>,

              <EuiContextMenuItem
                style={{ minWidth: "160px" }}
                key="logout"
                onClick={logout}
              >
                Đăng xuất
              </EuiContextMenuItem>,
            ]}
          />
        </EuiPopover>
      ),
    },
  ];

  return (
    <>
      <EuiHeader
        theme="dark"
        position="fixed"
        sections={[
          {
            items: [
              <SideLeft
                setNavIsOpen={(isOpen: boolean) => props.setNavIsOpen(isOpen)}
                navIsOpen={props.navIsOpen}
              />,
              <EuiHeaderLogo iconType={Images.logoBrand}>
                Mỹ Thuật Bụi
              </EuiHeaderLogo>,
            ],
            borders: "right",
          },
          {
            items: [
              <EuiFlexGroup gutterSize="none">
                {headerRightListing(openProfile, setOpenProfile, dispatch).map(
                  (item, key) => (
                    <EuiFlexItem key={key}>{item.component}</EuiFlexItem>
                  )
                )}
              </EuiFlexGroup>,
            ],
          },
        ]}
      />
      <EuiHeader
        position="fixed"
        className="breadcrumb-nav"
        sections={[
          {
            items: [
              <EuiHeaderSectionItemButton aria-label="Account menu">
                <EuiAvatar type="space" name="My Thuat Bui" size="s" />
              </EuiHeaderSectionItemButton>,
            ],
            // @ts-ignore
            breadcrumbs: breadcrumbs,
            borders: "right",
          },
          // {
          //   items: [
          //     <EuiHeaderSectionItemButton aria-label="MTTB" notification={true}>
          //       <EuiIcon type="cheer" size="m" />
          //     </EuiHeaderSectionItemButton>,
          //   ],
          //   borders: "none",
          // },
        ]}
      />
    </>
  );
};
