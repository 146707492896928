import {
  ARCHIVE_MANY_SUBJECT,
  CREATE_SUBJECT,
  DELETE_MANY_SUBJECT,
  DELETE_ONE_SUBJECT,
  GET_DETAIL_SUBJECT,
  GET_LIST_SUBJECT,
  SET_LOADING_SUBJECT,
  UNARCHIVE_MANY_SUBJECT,
  UPDATE_DETAIL_SUBJECT,
} from '../../constants';
import {
  IArchiveManyPayloadSubject,
  IDeleteManyPayloadSubject,
  IDeleteOnePayloadSubject,
  ILoadingSubject,
  IUnarchiveManyPayloadSubject,
} from '../types';

export const getListSubject = (payload: any) => {
  return {
    type: GET_LIST_SUBJECT,
    payload,
  };
};

export const getDetailSubject = (payload: any) => {
  return {
    type: GET_DETAIL_SUBJECT,
    payload,
  };
};

export const updateDetailSubject = (payload: any) => {
  return {
    type: UPDATE_DETAIL_SUBJECT,
    payload,
  };
};

export const createSubject = (payload: any) => {
  return {
    type: CREATE_SUBJECT,
    payload,
  };
};

export const deleteOneSubject = (payload: IDeleteOnePayloadSubject) => {
  return {
    type: DELETE_ONE_SUBJECT,
    payload,
  };
};

export const deleteManySubject = (payload: IDeleteManyPayloadSubject) => {
  return {
    type: DELETE_MANY_SUBJECT,
    payload,
  };
};

export const archiveManySubject = (payload: IArchiveManyPayloadSubject) => {
  return {
    type: ARCHIVE_MANY_SUBJECT,
    payload,
  };
};

export const unarchiveManySubject = (payload: IUnarchiveManyPayloadSubject) => {
  return {
    type: UNARCHIVE_MANY_SUBJECT,
    payload,
  };
};

export const setLoadingSubject = (payload: ILoadingSubject) => {
  return {
    type: SET_LOADING_SUBJECT,
    payload,
  };
};
