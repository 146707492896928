import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import CustomizeForm from "../../../components/common/form";
import { validation } from "../../../utils";
import { Paths } from "../../../constants";
import { getEmployeesApi, getTeacherRolesApi } from "../../../network";
import { createTeacher } from "../../../redux";

interface IFormInput {
  employeeId: string;
  teacherRoleIds: string[];
}

interface IEmployee {
  _id: string;
  name: string;
}

interface ITeacherRole {
  _id: string;
  name: string;
}

const Create: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [teacherRoles, setTeacherRoles] = useState<ITeacherRole[]>([]);
  const teacherRoleState = useSelector(
    (state: RootStateOrAny) => state.teacherRole
  );

  const getListEmployee = async (searchValue?: string) => {
    const responseEmployee = await getEmployeesApi({ name: searchValue });
    if (responseEmployee && responseEmployee.data) {
      setEmployees(responseEmployee.data);
    }
  };

  const getListTeacherRole = async (searchValue?: string) => {
    const response = await getTeacherRolesApi({ name: searchValue });
    if (response && response.data) {
      setTeacherRoles(response.data);
    }
  };

  useEffect(() => {
    getListEmployee();
    getListTeacherRole();
  }, []);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>({
    defaultValues: {
      employeeId: "",
      teacherRoleIds: [],
    },
    resolver: validation.teacher.create(),
  });

  const onSubmit = (data: any) => {
    console.log("ss: ", {
      ...data,
    });
    dispatch(createTeacher(data));
  };

  const formInput = [
    {
      name: "employeeId",
      label: "Nhân viên quản lý",
      type: "selectSearch",
      required: true,
      fullwidth: true,
      mode: "single",
      options: employees.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      onSearchChange: getListEmployee,
      onChangeValue: (value: string) => {
        setValue("employeeId", value);
      },
    },
    {
      name: "teacherRoleIds",
      label: "Phân môn giảng dạy",
      type: "selectSearch",
      mode: "multiple",
      required: true,
      fullwidth: true,
      options: teacherRoles.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      onSearchChange: getListTeacherRole,
      onChangeValue: (value: string[]) => {
        setValue("teacherRoleIds", value);
      },
    },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Quản lý giáo viên"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.TEACHER.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formInput.map((item: any, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton
              isLoading={teacherRoleState?.isCreateRequest || false}
              type="submit"
              fill
            >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Create;
