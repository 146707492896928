export const en = {
  common: {
    login: 'Login',
    editAction: {
      title: 'Edit',
    },
    deleteAction: {
      title: 'Delete',
      confirmText: 'Are you sure delete?',
      confirmButtonYes: 'Yes',
      confirmButtonNo: 'No',
      deleteSuccess: 'Delete success',
    },
    deleteManyAction: {
      title: 'Delete Success',
      message: 'Delete success',
      buttonText: 'Delete {number} items',
    },
    archiveManyAction: {
      title: 'Archive success',
      message: 'Archive success',
      buttonText: 'Archive {number} items',
    },
    unarchiveManyAction: {
      title: 'Unarchive success',
      message: 'Unarchive success',
      buttonText: 'Unarchive {number} items',
    },
    error: 'Error',
    saveAction: {
      title: 'Save',
      saveSuccess: 'Save success',
    },
    createButton: 'Create',
  },
  auth: {
    loginTitle: 'Login',
    welcomeMessage: "Welcome to Mỹ thuật Bụi",
    rememberAccount: "Remember this account",
    forgotPasswordText: 'Forgot password',
    username: {
      title: 'Username',
      placeholder: 'Username',
    },
    password: {
      title: 'Password',
      placeholder: 'Your Password',
    },
    notification: {
      loginSuccess: 'Login success',
      loginSuccessMessage: 'Wellcome to My Thuat Bui ERP',
      loginFail: 'Login fail',
    },
    error: {
      username: 'Username invalid',
      password: 'Password must be greater than 6',
    },
    forgotPassword: {
      titleSuccess: 'Successfully',
      messageSuccess: 'Mỹ Thuật Bụi will be send link reset password to your email.',
      titleFail: 'Failed',
    },
    resetPassword: {
      titleSuccess: 'Update success',
      messageSuccess: '',
      titleFail: 'Update fail',
    }
  },
  menus: {
    dashboard: {
      name: 'Dashboard',
    },
    user: {
      name: 'Users',
    },
    role: {
      name: 'Roles',
    },
    permission: {
      name: 'Permissions',
    }
  },
  header: {
    task: "Tasks",
    chat: "Chat",
    email: 'Email',
    calendar: 'Calendar',
    menus: {
      setting: 'Setting',
      chat: 'Chat',
      gallery: 'Gallery',
      search: 'Search',
      logout: 'Logout',
    },
  },
  customDataGrid: {
    deleteMany: {
      text: 'DELETE',
      confirmText: 'Are you sure?',
    },
    archiveMany: {
      text: 'ARCHIVE',
      confirmText: 'Are you sure?',
    },
    unarchiveMany: {
      text: 'UNARCHIVE',
      confirmText: 'Are you sure?',
    },
  },
  user: {
    filter: {
      button: 'Filter',
      email: 'Email',
      name: 'Name',
      role: 'Role',
      phone: 'Phone',
      status: 'Status',
    },
    list: {
      title: 'Users',
      codeCol: 'Code',
      nameCol: 'Name',
      usernameCol: 'Username',
      emailCol: 'Email',
      phoneCol: 'Phone',
      roleCol: 'Role',
      statusCol: 'Status',
      isArchivedCol: 'Is Archived',
      createdAtCol: 'Created At',
      actionCol: 'Actions',
    },
    createButton: 'Create',
    create: {
      title: 'Create User',
      nameLabel: 'Name',
      usernameLabel: 'Username',
      emailLabel: 'Email',
      phoneLabel: 'Phone',
      typeLabel: 'Type',
      statusLabel: 'Status',
      roleLabel: 'Role',
      passwordLabel: 'Password',
      confirmPasswordLabel: 'Confirm Password',
    }
  },
/*   room: {
    filter: {
      button: 'Filter',
      email: 'Email',
      name: 'Name',
      role: 'Role',
      phone: 'Phone',
      status: 'Status',
    },
    list: {
      title: 'Users',
      codeCol: 'Code',
      nameCol: 'Name',
      usernameCol: 'Username',
      emailCol: 'Email',
      phoneCol: 'Phone',
      roleCol: 'Role',
      statusCol: 'Status',
      isArchivedCol: 'Is Archived',
      createdAtCol: 'Created At',
      actionCol: 'Actions',
    },
    createButton: 'Create',
    create: {
      title: 'Create User',
      nameLabel: 'Name',
      usernameLabel: 'Username',
      emailLabel: 'Email',
      phoneLabel: 'Phone',
      typeLabel: 'Type',
      statusLabel: 'Status',
      roleLabel: 'Role',
      passwordLabel: 'Password',
      confirmPasswordLabel: 'Confirm Password',
    }
  }, */
  role: {
    filter: {
      button: 'Filter',
      name: 'Name',
    },
    list: {
      title: 'Roles',
      nameCol: 'Name',
      descriptionCol: 'Description',
      createdAtCol: 'Created At',
      isArchivedCol: 'Is Archived',
      actionCol: 'Actions',
    },
    create: {
      title: 'Create Role',
      nameLabel: 'Name',
      descriptionLabel: 'Description',
      permissionsLabel: 'Permissions',
    },
    editTitle: 'Edit Role',
  },
  permission: {
    filter: {
      button: 'Filter',
      name: 'Name',
      key: 'Key',
    },
    list: {
      title: 'Permission',
      nameCol: 'Name',
      keyCol: 'Key',
      descriptionCol: 'Description',
      createdAtCol: 'Created At',
      isArchivedCol: 'Is Archived',
      actionCol: 'Actions',
    },
    create: {
      title: 'Create Permission',
      nameLabel: 'Name',
      keyLabel: 'Key',
      descriptionLabel: 'Description',
      actionIdsLabel: 'Actions',

      actionNameLabel: 'Name',
      actionKeyLabel: 'Key',
      actionUrlLabel: 'Url',
      actionMethodLabel: 'Method',

      noActionMessage: 'Please add a action',
      enterValueToActionMessage: 'Please enter value to action',
    },
    editTitle: 'Edit Permission',
    addNewActionButton: 'Add action',
  }
}
