import i18n from "i18n-js";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { ActionTypes, Paths } from "../../constants";
import * as actionTypes from "../../constants/ActionTypes";
import history from "../../navigation";
import {
  archiveManyDepartmentApi,
  createDepartmentApi,
  deleteManyDepartmentApi,
  deleteDepartmentApi,
  getDepartmentDetailApi,
  getDepartmentsApi,
  updateDepartmentApi,
} from "../../network";
import { convertObjectParamsToStringParams, getInitParams } from "../../utils";
import { getDepartmentState } from "../selectors";
import { IDepartmentState, IRoleState } from "../types";

interface IPayload {
  type: string;
  payload: any;
}

interface IResponse {
  data: any;
  total: number;
}

function* getListDepartment(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const department: IRoleState = yield select<any>(getDepartmentState);

    // validate and set requesting
    if (department.isLoadingRequest) return;
    yield put({
      type: ActionTypes.SET_LOADING_ROLE,
      payload: { isLoadingRequest: true },
    });

    const { data, total }: IResponse = yield call(
      getDepartmentsApi,
      action.payload
    );
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`);
    yield put({
      type: ActionTypes.GET_LIST_DEPARTMENT_SUCCESS,
      payload: { data, total },
    });
  } catch (error) {
    yield put({ type: ActionTypes.GET_LIST_DEPARTMENT_FAILED, payload: error });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isLoadingRequest: false },
    });
  }
}

function* createDepartment(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const department: IDepartmentState = yield select<any>(getDepartmentState);

    // validate and set requesting
    if (department.isCreateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isCreateRequest: true },
    });

    // call api create
    const { data, total }: IResponse = yield call(
      createDepartmentApi,
      action.payload
    );

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    });

    // navigate
    history.push("/departments");
  } catch (error) {
    yield put({ type: actionTypes.CREATE_DEPARTMENT_FAILED, payload: error });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isCreateRequest: false },
    });
  }
}

function* deleteOneDepartment(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const department: IDepartmentState = yield select<any>(getDepartmentState);

    // validate and set requesting
    if (department.isDeleteRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isDeleteRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      deleteDepartmentApi,
      action.payload.id
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_DEPARTMENT,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isDeleteRequest: false },
    });
  }
}

function* updateDetailDepartment(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const department: IDepartmentState = yield select<any>(getDepartmentState);

    // validate and set requesting
    if (department.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isUpdateRequest: true },
    });

    // call api update
    const { data, total }: IResponse = yield call(
      updateDepartmentApi,
      action.payload.departmentId,
      action.payload.data
    );

    // yield put({
    //   type: actionTypes.UPDATE_DETAIL_USER_SUCCESS,
    // });

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    });
    history.push(Paths.DEPARTMENT.LIST);
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_DETAIL_DEPARTMENT_FAILED,
      payload: error,
    });
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isUpdateRequest: false },
    });
  }
}

function* getDetailDepartment(action: IPayload) {
  try {
    const { data, total }: IResponse = yield call(
      getDepartmentDetailApi,
      action.payload
    );

    yield put({
      type: actionTypes.GET_DETAIL_DEPARTMENT_SUCCESS,
      payload: { data, total },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DETAIL_DEPARTMENT_FAILED,
      payload: error,
    });
  }
}

function* deleteManyDepartment(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const department: IDepartmentState = yield select<any>(getDepartmentState);

    // validate and set requesting
    if (department.isDeleteRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isDeleteRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      deleteManyDepartmentApi,
      action.payload.ids
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_DEPARTMENT,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    console.log(error);
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isDeleteRequest: false },
    });
  }
}

function* archiveManyDepartment(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const department: IDepartmentState = yield select<any>(getDepartmentState);

    // validate and set requesting
    if (department.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isUpdateRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      archiveManyDepartmentApi,
      action.payload
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_DEPARTMENT,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    console.log(error);
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isUpdateRequest: false },
    });
  }
}

function* unarchiveManyDepartment(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const department: IDepartmentState = yield select<any>(getDepartmentState);

    // validate and set requesting
    if (department.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isUpdateRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      archiveManyDepartmentApi,
      action.payload
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_DEPARTMENT,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    console.log(error);
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_DEPARTMENT,
      payload: { isUpdateRequest: false },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_LIST_DEPARTMENT, getListDepartment),
    takeLatest(actionTypes.GET_DETAIL_DEPARTMENT, getDetailDepartment),
    takeLatest(ActionTypes.CREATE_DEPARTMENT, createDepartment),
    takeLatest(actionTypes.DELETE_ONE_DEPARTMENT, deleteOneDepartment),
    takeLatest(actionTypes.UPDATE_DETAIL_DEPARTMENT, updateDetailDepartment),
    takeLatest(actionTypes.DELETE_MANY_DEPARTMENT, deleteManyDepartment),
    takeLatest(actionTypes.ARCHIVE_MANY_DEPARTMENT, archiveManyDepartment),
    takeLatest(actionTypes.UNARCHIVE_MANY_DEPARTMENT, unarchiveManyDepartment),
  ]);
}
