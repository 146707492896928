import i18n from "i18n-js";
import React, { useEffect, useRef, useState } from "react";
import {
  EuiBasicTable,
  EuiButton,
  EuiFlexGroup,
  EuiTablePagination,
} from "@elastic/eui";

interface CustomizeTableProps {
  columns: Array<any>;
  body: Array<any>;
  pagination: {
    limit: number;
    offset: number;
  };
  total: number;
  itemPerPageOptions?: Array<number>;
  isSelectable?: boolean;
  isLoading?: boolean;

  onChangePage?: (offset: number) => void;
  onChangeItemsPerPage?: (pageSize: number) => void;
  onSortChange?: (sort: any) => void;
  onChange?: (data: any) => void;
  onSelectionChange?: (ids: string[]) => void;
  onDeleteMany?: (ids: string[]) => void;
  onArchiveMany?: (ids: string[]) => void;
  onUnarchiveMany?: (ids: string[]) => void;
}

export default function CustomizeTablePagination(props: CustomizeTableProps) {
  const [sortField, setSortField] = useState("code");
  const [sortDirection, setSortDirection] = useState("desc");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const tableRef = useRef();

  const onChangePage = (page: number) => {
    if (props.onChangePage) props.onChangePage(page * props.pagination.limit);
  };

  const onChangeItemsPerPage = (pageSize: number) => {
    if (props.onChangeItemsPerPage) props.onChangeItemsPerPage(pageSize);
  };

  const onChange = (data: any) => {
    const { page, sort } = data;

    if (sort) {
      setSortField(sort.field);
      setSortDirection(sort.direction);
    }

    if (props.onSortChange) {
      props.onSortChange(sort);
    }

    if (props.onChange) {
      props.onChange({ page, sort });
    }
  };

  const onSelectionChange = (items: any) => {
    const ids = items.map((item: any) => item._id.toString());
    setSelectedIds(ids);
    setSelectedItems(items);

    if (props.onSelectionChange) {
      props.onSelectionChange(ids);
    }
  };

  const onClickDelete = () => {
    if (props.onDeleteMany) {
      props.onDeleteMany(selectedIds);
    }

    setSelectedIds([]);
    setSelectedItems([]);
    // @ts-ignore
    tableRef.current.clearSelection();
  };

  const onClickArchive = () => {
    if (props.onArchiveMany) {
      props.onArchiveMany(selectedIds);
    }

    setSelectedIds([]);
    setSelectedItems([]);
    // @ts-ignore
    tableRef.current.clearSelection();
  };

  const onClickUnarchive = () => {
    if (props.onUnarchiveMany) {
      props.onUnarchiveMany(selectedIds);
    }

    setSelectedIds([]);
    setSelectedItems([]);
    // @ts-ignore
    tableRef.current.clearSelection();
  };

  // render
  const renderTableHeader = () => {
    if (selectedItems.length === 0) {
      return <></>;
    }

    return (
      <div
        style={{
          marginBottom: 10,
          marginTop: 0,
          display: "flex",
          justifyContent: "end",
        }}
      >
        <EuiFlexGroup justifyContent={'flexEnd'}>
        <EuiButton color="danger" iconType="trash" onClick={onClickDelete}>
          {i18n
            .t("common.deleteManyAction.buttonText")
            .replace("{number}", (selectedItems?.length || 0).toString())}
        </EuiButton>

        <EuiButton
          style={{ marginLeft: 10 }}
          color="primary"
          iconType="logstashInput"
          onClick={onClickArchive}
        >
          {i18n
            .t("common.archiveManyAction.buttonText")
            .replace("{number}", (selectedItems?.length || 0).toString())}
        </EuiButton>

        <EuiButton
          style={{ marginLeft: 10 }}
          color="success"
          iconType="logstashOutput"
          onClick={onClickUnarchive}
        >
          {i18n
            .t("common.unarchiveManyAction.buttonText")
            .replace("{number}", (selectedItems?.length || 0).toString())}
        </EuiButton>
        </EuiFlexGroup>
      </div>
    );
  };

  useEffect(() => {}, [sortField, sortDirection, selectedIds, selectedItems]);

  return (
    <EuiFlexGroup direction="column">
      {renderTableHeader()}

      <EuiBasicTable
        // @ts-ignore
        ref={tableRef}
        items={props.body}
        itemId={"_id"}
        columns={props.columns}
        loading={props.isLoading || false}
        style={{
          width: "auto",
          overflowX: "scroll",
          maxHeight: `70vh`,
          overflow: "scroll",
        }}
        // @ts-ignore
        sorting={{ sort: { field: sortField, direction: sortDirection } }}
        onChange={onChange}
        isSelectable={props.isSelectable || undefined}
        selection={{
          onSelectionChange,
        }}
      />
      <EuiTablePagination
        pageCount={Math.ceil(props.total / Number(props.pagination.limit))}
        activePage={
          Number(props.pagination.offset) / Number(props.pagination.limit)
        }
        onChangePage={onChangePage}
        itemsPerPageOptions={props.itemPerPageOptions || [10, 20, 50]}
        onChangeItemsPerPage={onChangeItemsPerPage}
        itemsPerPage={Number(props.pagination.limit)}
      />
    </EuiFlexGroup>
  );
}
