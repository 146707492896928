import {
  ARCHIVE_MANY_EMPLOYEE,
  CREATE_EMPLOYEE,
  DELETE_MANY_EMPLOYEE,
  DELETE_ONE_EMPLOYEE,
  GET_DETAIL_EMPLOYEE,
  GET_LIST_EMPLOYEE,
  SET_LOADING_EMPLOYEE,
  UNARCHIVE_MANY_EMPLOYEE,
  UPDATE_DETAIL_EMPLOYEE,
} from "../../constants";
import {
  IArchiveManyPayloadEmployee,
  IDeleteManyPayloadEmployee,
  IDeleteOnePayloadEmployee,
  ILoadingEmployee,
  IUnarchiveManyPayloadEmployee,
} from "../types";

export const getListEmployee = (payload: any) => {
  return {
    type: GET_LIST_EMPLOYEE,
    payload,
  };
};

export const getDetailEmployee = (payload: any) => {
  return {
    type: GET_DETAIL_EMPLOYEE,
    payload,
  };
};

export const updateDetailEmployee = (payload: any) => {
  return {
    type: UPDATE_DETAIL_EMPLOYEE,
    payload,
  };
};

export const createEmployee = (payload: any) => {
  return {
    type: CREATE_EMPLOYEE,
    payload,
  };
};

export const deleteOneEmployee = (payload: IDeleteOnePayloadEmployee) => {
  return {
    type: DELETE_ONE_EMPLOYEE,
    payload,
  };
};

export const deleteManyEmployee = (payload: IDeleteManyPayloadEmployee) => {
  return {
    type: DELETE_MANY_EMPLOYEE,
    payload,
  };
};

export const archiveManyEmployee = (payload: IArchiveManyPayloadEmployee) => {
  return {
    type: ARCHIVE_MANY_EMPLOYEE,
    payload,
  };
};

export const unarchiveManyEmployee = (payload: IUnarchiveManyPayloadEmployee) => {
  return {
    type: UNARCHIVE_MANY_EMPLOYEE,
    payload,
  };
};

export const setLoadingEmployee = (payload: ILoadingEmployee) => {
  return {
    type: SET_LOADING_EMPLOYEE,
    payload,
  };
};
