import { EuiButton, EuiPageHeader } from "@elastic/eui"
import { useNavigate } from "react-router-dom";
import { IPageHeader } from "./typing"

const CustomBackPage: React.FC<IPageHeader> = (props) => {
  const { pageClassName, pageTitle, pagePath, childContent, ...rest } = props
  const navigate = useNavigate();
  return <EuiPageHeader
    bottomBorder
    className={pageClassName || "mtb-page-header"}
    pageTitle={pageTitle}
    rightSideItems={[
      <EuiButton
        iconSide="left"
        iconType="arrowLeft"
        onClick={() => navigate(pagePath)}
      >
        {childContent || "Quay lại"}
      </EuiButton>,
    ]}
    {...rest}
  />
}

export default CustomBackPage;