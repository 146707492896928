/* eslint-disable react-hooks/exhaustive-deps */
import { EuiBadge, EuiButton, EuiPageHeader, EuiSpacer } from "@elastic/eui";
import i18n from "i18n-js";
import { FC, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomizeFilter,
  CustomizeTablePagination,
  DeleteAction,
  EditAction,
  FilterInput,
  Access,
} from "../../../components";
import { Paths, PERMISSIONS } from "../../../constants";
import {
  archiveManyDepartment,
  deleteManyDepartment,
  deleteOneDepartment,
  getListDepartment,
  unarchiveManyDepartment,
} from "../../../redux/actions/department";
import { getInitParams, formatToLocalDate } from "../../../utils";

const List: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState(getInitParams());

  useEffect(() => {
    dispatch(getListDepartment(params));
  }, [params]);

  const { departments, total } = useSelector(
    (state: RootStateOrAny) => state?.department?.list
  );

  const { isLoadingRequest } = useSelector(
    (state: RootStateOrAny) => state.department
  );

  const onChangePage = (value: any) => {
    setParams({ ...params, offset: value });
  };

  const onDeleteMany = (ids: string[]) => {
    dispatch(deleteManyDepartment({ ids }));
  };

  const onArchiveMany = (ids: string[]) => {
    dispatch(archiveManyDepartment({ ids, isArchived: true }));
  };

  const onUnarchiveMany = (ids: string[]) => {
    dispatch(unarchiveManyDepartment({ ids, isArchived: false }));
  };

  const columns = [
    {
      field: "name",
      name: "Tên",
      sortable: false,
    },
    // {
    //   field: "parentDepartmentId",
    //   name: "Thuộc phòng ban",
    // },
    // {
    //   field: "managerUserId",
    //   name: "Quản lý bởi",
    //   sortable: false,
    // },
    {
      field: "isDefault",
      name: "Phòng ban mặc định",
      sortable: false,
      render: (isDefault: boolean) => <>{isDefault ? "Có" : "Không"}</>,
    },
    {
      field: "isSpecialize",
      name: "Phòng ban chuyên môn",
      sortable: false,
      render: (isSpecialize: boolean) => <>{isSpecialize ? "Có" : "Không"}</>,
    },
    {
      field: "isOperator",
      name: "Phòng ban vận hành",
      sortable: false,
      render: (isOperator: boolean) => <>{isOperator ? "Có" : "Không"}</>,
    },
    {
      field: "isArchived",
      name: "Lưu trữ",
      sortable: false,
      render: (isArchived: boolean) => (
        <EuiBadge color={isArchived ? "warning" : "ghost"}>
          {isArchived ? "Đã lưu trữ" : "Không"}
        </EuiBadge>
      ),
    },
    {
      field: "createdAt",
      name: "Khởi tạo",
      render: (time: string) => <p>{formatToLocalDate(time)}</p>,
    },
    {
      name: "Hành động",
      actions: [
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.DEPARTMENT_UPDATE}>
                <EditAction
                  link={Paths.DEPARTMENT.EDIT.replace(":id", item._id)}
                />
              </Access>
            );
          },
        },
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.DEPARTMENT_DELETE}>
                <DeleteAction
                  onOk={() => {
                    dispatch(deleteOneDepartment({ id: item._id }));
                  }}
                />
              </Access>
            );
          },
        },
      ],
    },
  ];

  const onChangeItemsPerPage = (per: any) => {
    setParams({ ...params, limit: per });
  };

  const filterInputs: FilterInput[] = [
    { name: "name", label: "Tên", type: "input", visible: true },
    // { name: "managerUserId", label: "Quản lý bởi", type: "select", visible: true },
    // { name: "parentDepartmentId", label: "Thuộc phòng ban", type: "select", visible: true },
    {
      name: "isDefault",
      label: "Phòng ban mặc định",
      type: "select",
      options: [
        { value: "", text: "" },
        { value: true, text: "Có" },
        { value: false, text: "Không" },
      ],
    },
    {
      name: "isSpecialize",
      label: "Phòng ban chuyên môn",
      type: "select",
      options: [
        { value: "", text: "" },
        { value: true, text: "Có" },
        { value: false, text: "Không" },
      ],
    },
    {
      name: "isOperator",
      label: "Phòng ban vận hành",
      type: "select",
      options: [
        { value: "", text: "" },
        { value: true, text: "Có" },
        { value: false, text: "Không" },
      ],
    },
    {
      name: "isArchived",
      label: "Lưu trữ",
      type: "select",
      options: [
        { value: "", text: "Tất cả" },
        { value: "true", text: "Đã lưu trữ" },
        { value: "false", text: "Không" },
      ],
    },
  ];

  const onFilter = (data: any) => {
    const newFilter: any = { ...params, ...data };
    for (let key in newFilter) {
      if (newFilter[key] === "" || newFilter[key] === undefined) {
        delete newFilter[key];
      }
    }
    setParams({ ...newFilter, offset: 0 });
  };

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Quản lý phòng ban"
        rightSideItems={[
          <Access permissionKey={PERMISSIONS.DEPARTMENT_CREATE}>
            <EuiButton
              iconSide="left"
              iconType="plusInCircle"
              fill
              onClick={() => navigate({ pathname: Paths.DEPARTMENT.CREATE })}
            >
              {i18n.t("common.createButton")}
            </EuiButton>
          </Access>,
        ]}
      />
      <EuiSpacer size="s" />

      <CustomizeFilter
        list={filterInputs}
        onFilter={onFilter}
        page={Paths.DEPARTMENT.LIST}
      />

      <EuiSpacer size="s" />

      <CustomizeTablePagination
        columns={columns}
        body={departments}
        isLoading={isLoadingRequest}
        pagination={params}
        total={total}
        isSelectable={true}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onDeleteMany={onDeleteMany}
        onArchiveMany={onArchiveMany}
        onUnarchiveMany={onUnarchiveMany}
      />
    </>
  );
};

export default List;
