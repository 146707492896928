/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import size from "lodash/size";
import { useForm } from "react-hook-form";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
  htmlIdGenerator,
} from "@elastic/eui";
import { validation } from "../../../utils/validation";
import CustomizeForm from "../../../components/common/form";
import { createPermission } from "../../../redux/actions/permission";
import { ActionTypes, Paths } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { Warning } from "../Warning";

interface IFormInput {
  name: string;
  nameSearch: string;
}

const Create: FC = () => {
  const dispatch = useDispatch();
  const [action, setAction] = useState<any>([
    {
      isDelete: false,
      data: [
        { name: `name0`, label: "Tên", type: "input" },
        { name: `key0`, label: "Từ khóa", type: "input" },
        { name: `url0`, label: "Url", type: "input" },
        {
          name: `method0`,
          label: "Phương thức",
          type: "select",
          options: [
            { value: "GET", text: "GET" },
            { value: "POST", text: "POST" },
            { value: "PUT", text: "PUT" },
            { value: "PATCH", text: "PATCH" },
            { value: "DELETE", text: "DELETE" },
          ],
        },
      ],
    },
  ]);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { name: "", nameSearch: "" },
    resolver: validation.permission.create(action),
  });

  useEffect(() => {
    let typeReset: any = {};

    for (let i = 0; i < size(action); i++) {
      typeReset[action[i].data[0].name] =
        control._fields[action[i].data[0].name]?._f.value;
      typeReset[action[i].data[1].name] =
        control._fields[action[i].data[1].name]?._f.value;
      typeReset[action[i].data[2].name] =
        control._fields[action[i].data[2].name]?._f.value;
      typeReset[action[i].data[3].name] =
        control._fields[action[i].data[3].name]?._f.value || "POST";
    }
    reset({
      name: control._fields.name?._f.value,
      nameSearch: control._fields.nameSearch?._f.value,
      ...typeReset,
    });
  }, [action]);

  const checkActionValidate = (actions: any) => {
    console.log("aa: ", actions);
    if ((actions?.length || 0) === 0) {
      return false;
    }

    for (let i = 0; i < actions.length; i++) {
      if (
        actions[i]?.key === undefined ||
        actions[i]?.method === undefined ||
        actions[i]?.name === undefined ||
        actions[i]?.url === undefined
      ) {
        return false;
      }
    }

    return true;
  };

  const onSubmit = (data: any) => {
    const deleteIds = action
      .filter((item: any) => item.isDelete)
      .map((item: any) => `${item.data[0].name}`);
    const typeActions = action.reduce((result: any, item: any) => {
      if (!deleteIds.includes(item.data[0].name)) {
        result.push(`${item.data[0].name.slice(4, 100)}`);
      }
      return result;
    }, []);
    const actions = typeActions.reduce((result: any, item: any) => {
      result.push({
        key: data[`key${item}`],
        method: data[`method${item}`],
        name: data[`name${item}`],
        url: data[`url${item}`],
      });
      return result;
    }, []);

    if (!checkActionValidate(actions)) {
      return dispatch({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: "Có lỗi xảy ra",
          message: "Vui lòng nhập đầy đủ thông tin",
          type: "danger",
        },
      });
    }
    dispatch(
      createPermission({ actions, key: data.nameSearch, name: data.name })
    );
  };

  const ars = [
    { name: "name", label: "Tên", type: "input" },
    { name: "nameSearch", label: "Từ khóa", type: "input" },
  ];

  const onAddAction = () => {
    const code = htmlIdGenerator()();
    setAction([
      ...action,
      {
        isDelete: false,
        data: [
          { name: `name${code}`, label: "Tên", type: "input" },
          { name: `key${code}`, label: "Từ khóa", type: "input" },
          { name: `url${code}`, label: "Url", type: "input" },
          {
            name: `method${code}`,
            label: "Phương thức",
            type: "select",
            options: [
              { value: "GET", text: "GET" },
              { value: "POST", text: "POST" },
              { value: "PUT", text: "PUT" },
              { value: "PATCH", text: "PATCH" },
              { value: "DELETE", text: "DELETE" },
            ],
          },
        ],
      },
    ]);
  };

  const onDeleteAction = (index: any) => {
    setAction(
      action.reduce((result: any, quote: any, key: number) => {
        console.log("q: ", quote);
        result.push({
          ...quote,
          isDelete: key === index ? true : quote.isDelete,
        });
        return result;
      }, [])
    );
  };

  return (
    <>
      <Warning />
      <EuiSpacer size="s" />
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Quản lý quyền hạn"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.PERMISSION.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer size="s" />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {ars.map((item, index: number) => (
              <CustomizeForm
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
              />
            ))}
            <EuiFlexGroup direction="column">
              {action.map((item: any, iIndex: number) => {
                return (
                  <>
                    {!item.isDelete && (
                      <EuiFlexGroup>
                        {item.data.map((quote: any, jIndex: number) => {
                          return (
                            <EuiFlexItem grow={3}>
                              <CustomizeForm
                                key={jIndex}
                                name={quote.name}
                                control={control}
                                label={quote.label}
                                errors={errors}
                                type={quote.type}
                                options={quote.options}
                              />
                            </EuiFlexItem>
                          );
                        })}
                        <EuiFlexItem
                          grow={1}
                          css={{ display: "flex", justifyContent: "end" }}
                        >
                          <EuiButton
                            size="s"
                            fill
                            color="danger"
                            onClick={() => onDeleteAction(iIndex)}
                          >
                            Xóa
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    )}
                  </>
                );
              })}

              <EuiFlexItem grow={1}>
                <EuiButton size="s" fill color="primary" onClick={onAddAction}>
                  Thêm thao tác
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiButton type="submit">Lưu</EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Create;