import { getRequest, postRequest } from "../http";

export const getProfileApi = async () => {
  const url = "/profile";
  return await getRequest(url);
};

export const updateProfileApi = async (data: object) => {
  const url = "/profile";
  return await postRequest(url, data);
};
