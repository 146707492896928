import {
  EuiButton,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiPopover,
  useGeneratedHtmlId,
} from "@elastic/eui";
import i18n from "i18n-js";
import React, { BaseSyntheticEvent, FC, useState } from "react";

type Data = Record<string, any>;

export interface SavedFilter {
  id: string;
  page: string;
  name: string;
  data: Data;
}

interface Props {
  onSave: () => void;
  onDelete: (id: string) => void;
  savedFilters: SavedFilter[] | never[];
  onFilter: (data: Data) => void;
}

export const SavedFilters: FC<Props> = (props) => {
  const [isPopoverOpen, setPopover] = useState(false);

  const singleContextMenuPopoverId = useGeneratedHtmlId({
    prefix: "singleContextMenuPopover",
  });

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const button = (
    <EuiButton iconType="starFilled" onClick={onButtonClick}>
      Yêu thích
    </EuiButton>
  );

  const items = [
    ...(props.savedFilters?.map((f) => {
      return (
        <EuiContextMenuItem
          key={f.id}
          icon="empty"
          onClick={() => {
            props.onFilter(f.data);
            closePopover();
          }}
          style={{ minWidth: "100px" }}
        >
          <span style={{ flex: 1, paddingRight: "10px" }}>{f.name}</span>

          <EuiButtonIcon
            iconType={"trash"}
            onClick={(event: BaseSyntheticEvent) => {
              event.preventDefault();
              event.stopPropagation();
              props.onDelete(f.id);
              closePopover();
            }}
            title={i18n.t("common.deleteAction.title")}
            aria-label={i18n.t("common.deleteAction.title")}
            color="text"
            display="base"
          ></EuiButtonIcon>
        </EuiContextMenuItem>
      );
    }) ?? []),
    <EuiContextMenuItem
      key="saveFilter"
      icon="importAction"
      onClick={() => {
        closePopover();
        props.onSave();
      }}
    >
      Lưu tìm kiếm hiện tại
    </EuiContextMenuItem>,
  ];

  return (
    <EuiPopover
      id={singleContextMenuPopoverId}
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiContextMenuPanel
        className="mtb-saved-filters"
        size="s"
        items={items}
      />
    </EuiPopover>
  );
};
