import { REQUEST_ERROR } from '../../constants';

export class RequestError extends Error {
  data: any;
  isLogAble: boolean;
  code: number = REQUEST_ERROR;

  constructor(message: string, name: string, data: any) {
    super(message);
    this.name = name;
    this.data = data;
    this.isLogAble = true;
  }
}
