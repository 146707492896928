import { UNAUTHORIZED } from '../../constants';

export class Unauthorized extends Error {
  data: any;
  isLogAble: boolean;
  code: number = UNAUTHORIZED;

  constructor(message: string, name: string, data: string) {
    super(message);
    this.name = name;
    this.data = data;
    this.isLogAble = false;
  }
}
