import {
  ARCHIVE_MANY_ROLE,
  CREATE_ROLE,
  DELETE_MANY_ROLE,
  DELETE_ONE_ROLE,
  GET_DETAIL_ROLE,
  GET_LIST_ROLE,
  SET_LOADING_ROLE,
  UNARCHIVE_MANY_ROLE,
  UPDATE_ROLE,
  GET_DETAIL_ACTION_ROLE,
  GET_DETAIL_PERMISSION_ROLE,
} from '../../constants';
import {
  IArchiveManyPayloadRole,
  IDeleteManyPayloadRole,
  IDeleteOnePayloadRole,
  ILoadingRole,
  IUnarchiveManyPayloadRole,
} from '../types';

export const getListRoles = (payload: any) => {
  return {
    type: GET_LIST_ROLE,
    payload,
  };
};

export const getDetailRole = (payload: any) => {
  return {
    type: GET_DETAIL_ROLE,
    payload,
  };
};

export const getDetailPermissionRole = (payload: any) => {
  return {
    type: GET_DETAIL_PERMISSION_ROLE,
    payload,
  };
};

export const getDetailActionRole = (payload: any) => {
  return {
    type: GET_DETAIL_ACTION_ROLE,
    payload,
  };
};

export const createRole = (payload: any) => {
  return {
    type: CREATE_ROLE,
    payload,
  };
};

export const updateRole = (payload: any) => {
  return {
    type: UPDATE_ROLE,
    payload,
  };
};

export const deleteOneRole = (payload: IDeleteOnePayloadRole) => {
  return {
    type: DELETE_ONE_ROLE,
    payload,
  };
};

export const deleteManyRole = (payload: IDeleteManyPayloadRole) => {
  return {
    type: DELETE_MANY_ROLE,
    payload,
  };
};

export const archiveManyRole = (payload: IArchiveManyPayloadRole) => {
  return {
    type: ARCHIVE_MANY_ROLE,
    payload,
  };
};

export const unarchiveManyRole = (payload: IUnarchiveManyPayloadRole) => {
  return {
    type: UNARCHIVE_MANY_ROLE,
    payload,
  };
};

export const setLoadingRole = (payload: ILoadingRole) => {
  return {
    type: SET_LOADING_ROLE,
    payload,
  };
};
