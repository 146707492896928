import i18n from 'i18n-js';
import { useState } from 'react';
import { EuiButtonIcon } from '@elastic/eui';

import { Confirm } from './Confirm';

interface IDeleteAction {
  onOk?: () => void;
  onClose?: () => void;
}

export function DeleteAction(props: IDeleteAction) {
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);

  const onCloseConfirm = () => {
    setIsVisibleConfirm(false);
    if (props.onClose) props.onClose();
  };

  const onOKConfirm = () => {
    setIsVisibleConfirm(false);
    if (props.onOk) props.onOk();
  };

  return (
    <Confirm
      target={
        <EuiButtonIcon
          iconType={'trash'}
          onClick={(event: any) => {
            setIsVisibleConfirm(true);
          }}
          title={i18n.t('common.deleteAction.title')}
          aria-label={i18n.t('common.deleteAction.title')}
          color={'danger'}
          display="base"
        >
        </EuiButtonIcon>
      }
      visible={isVisibleConfirm}
      text={i18n.t('common.deleteAction.confirmText')}
      onOk={onOKConfirm}
      onClose={onCloseConfirm}
    />
  );
}
