export const vi = {
  common: {
    login: "Đăng Nhập",
    editAction: {
      title: 'Cập nhật',
    },
    deleteAction: {
      title: 'Xoá',
      confirmText: 'Bạn có chắc muốn xoá không?',
      confirmButtonYes: 'Có',
      confirmButtonNo: 'Không',
      deleteSuccess: 'Xoá thành công',
    },
    deleteManyAction: {
      title: 'Xoá thành công',
      message: 'Xoá thành công',
      buttonText: 'Xoá {number} dòng',
    },
    archiveManyAction: {
      title: 'Lưu trữ thành công',
      message: 'Lưu trữ thành công',
      buttonText: 'Lưu trữ {number} dòng',
    },
    unarchiveManyAction: {
      title: 'Huỷ lưu trữ thành công',
      message: 'Huỷ lưu trữ thành công',
      buttonText: 'Huỷ lưu trữ {number} dòng',
    },
    error: 'Lỗi',
    saveAction: {
      title: 'Lưu',
      saveSuccess: 'Lưu thành công',
    },
    createButton: 'Tạo mới',
  },
  auth: {
    loginTitle: "Đăng Nhập",
    welcomeMessage: "Chào mừng bạn đến với Mỹ thuật Bụi",
    rememberAccount: "Ghi nhớ tài khoản này",
    forgotPasswordText: 'Quên mật khẩu',
    username: {
      title: "Tên đăng nhập",
      placeholder: "Tên đăng nhập",
    },
    password: {
      title: "Mật Khẩu",
      placeholder: "Mật Khẩu của bạn",
    },
    notification: {
      loginSuccess: "Đăng nhập thành công",
      loginSuccessMessage: "Chào mừng tới Mỹ Thuật Bụi ERP",
      loginFail: "Đăng nhập thất bại",
    },
    error: {
      username: 'Tên đăng nhập không hợp lệ',
      password: 'Mật khẩu phải dài hơn 6 ký tự',
    },
    forgotPassword: {
      titleSuccess: 'Gửi thành công',
      messageSuccess: 'Mỹ Thuật Bụi sẽ gửi link lấy lại mật khẩu vào email của bạn.',
      titleFail: 'Gửi thất bại',
    },
    resetPassword: {
      titleSuccess: 'Cập nhật thành công',
      messageSuccess: '',
      titleFail: 'Cập nhật thất bại',
    }
  },
  menus: {
    dashboard: {
      name: "Trang chủ",
    },
    user: {
      name: "Người dùng",
    },
    role: {
      name: 'Vai trò',
    },
    permission: {
      name: 'Quyền hạn',
    },
    student: {
      name: "Học sinh",
    },
    teacher: {
      name: "Giáo viên",
    },
  },
  header: {
    task: "Công việc",
    chat: "Tin nhắn",
    email: 'Email',
    calendar: 'Lịch trình',
    menus: {
      setting: 'Cài đặt',
      chat: 'Tin nhắn',
      gallery: 'Thư viện',
      search: 'Tìm kiếm',
      logout: 'Đăng xuất',
    },
  },
  customDataGrid: {
    deleteMany: {
      text: 'XOÁ',
      confirmText: 'Bạn có chắc muốn xoá?',
    },
    archiveMany: {
      text: 'LƯU TRỮ',
      confirmText: 'Bạn có chắc muốn lưu trữ?',
    },
    unarchiveMany: {
      text: 'HUỶ LƯU TRỮ',
      confirmText: 'Bạn có chắc muốn huỷ lưu trữ?',
    },
  },
  user: {
    filter: {
      button: 'Tìm kiếm',
      email: 'Email',
      name: 'Tên',
      role: 'Vai trò',
      phone: 'Số điện thoại',
      status: 'Trạng thái',
    },
    list: {
      title: 'Người dùng',
      codeCol: 'Mã',
      nameCol: 'Tên',
      usernameCol: 'Tên đăng nhập',
      emailCol: 'Email',
      phoneCol: 'Số điện thoại',
      roleCol: 'Vai trò',
      statusCol: 'Trạng thái',
      isArchivedCol: 'Lưu trữ',
      createdAtCol: 'Thời gian tạo',
      actionCol: 'Hành động',
    },
    createButton: "Tạo mới",
    create: {
      title: "Tạo mới người dùng",
      nameLabel: "Tên",
      usernameLabel: "Tên đăng nhập",
      emailLabel: "Email",
      phoneLabel: "Số điện thoại",
      typeLabel: "Loại",
      statusLabel: "Trạng thái",
      roleLabel: "Vai trò",
      passwordLabel: "Mật khẩu",
      confirmPasswordLabel: "Xác nhận mật khẩu",
    },
    editTitle: 'Cập nhật người dùng',
  },
/*   room: {
    filter: {
      button: 'Filter',
      email: 'Email',
      name: 'Name',
      role: 'Role',
      phone: 'Phone',
      status: 'Status',
    },
    list: {
      title: 'Users',
      codeCol: 'Code',
      nameCol: 'Name',
      usernameCol: 'Username',
      emailCol: 'Email',
      phoneCol: 'Phone',
      roleCol: 'Role',
      statusCol: 'Status',
      isArchivedCol: 'Is Archived',
      createdAtCol: 'Created At',
      actionCol: 'Actions',
    },
    createButton: 'Create',
    create: {
      title: 'Create User',
      nameLabel: 'Name',
      usernameLabel: 'Username',
      emailLabel: 'Email',
      phoneLabel: 'Phone',
      typeLabel: 'Type',
      statusLabel: 'Status',
      roleLabel: 'Role',
      passwordLabel: 'Password',
      confirmPasswordLabel: 'Confirm Password',
    }
  }, */
  role: {
    filter: {
      button: 'Tìm kiếm',
      name: 'Tên',
    },
    list: {
      title: 'Vai trò',
      nameCol: 'Tên',
      descriptionCol: 'Mô tả',
      createdAtCol: 'Thời gian tạo',
      isArchivedCol: 'Lưu trữ',
      actionCol: 'Hành động',
    },
    create: {
      title: 'Tạo vai trò',
      nameLabel: 'Tên',
      descriptionLabel: 'Mô tả',
      permissionsLabel: 'Quyền hạn',
    },
    editTitle: 'Cập nhật vai trò',
  },
  permission: {
    filter: {
      button: 'Tìm kiếm',
      name: 'Tên',
      key: 'Từ khoá',
    },
    list: {
      title: 'Quyền hạn',
      nameCol: 'Tên',
      keyCol: 'Từ khoá',
      descriptionCol: 'Mô tả',
      createdAtCol: 'Thời gian tạo',
      isArchivedCol: 'Lưu trữ',
      actionCol: 'Hành động',
    },
    create: {
      title: 'Tạo quyền hạn',
      nameLabel: 'Tên',
      keyLabel: 'Từ khoá',
      descriptionLabel: 'Mô tả',
      actionIdsLabel: 'Hành động',

      actionNameLabel: 'Tên',
      actionKeyLabel: 'Từ khoá',
      actionUrlLabel: 'Url',
      actionMethodLabel: 'Phương thức',

      noActionMessage: 'Vui lòng thêm 1 thao tác',
      enterValueToActionMessage: 'Vui lòng nhập dữ liệu cho thao tác',
    },
    editTitle: 'Cập nhật quyền hạn',
    addNewActionButton: 'Thêm thao tác',
  }
};
