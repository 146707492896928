import React, {useState} from 'react';
import { Controller } from 'react-hook-form';
import { EuiFormRow, EuiDatePicker } from '@elastic/eui';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

export interface ITimeForm {
  control: any;
  name: string;
  require?: boolean | false;
  label: string;
  errors: any;
  isDisable?: boolean | false;
  id?: any;
}

export function TimeForm(props: ITimeForm) {
  const [startDate, setStartDate] = useState(moment());
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ required: props.require }}
      render={({ field }) => {
        const { ref, ...otherProps } = field;

        const handleChange = (date: any) => {
          setStartDate(date);

          if (otherProps?.onChange) {
            otherProps.onChange(date);
          }
        };
        return (
          <EuiFormRow
            fullWidth
            isDisabled={props.isDisable}
            label={props.label }
            isInvalid={!isEmpty(props.errors[props.name])}
            error={props?.errors[props.name]?.message}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <EuiDatePicker
              showTimeSelect
              showTimeSelectOnly
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              // @ts-ignore
              selected={otherProps.value || startDate}
              onChange={(value) => {
                handleChange(value);
              }}
            />
          </EuiFormRow>
        );
      }}
    />
  );
}
