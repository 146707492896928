import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import moment from 'moment';
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { Paths } from "../../../constants";
import CustomizeForm from "../../../components/common/form";
import { validation } from "../../../utils";
import { getDetailSchoolHour, updateDetailSchoolHour } from "../../../redux";

interface IFormInput {
  timeFrame: string;
  startHourName: string;
  endHourName: string;
  studySchedule: string;
  startHour: number;
  endHour: number;
}

const Edit: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id }: any = useParams();

  const schoolHourState = useSelector(
    (state: RootStateOrAny) => state.schoolHour
  );

  const schoolHourDetail = useSelector(
    (state: RootStateOrAny) => state.schoolHour.detail.data
  );

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { ...schoolHourDetail },
    resolver: validation.schoolHour.edit(),
  });

  useEffect(() => {
    reset({});
    dispatch(getDetailSchoolHour(id));
  }, []);

  useEffect(() => {
    if (!isEmpty(schoolHourDetail) && schoolHourDetail._id === id) {
      const endHourArray = schoolHourDetail.endHourName.split(':');
      const endHourDate = new Date().setHours(Number(endHourArray[0]), Number(endHourArray[1]), 0, 0);

      const startHourArray = schoolHourDetail.startHourName.split(':');
      const startHourDate = new Date().setHours(Number(startHourArray[0]), Number(startHourArray[1]), 0, 0);

      reset({
        ...schoolHourDetail,
        endHourName: moment(endHourDate),
        startHourName: moment(startHourDate),
      });
    }
  }, [schoolHourDetail]);

  const updateTimeFrame = (value: any) => {
    if (value.startHourName && value.endHourName) {
      // @ts-ignore
      let tHour = value.startHourName.hours();
      if (tHour < 10) tHour = '0'+tHour;
      // @ts-ignore
      let tMinute = value.startHourName.minutes();
      if (tMinute < 10) tMinute = '0'+tMinute;

      // @ts-ignore
      let eHour = value.endHourName.hours();
      if (eHour < 10) eHour = '0'+eHour;
      // @ts-ignore
      let eMinute = value.endHourName.minutes();
      if (eMinute < 10) eMinute = '0'+eMinute;

      setValue('timeFrame', `${tHour}:${tMinute} - ${eHour}:${eMinute}`);
    }
  }

  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  useEffect(() => {
    watch((value, { name, type }) => {
      let startHour = 0;
      let endHour = 0;
      if (name === 'startHourName' && type === 'change') {
        // @ts-ignore
        startHour = value.startHourName.hours() * 60 + value.startHourName.minutes();
        setValue('startHour', startHour);

        updateTimeFrame(value);
      }

      if (name === 'endHourName' && type === 'change') {
        // @ts-ignore
        endHour = value.endHourName.hours() * 60 + value.endHourName.minutes();
        setValue('endHour', endHour);

        updateTimeFrame(value);
      }
    });
  }, [watch]);

  const onSubmit = (data: any) => {
    console.log({data});
    dispatch(updateDetailSchoolHour({ id, data}));
  };

  const formInput = [
    { name: "timeFrame", label: "Khung giờ", type: "input", isDisable: true },
    {
      name: "startHourName",
      label: "Từ giờ",
      type: "time",
      required: true
    },
    {
      name: "endHourName",
      label: "Đến giờ",
      type: "time",
      required: true
    },
    {
      name: "studySchedule",
      label: "Buổi",
      type: "select",
      options: [
        {value: '', label: 'Chọn buổi', text: 'Chọn buổi'},
        {value: 'MORNING', label: 'Sáng', text: 'Sáng',},
        {value: 'AFTERNOON', label: 'Chiều', text: 'Chiều'},
        {value: 'EVENING', label: 'Tối', text: 'Tối'},
      ]
    },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Cập nhật"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.SCHOOL_HOUR.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formInput.map((item: any, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                isDisable={item?.isDisable || false}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton
              isLoading={schoolHourState?.isUpdateRequest || false}
              type="submit"
              fill
            >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Edit;
