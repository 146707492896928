import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import isEmpty from "lodash/isEmpty";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import { Paths } from "../../../constants";

import { validation } from "../../../utils";
import {
  getEmployeesApi,
  getSubjectsApi,
  getTeacherRolesApi,
} from "../../../network";
import { getListLocation as getListLocationApi } from "../../location/services";
import {
  getDetailTeacherRole,
  updateDetailTeacherRole,
} from "../../../redux/actions/teacher-role";
import { getDetailTeacher, updateDetailTeacher } from "../../../redux";

interface IFormInput {
  employeeId: string;
  teacherRoleIds: string[];
}

interface IEmployee {
  _id: string;
  name: string;
}

interface ITeacherRole {
  _id: string;
  name: string;
}

const Edit: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id }: any = useParams();

  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [teacherRoles, setTeacherRoles] = useState<ITeacherRole[]>([]);
  const [employeeSelected, setEmployeeSelected] = useState<any>([]);
  const [teacherRoleSelected, setTeacherRoleSelected] = useState<any>([]);

  const teacherState = useSelector((state: RootStateOrAny) => state.teacher);

  const teacherDetail = useSelector(
    (state: RootStateOrAny) => state.teacher.detail.teachers
  );

  const getListEmployee = async (searchValue?: string) => {
    const responseEmployee = await getEmployeesApi({ name: searchValue });
    if (responseEmployee && responseEmployee.data) {
      setEmployees(responseEmployee.data);
    }
  };

  const getListTeacherRole = async (searchValue?: string) => {
    const response = await getTeacherRolesApi({ name: searchValue });
    if (response && response.data) {
      setTeacherRoles(response.data);
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { ...teacherDetail },
    resolver: validation.teacher.edit(),
  });

  useEffect(() => {
    reset({});
    dispatch(getDetailTeacher(id));
    getListEmployee();
    getListTeacherRole();
  }, []);

  useEffect(() => {
    if (!isEmpty(teacherDetail) && teacherDetail._id === id) {
      reset({ ...teacherDetail });

      if (teacherDetail?.employeeId) {
        setEmployeeSelected([
          {
            _id: teacherDetail?.employeeId,
            value: teacherDetail?.employeeId,
            label: teacherDetail?.employeeId,
          },
        ]);
      }

      if (teacherDetail?.teacherIds) {
        setTeacherRoleSelected([
          {
            _id: teacherDetail?.teacherIds,
            value: teacherDetail?.teacherIds,
            label: teacherDetail?.teacherIds,
          },
        ]);
      }
    }
  }, [teacherDetail]);

  const onSubmit = (data: any) => {
    console.log({ data });
    dispatch(updateDetailTeacher({ teacherId: id, data }));
  };

  const formFields = [
    {
      name: "employeeId",
      label: "Nhân viên",
      type: "selectSearch",
      required: true,
      fullwidth: true,
      options: employees.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: employeeSelected,
      onSearchChange: getListEmployee,
      onChangeValue: (value: string) => {
        setEmployeeSelected(null);
        setValue("employeeId", value || "");
      },
    },
    {
      name: "teacherRoleIds",
      label: "Phân môn giảng dạy",
      type: "selectSearch",
      mode: "multiple",
      required: true,
      fullwidth: true,
      options: teacherRoles.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      onSearchChange: getListTeacherRole,
      onChangeValue: (value: string[]) => {
        setValue("teacherRoleIds", value);
      },
    },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Cập nhật"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.TEACHER.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formFields.map((item: any, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                isDisable={item?.isDisable || false}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton
              isLoading={teacherState?.isUpdateRequest || false}
              type="submit"
              fill
            >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Edit;
