/* eslint-disable react-hooks/exhaustive-deps */
import { EuiBadge, EuiButton, EuiPageHeader, EuiSpacer } from "@elastic/eui";
import i18n from "i18n-js";
import { FC, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomizeFilter,
  CustomizeTablePagination,
  DeleteAction,
  EditAction,
  FilterInput,
  Access,
} from "../../../components";
import { Paths, PERMISSIONS } from "../../../constants";
import { getEmployeesApi } from "../../../network";

import { getInitParams, formatToLocalDate } from "../../../utils";
import moment from "moment";
import {
  archiveManyTeacher,
  deleteManyTeacher,
  deleteOneTeacher,
  getListTeacher,
  unarchiveManyTeacher,
} from "../../../redux";

interface IEmployee {
  _id: string;
  name: string;
}

interface ITeacherRole {
  _id: string;
  name: string;
}

const List: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState(getInitParams());
  const [employees, setEmployees] = useState<IEmployee[]>([]);

  const getListEmployee = async (searchValue?: string) => {
    const responseEmployee = await getEmployeesApi({ name: searchValue });
    if (responseEmployee && responseEmployee.data) {
      setEmployees(responseEmployee.data);
    }
  };

  useEffect(() => {
    getListEmployee();
  }, []);

  useEffect(() => {
    dispatch(getListTeacher(params));
  }, [params]);

  const { teachers, total } = useSelector(
    (state: RootStateOrAny) => state?.teacher?.list
  );

  const { isLoadingRequest } = useSelector(
    (state: RootStateOrAny) => state.teacher
  );

  const onChangePage = (value: any) => {
    setParams({ ...params, offset: value });
  };

  const onDeleteMany = (ids: string[]) => {
    dispatch(deleteManyTeacher({ ids }));
  };

  const onArchiveMany = (ids: string[]) => {
    dispatch(archiveManyTeacher({ ids, isArchived: true }));
  };

  const onUnarchiveMany = (ids: string[]) => {
    dispatch(unarchiveManyTeacher({ ids, isArchived: false }));
  };

  const columns = [
    {
      field: "employeeId",
      name: "Nhân viên",
      sortable: false,
    },
    {
      field: "isArchived",
      name: "Lưu trữ",
      sortable: false,
      render: (isArchived: boolean) => (
        <EuiBadge color={isArchived ? "warning" : "ghost"}>
          {isArchived ? "Đã lưu trữ" : "Không"}
        </EuiBadge>
      ),
    },
    {
      field: "createdAt",
      name: "Khởi tạo",
      render: (time: string) => <p>{formatToLocalDate(time)}</p>,
    },
    {
      name: "Hành động",
      actions: [
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.TEACHER_UPDATE}>
                <EditAction
                  link={Paths.TEACHER.EDIT.replace(":id", item._id)}
                />
              </Access>
            );
          },
        },
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.TEACHER_DELETE}>
                <DeleteAction
                  onOk={() => {
                    dispatch(deleteOneTeacher({ id: item._id }));
                  }}
                />
              </Access>
            );
          },
        },
      ],
    },
  ];

  const onChangeItemsPerPage = (per: any) => {
    setParams({ ...params, limit: per });
  };

  const filterInputs: FilterInput[] = [
    {
      name: "employeeId",
      label: "Giáo viên",
      type: "select",
      visible: true,
      options: [{ value: "", text: "" }, ...employees].map((item: any) => ({
        value: item._id,
        text: item.name,
      })),
    },
    {
      name: "isArchived",
      label: "Lưu trữ",
      type: "select",
      options: [
        { value: "", text: "Tất cả" },
        { value: "true", text: "Đã lưu trữ" },
        { value: "false", text: "Không" },
      ],
    },
  ];

  const onFilter = (data: any) => {
    const newFilter: any = { ...params, ...data };
    for (let key in newFilter) {
      if (newFilter[key] === "" || newFilter[key] === undefined) {
        delete newFilter[key];
      }
    }
    setParams({ ...newFilter, offset: 0 });
  };

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Quản lý giáo viên"
        rightSideItems={[
          <Access permissionKey={PERMISSIONS.TEACHER_CREATE}>
            <EuiButton
              iconSide="left"
              iconType="plusInCircle"
              fill
              onClick={() => navigate({ pathname: Paths.TEACHER.CREATE })}
            >
              {i18n.t("common.createButton")}
            </EuiButton>
          </Access>,
        ]}
      />
      <EuiSpacer size="s" />

      <CustomizeFilter
        list={filterInputs}
        onFilter={onFilter}
        page={Paths.TEACHER.LIST}
      />

      <EuiSpacer size="s" />

      <CustomizeTablePagination
        columns={columns}
        body={teachers}
        isLoading={isLoadingRequest}
        pagination={params}
        total={total}
        isSelectable={true}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onDeleteMany={onDeleteMany}
        onArchiveMany={onArchiveMany}
        onUnarchiveMany={onUnarchiveMany}
      />
    </>
  );
};

export default List;
