import { RootStateOrAny, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { MainLayout } from "./components/layout";
import { Paths } from "./constants";
import history from "./navigation";
import { HistoryRouter } from "./navigation/HistoryRouter";
import { RequireAuth } from "./navigation/RequireAuth";
import { ForgotPassword, ResetPassword } from "./pages/auth";
import { ClassNumberRouter } from "./pages/classnumber";
import Dashboard from "./pages/dashboard/Dashboard";
import { DepartmentRouter } from "./pages/department";
import { EmployeeRouter } from "./pages/employee";
import { LocationRouter } from "./pages/location";
import { Login } from "./pages/login";
import { PermissionRouter } from "./pages/permission";
import { ProfileRouter } from "./pages/profile";
import { RoleRouter } from "./pages/role";
import { RoomRouter } from "./pages/room";
import { SchoolHourRouter } from "./pages/school-hour";
import { SubjectRouter } from "./pages/subject";
import { TeacherRouter } from "./pages/teacher";
import { TeacherRoleRouter } from "./pages/teacher-role";
import { UserRouter } from "./pages/user";

export const Navigator = () => {
  const profile = useSelector((state: RootStateOrAny) => state.profile.data);

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path={Paths.PUBLIC.LOGIN} element={<Login />} />
        <Route
          path={Paths.PUBLIC.FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />
        <Route path={Paths.PUBLIC.RESET_PASSWORD} element={<ResetPassword />} />

        <Route element={<RequireAuth />}>
          {/* <Route element={<MainLayout />}> */}
          <Route element={profile ? <MainLayout /> : <></>}>
            <Route path={Paths.DASHBOARD} element={<Dashboard />} />
            <Route
              path={`${Paths.PROFILE.DETAIL}/*`}
              element={<ProfileRouter />}
            />
            <Route path={`${Paths.USER.LIST}/*`} element={<UserRouter />} />
            <Route path={`${Paths.ROLE.LIST}/*`} element={<RoleRouter />} />
            <Route path={`${Paths.ROOM.LIST}/*`} element={<RoomRouter />} />
            <Route
              path={`${Paths.SUBJECT.LIST}/*`}
              element={<SubjectRouter />}
            />
            <Route
              path={`${Paths.LOCATION.LIST}/*`}
              element={<LocationRouter />}
            />
            <Route
              path={`${Paths.DEPARTMENT.LIST}/*`}
              element={<DepartmentRouter />}
            />
            <Route
              path={`${Paths.SCHOOL_HOUR.LIST}/*`}
              element={<SchoolHourRouter />}
            />
            <Route
              path={`${Paths.EMPLOYEE.LIST}/*`}
              element={<EmployeeRouter />}
            />
            <Route
              path={`${Paths.TEACHER_ROLE.LIST}/*`}
              element={<TeacherRoleRouter />}
            />
            <Route
              path={`${Paths.CLASS_NUMBER.LIST}/*`}
              element={<ClassNumberRouter />}
            />
            <Route
              path={`${Paths.TEACHER.LIST}/*`}
              element={<TeacherRouter />}
            />
            {profile?.isRootAdmin && (
              <Route
                path={`${Paths.PERMISSION.LIST}/*`}
                element={<PermissionRouter />}
              />
            )}
            <Route path="*" element={<b>Not found</b>}></Route>
          </Route>
        </Route>
      </Routes>
    </HistoryRouter>
  );
};
