import {
  EuiButton,
  EuiPageContentBody,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import { useForm } from "react-hook-form";
import CustomizeForm from "../../../components/common/form";
import { validation } from "../../../utils";
import { ActionTypes, Paths } from "../../../constants";
import { IUpsertClassNumber } from "../../../typing/classNumber";
import CustomBackPage from "../../../components/Page/Header";
import { createClassNumber } from "../services";
import history from "../../../navigation";
import { useState } from "react";
import { useDispatch } from "react-redux";


const Create: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);

  const { handleSubmit, control, formState: { errors } } = useForm<IUpsertClassNumber>({
    defaultValues: {
      roomId: "",
      subjectId: "",
      locationId: "",
      maxQuantity: 0
    },
    resolver: validation.classNumber.create(),
  });

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await createClassNumber(data);
      dispatch({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: "Cập nhật thông tin thành công",
          message: data?.message || "",
          type: "success",
        },
      })
      history.push("/class-numbers");
    } catch (e) {
      dispatch({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: "Cập nhật thông tin thất bại",
          message: `${e}` || "",
          type: "danger",
        },
      })
    } finally {
      setLoading(false);
    }
  };

  const ars = [
    { name: "roomId", label: "Phòng học", type: "selectSearch" },
    { name: "subjectId", label: "Môn học", type: "selectSearch" },
    { name: "locationId", label: "Địa điểm học", type: "selectSearch" },
    { name: "maxQuantity", label: "Sĩ số tối đa", type: "number" },
  ];

  return (
    <>
      <CustomBackPage pageTitle="Thêm sĩ số mới" pagePath={Paths.CLASS_NUMBER.LIST} />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {ars.map((item, index: number) => (
              <CustomizeForm
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
              />
            ))}
            <EuiButton isLoading={isLoading} type="submit" fill >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Create;
