import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import isEmpty from "lodash/isEmpty";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import { Paths } from "../../../constants";
import { getEmployeesApi, getSubjectsApi } from "../../../network";
import {
  getDetailTeacherRole,
  updateDetailTeacherRole,
} from "../../../redux/actions/teacher-role";
import { validation } from "../../../utils";
import { getListLocation as getListLocationApi } from "../../location/services";

interface IFormInput {
  name: string;
  subjectId: string;
  employeeId: string;
  provinceId: string;
  startWorkingDate: string;
}

interface IEmployee {
  _id: string;
  name: string;
}

interface ISubject {
  _id: string;
  name: string;
}

interface ILocation {
  _id: string;
  name: string;
}

interface ITeacherRole {
  _id: string;
  name: string;
}

const Edit: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id }: any = useParams();

  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [subjects, setSubjects] = useState<ISubject[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [employeeSelected, setEmployeeSelected] = useState<any>([]);
  const [provinceSelected, setProvinceSelected] = useState<any>([]);
  const [subjectSelected, setSubjectSeleted] = useState<any>([]);
  const [teacherRoles, setTeacherRoles] = useState<ITeacherRole[]>([]);
  // const [departmentSelected, setDepartmentSelected] = useState<any>([]);

  const teacherRoleState = useSelector(
    (state: RootStateOrAny) => state.teacherRole
  );

  const teacherRoleDetail = useSelector(
    (state: RootStateOrAny) => state.teacherRole.detail.teacherRoles
  );

  const getListEmployee = async (searchValue?: string) => {
    const responseEmployee = await getEmployeesApi({ name: searchValue });
    if (responseEmployee && responseEmployee.data) {
      setEmployees(responseEmployee.data);
    }
  };

  const getListSubject = async (searchValue?: string) => {
    const response = await getSubjectsApi({ name: searchValue });
    if (response && response.data) {
      setSubjects(response.data);
    }
  };

  const getListLocation = async (searchValue?: string) => {
    const response = await getListLocationApi({ name: searchValue });
    if (response && response.data) {
      setLocations(response.data);
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { ...teacherRoleDetail },
    resolver: validation.teacherRole.edit(),
  });

  useEffect(() => {
    reset({});
    dispatch(getDetailTeacherRole(id));
    getListEmployee();
    getListSubject();
    getListLocation();
  }, []);

  useEffect(() => {
    if (!isEmpty(teacherRoleDetail) && teacherRoleDetail._id === id) {
      reset({ ...teacherRoleDetail });

      if (teacherRoleDetail?.employeeId) {
        setEmployeeSelected([
          {
            _id: teacherRoleDetail?.employeeId,
            value: teacherRoleDetail?.employeeId,
            label: teacherRoleDetail?.employeeId,
          },
        ]);
      }
      if (teacherRoleDetail?.provinceId) {
        setProvinceSelected([
          {
            _id: teacherRoleDetail?.provinceId,
            value: teacherRoleDetail?.provinceId,
            label: teacherRoleDetail?.provinceId,
          },
        ]);
      }
      if (teacherRoleDetail?.subjectId) {
        setSubjectSeleted([
          {
            _id: teacherRoleDetail?.subjectId,
            value: teacherRoleDetail?.subjectId,
            label: teacherRoleDetail?.subjectId,
          },
        ]);
      }
    }
  }, [teacherRoleDetail]);

  const onSubmit = (data: any) => {
    console.log({ data });
    dispatch(updateDetailTeacherRole({ teacherRoleId: id, data }));
  };

  const formFields = [
    { name: "name", label: "Tên", type: "input" },
    {
      name: "employeeId",
      label: "Nhân viên",
      type: "selectSearch",
      required: true,
      fullWidth: true,
      options: employees.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: employeeSelected,
      // onSearchChange: getListEmployee,
      onChangeValue: (value: string) => {
        setEmployeeSelected(null);
        setValue("employeeId", value || "");
      },
    },
    {
      name: "subjectId",
      label: "Môn học",
      type: "selectSearch",
      required: true,
      fullWidth: true,
      options: subjects.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: subjectSelected,
      // onSearchChange: getListSubject,
      onChangeValue: (value: string) => {
        setSubjectSeleted(null);
        setValue("subjectId", value || "");
      },
    },
    {
      name: "provinceId",
      label: "Địa điểm làm việc",
      type: "selectSearch",
      required: true,
      fullWidth: true,
      customSelected: provinceSelected,
      options: locations.map((item: any) => ({
        id: item.provinceId,
        value: item.provinceId,
        label: item.name,
      })),
      // onSearchChange: getListLocation,
      onChangeValue: (value: string) => {
        setProvinceSelected(null);
        setValue("provinceId", value || "");
      },
    },
    {
      name: "startWorkingDate",
      label: "Ngày bắt đầu",
      type: "date",
      required: true,
      fullWidth: true,
    },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Cập nhật"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.TEACHER_ROLE.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formFields.map((item: any, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                isDisable={item?.isDisable || false}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton
              isLoading={teacherRoleState?.isUpdateRequest || false}
              type="submit"
              fill
            >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Edit;
