import { IGetRoom, IUpsertRoom, IPatchRoom, IDeleteManyRoom } from "../../typing/room";
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http";

const roomUrl = "/rooms";

const getRoomsApi = async (data?: IGetRoom) => {
  return await getRequest(roomUrl, { params: data });
};

const getRoomDetailApi = async (id: string) => {
  const url = `${roomUrl}/${id}`;
  return await getRequest(url)
}

const createRoomApi = async (data: IUpsertRoom) => {
  return await postRequest(roomUrl, data);
}

const updateRoomApi = async (id: string, data: IUpsertRoom) => {
  const url = `${roomUrl}/${id}`;
  return await putRequest(url, data);
}

const archiveManyRoomApi = async (data: IPatchRoom) => {
  return await patchRequest(roomUrl, data);
}

const deleteRoomApi = async (id: string) => {
  const url = `${roomUrl}/${id}`;
  return await deleteRequest(url);
}

const deleteManyRoomApi = async (data: IDeleteManyRoom) => {
  return await deleteRequest(roomUrl, { data });
}

export {
  getRoomsApi,
  getRoomDetailApi,
  createRoomApi,
  updateRoomApi,
  archiveManyRoomApi,
  deleteRoomApi,
  deleteManyRoomApi
}