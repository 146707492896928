/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { EuiCard, EuiCheckbox } from '@elastic/eui';

interface IPermission {
  _id: string;
  name: string;
}

interface IAction {
  _id: string;
  name: string;
  isSelected?: boolean;
}

interface ISelectedAction {
  _id: string;
  isSelected: boolean;
}

interface IPermissionData {
  id: string;
  actionIds: string[];
}

interface IPermissionItemProps {
  key?: string | number;
  initPermission?: IPermissionData;
  permission: IPermission;
  actions: IAction[];
  onChange: (data: IPermissionData) => void;
}

export function PermissionItem(props: IPermissionItemProps) {
  const { initPermission } = props;
  const [isOnChange, setIsOnChange] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedActions, setSelectedActions] = useState<ISelectedAction[]>([]);

  const handleOnChange = () => {
    let actionIds = selectedActions.map(item => item.isSelected ? item._id : '');
    if (isCheckAll) {
      actionIds = props.actions.map(item => item._id);
    }
    actionIds = actionIds.filter(value => value !== '');
    props.onChange({ id: props.permission._id, actionIds });
  };

  const onCheckAll = (e: any) => {
    setIsCheckAll(!isCheckAll);
    setIsOnChange(true);
  };

  const onCheckAction = (id: string) => {
    let newSelected: ISelectedAction[] = [...selectedActions];
    const index = newSelected.findIndex(item => item._id === id);

    if (index >= 0) {
      newSelected[index].isSelected = !newSelected[index].isSelected;
    } else {
      newSelected.push({
        _id: id,
        isSelected: true,
      });
    }

    setSelectedActions(newSelected);
    setIsOnChange(true);
  };

  useEffect(() => {
    if (isOnChange) {
      handleOnChange();
      setIsOnChange(false);
    }
  }, [isOnChange]);

  useEffect(() => {
    // @ts-ignore
    if (initPermission?.actionIds.length > 0) {
      let selected: any = [];
      // @ts-ignore
      for (let i = 0; i < initPermission?.actionIds?.length; i++) {
        selected.push({
          _id: initPermission?.actionIds[i],
          isSelected: true,
        });
      }

      setSelectedActions(selected);
    } else {
      setSelectedActions([]);
    }
  }, [initPermission]);

  return (
    <EuiCard
      key={props.key}
      style={{ marginTop: 10, marginBottom: 10 }}
      layout='horizontal'
      title={props.permission.name}
      titleSize='xs'
      description={
        <div>
          {props.actions.map((item: any, actionIndex: number) => {
            const index = selectedActions.findIndex(selectedItem => selectedItem._id === item._id);

            let isActionChecked = isCheckAll;
            if (index >= 0 && !isCheckAll) isActionChecked = selectedActions[index].isSelected;

            return (
              <EuiCheckbox
                key={`${props.permission._id}_${item._id}`}
                id={`${props.permission._id}_${item._id}`}
                label={item.name}
                checked={isActionChecked}
                onChange={(e) => onCheckAction(item._id)}
              />
            );
          })}
        </div>
      }
      icon={
        <EuiCheckbox
          id={props.permission._id}
          checked={isCheckAll}
          onChange={(e) => onCheckAll(e)}
        />
      }
    />
  );
}
