import i18n from "i18n-js";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { ActionTypes, Paths } from "../../constants";
import * as actionTypes from "../../constants/ActionTypes";
import history from "../../navigation";
import {
  archiveManyTeacherApi,
  createTeacherApi,
  deleteManyTeacherApi,
  deleteTeacherApi,
  getTeacherDetailApi,
  getTeacherApi,
  updateTeacherApi,
} from "../../network";
import { convertObjectParamsToStringParams, getInitParams } from "../../utils";
import { getTeacherState } from "../selectors";
import { ITeacherState } from "../types";

interface IPayload {
  type: string;
  payload: any;
}
interface IResponse {
  data: any;
  total: number;
}

function* getListTeacher(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacher: ITeacherState = yield select<any>(getTeacherState);

    // validate and set requesting
    if (teacher.isLoadingRequest) return;
    yield put({
      type: ActionTypes.SET_LOADING_TEACHER,
      payload: { isLoadingRequest: true },
    });

    const { data, total }: IResponse = yield call(
      getTeacherApi,
      action.payload
    );
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`);
    yield put({
      type: ActionTypes.GET_LIST_TEACHER_SUCCESS,
      payload: { data, total },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_LIST_TEACHER_FAILED,
      payload: error,
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isLoadingRequest: false },
    });
  }
}

function* createTeacher(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacher: ITeacherState = yield select<any>(getTeacherState);

    // validate and set requesting
    if (teacher.isCreateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isCreateRequest: true },
    });

    // call api create
    const { data, total }: IResponse = yield call(
      createTeacherApi,
      action.payload
    );

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    });

    // navigate
    history.push("/teachers");
  } catch (error) {
    yield put({ type: actionTypes.CREATE_TEACHER_FAILED, payload: error });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isCreateRequest: false },
    });
  }
}

function* deleteOneTeacher(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacher: ITeacherState = yield select<any>(getTeacherState);

    // validate and set requesting
    if (teacher.isDeleteRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isDeleteRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      deleteTeacherApi,
      action.payload.id
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_TEACHER,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isDeleteRequest: false },
    });
  }
}

function* updateDetailTeacher(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacher: ITeacherState = yield select<any>(getTeacherState);

    // validate and set requesting
    if (teacher.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isUpdateRequest: true },
    });

    // call api update
    const { data, total }: IResponse = yield call(
      updateTeacherApi,
      action.payload.teacherId,
      action.payload.data
    );

    // yield put({
    //   type: actionTypes.UPDATE_DETAIL_USER_SUCCESS,
    // });

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    });
    history.push(Paths.TEACHER.LIST);
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_DETAIL_TEACHER_FAILED,
      payload: error,
    });
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isUpdateRequest: false },
    });
  }
}

function* getDetailTeacher(action: IPayload) {
  try {
    const { data, total }: IResponse = yield call(
      getTeacherDetailApi,
      action.payload
    );

    yield put({
      type: actionTypes.GET_DETAIL_TEACHER_SUCCESS,
      payload: { data, total },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DETAIL_TEACHER_FAILED,
      payload: error,
    });
  }
}

function* deleteManyTeacher(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacher: ITeacherState = yield select<any>(getTeacherState);

    // validate and set requesting
    if (teacher.isDeleteRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isDeleteRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      deleteManyTeacherApi,
      action.payload.ids
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_TEACHER,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isDeleteRequest: false },
    });
  }
}

function* archiveManyTeacher(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacher: ITeacherState = yield select<any>(getTeacherState);

    // validate and set requesting
    if (teacher.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isUpdateRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      archiveManyTeacherApi,
      action.payload
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_TEACHER,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    console.log(error);
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isUpdateRequest: false },
    });
  }
}

function* unarchiveManyTeacher(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacher: ITeacherState = yield select<any>(getTeacherState);

    // validate and set requesting
    if (teacher.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isUpdateRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      archiveManyTeacherApi,
      action.payload
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_TEACHER,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    console.log(error);
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER,
      payload: { isUpdateRequest: false },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_LIST_TEACHER, getListTeacher),
    takeLatest(actionTypes.GET_DETAIL_TEACHER, getDetailTeacher),
    takeLatest(ActionTypes.CREATE_TEACHER, createTeacher),
    takeLatest(actionTypes.DELETE_ONE_TEACHER, deleteOneTeacher),
    takeLatest(actionTypes.UPDATE_DETAIL_TEACHER, updateDetailTeacher),
    takeLatest(actionTypes.DELETE_MANY_TEACHER, deleteManyTeacher),
    takeLatest(actionTypes.ARCHIVE_MANY_TEACHER, archiveManyTeacher),
    takeLatest(actionTypes.UNARCHIVE_MANY_TEACHER, unarchiveManyTeacher),
  ]);
}
