import {
  GET_DETAIL_TEACHER_ROLE_SUCCESS,
  // GET_DETAIL_TEACHER_ROLE_SUCCESS,
  GET_LIST_TEACHER_ROLE,
  GET_LIST_TEACHER_ROLE_SUCCESS,
  PAGINATION_DEFAULT,
  SET_LOADING_TEACHER_ROLE,
} from "../../constants";
import { ITeacherRoleState } from "../types";

interface ActionProps {
  type: string;
  payload: any;
}

const initState: ITeacherRoleState = {
  list: {
    teacherRoles: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    teacherRoles: {},
  },
  isLoadingRequest: false,
  isDeleteRequest: false,
  isUpdateRequest: false,
  isCreateRequest: false,
};

const teacherRole = (
  state: ITeacherRoleState = initState,
  action: ActionProps
) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LIST_TEACHER_ROLE: {
      return {
        ...state,
      };
    }
    case GET_LIST_TEACHER_ROLE_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          teacherRoles: payload.data,
          total: payload.total,
        },
      };
    }

    case GET_DETAIL_TEACHER_ROLE_SUCCESS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          teacherRoles: payload.data,
        },
      };
    }

    case SET_LOADING_TEACHER_ROLE: {
      return {
        ...state,
        ...payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default teacherRole;
