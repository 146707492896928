import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NORMAL_CHARACTER_REGEX, PHONE_REGEX } from "../constants";

const REQUIRE_MESSAGE = "Không được để trống";

export const validation = {
  user: {
    edit: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          // .matches(NORMAL_CHARACTER_REGEX, "Tên người dùng không hợp lệ"),
          phone: yup
            .string()
            .required(REQUIRE_MESSAGE)
            .matches(PHONE_REGEX, "Số điện thoại không hợp lệ"),
          email: yup
            .string()
            .email("Email hợp lệ. ví dụ: mtb@abc.com")
            .required(REQUIRE_MESSAGE),
          username: yup
            .string()
            .required(REQUIRE_MESSAGE)
            .matches(NORMAL_CHARACTER_REGEX, "Tên đăng nhập không hợp lệ"),
          password: yup
            .string()
            .test(
              "len",
              "có thể để trống hoặc có chuỗi ít nhất 6 ký tự",
              (value: any) => {
                if (value === undefined || value === null || value === "")
                  return true;
                return value.length === 0 || value.length >= 6;
              }
            )
            .nullable()
            .transform((v, o) => (o === "" ? null : v)),
        })
      );
    },
    create: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          // .matches(NORMAL_CHARACTER_REGEX, "Tên người dùng không hợp lệ"),
          username: yup
            .string()
            .required(REQUIRE_MESSAGE)
            .min(5, "Tên đăng nhập tối thiểu 5 ký tự")
            .matches(NORMAL_CHARACTER_REGEX, "Tên đăng nhập không hợp lệ"),
          email: yup
            .string()
            .email("Email hợp lệ. ví dụ: mtb@abc.com")
            .required(REQUIRE_MESSAGE),
          phone: yup
            .string()
            .required(REQUIRE_MESSAGE)
            .matches(PHONE_REGEX, "Số điện thoại không hợp lệ"),
          type: yup.string().required(REQUIRE_MESSAGE),
          roleId: yup.string().required(REQUIRE_MESSAGE),
          status: yup.string().required(REQUIRE_MESSAGE),
          password: yup
            .string()
            .required(REQUIRE_MESSAGE)
            .min(6, "Mật khẩu tối thiểu 6 kí tự"),
          repassword: yup
            .string()
            .required(REQUIRE_MESSAGE)
            .oneOf([yup.ref("password")], "Vui lòng nhập đúng mật khẩu"),
        })
      );
    },
  },
  permission: {
    create: (action: any) => {
      let actionYup = {};
      // for (let i = 0; i < size(action); i++) {
      //   if (!actionYup[action[i]?.isDelete]) {
      //     actionYup[action[i].data[0].name] = yup
      //       .string()
      //       .required(REQUIRE_MESSAGE);
      //     actionYup[action[i].data[1].name] = yup
      //       .string()
      //       .required(REQUIRE_MESSAGE);
      //     actionYup[action[i].data[2].name] = yup
      //       .string()
      //       .required(REQUIRE_MESSAGE);
      //     actionYup[action[i].data[3].name] = yup
      //       .string()
      //       .required(REQUIRE_MESSAGE);
      //   }
      // }
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          nameSearch: yup.string().required(REQUIRE_MESSAGE),
          ...actionYup,
        })
      );
    },
    edit: (action: any) => {
      let actionYup = {};
      // for (let i = 0; i < size(action); i++) {
      //   actionYup[action[i][0].name] = yup
      //     .string()
      //     .required(REQUIRE_MESSAGE);
      //   actionYup[action[i][1].name] = yup
      //     .string()
      //     .required(REQUIRE_MESSAGE);
      //   actionYup[action[i][2].name] = yup
      //     .string()
      //     .required(REQUIRE_MESSAGE);
      //   actionYup[action[i][3].name] = yup
      //     .string()
      //     .required(REQUIRE_MESSAGE);
      // }
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          key: yup.string().required(REQUIRE_MESSAGE),
          ...actionYup,
        })
      );
    },
  },
  role: {
    create: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          description: yup.string().required(REQUIRE_MESSAGE),
        })
      );
    },
    edit: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          description: yup.string().required(REQUIRE_MESSAGE),
          // permissions: yup.array().of(yup.string()).notRequired(),
        })
      );
    },
  },
  room: {
    create: () => {
      return yupResolver(
        yup.object({
          code: yup.string().required(REQUIRE_MESSAGE),
          locationId: yup.string().required(REQUIRE_MESSAGE),
          maxQuantity: yup.number().required(REQUIRE_MESSAGE),
        })
      );
    },
    edit: () => {
      return yupResolver(
        yup.object({
          code: yup.string().required(REQUIRE_MESSAGE),
          locationId: yup.string().required(REQUIRE_MESSAGE),
          maxQuantity: yup.number().required(REQUIRE_MESSAGE),
        })
      );
    },
  },
  subject: {
    create: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          code: yup.string().required(REQUIRE_MESSAGE),
          subjectTypeId: yup.string().required(REQUIRE_MESSAGE),
          subjectObjectId: yup.string().required(REQUIRE_MESSAGE),
          subjectLevelId: yup.string().required(REQUIRE_MESSAGE),
          subjectFormId: yup.string().required(REQUIRE_MESSAGE),
          numberOfLessons: yup.number().required(REQUIRE_MESSAGE),
        })
      );
    },
    edit: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          code: yup.string().required(REQUIRE_MESSAGE),
          subjectTypeId: yup.string().required(REQUIRE_MESSAGE),
          subjectObjectId: yup.string().required(REQUIRE_MESSAGE),
          subjectLevelId: yup.string().required(REQUIRE_MESSAGE),
          subjectFormId: yup.string().required(REQUIRE_MESSAGE),
          numberOfLessons: yup.number().required(REQUIRE_MESSAGE),
        })
      );
    },
  },
  profile: {
    edit: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          phone: yup
            .string()
            .required(REQUIRE_MESSAGE)
            .matches(PHONE_REGEX, "Số điện thoại không hợp lệ"),
          email: yup
            .string()
            .email("Tên đăng nhập hợp lệ. ví dụ: mtb@abc.com")
            .required(REQUIRE_MESSAGE),
          password: yup.string().min(6, "Mật khẩu lớn hơn 6 kí tự"),
        })
      );
    },
  },
  department: {
    create: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          employeeIdManagement: yup.string().required(REQUIRE_MESSAGE),
        })
      );
    },
    edit: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          employeeIdManagement: yup.string().required(REQUIRE_MESSAGE),
        })
      );
    },
  },

  location: {
    create: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          code: yup.string().required(REQUIRE_MESSAGE),
          provinceId: yup.string().notRequired(),
          districtId: yup.string().notRequired(),
          wardId: yup.string().notRequired(),
          address: yup.string().notRequired(),
          parkingAddress: yup.string().notRequired(),
          hotline: yup.string().notRequired(),
        })
      );
    },
    edit: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          code: yup.string().required(REQUIRE_MESSAGE),
          provinceId: yup.string().notRequired(),
          districtId: yup.string().notRequired(),
          wardId: yup.string().notRequired(),
          address: yup.string().notRequired(),
          parkingAddress: yup.string().notRequired(),
          hotline: yup.string().notRequired(),
        })
      );
    },
  },

  schoolHour: {
    create: () => {
      return yupResolver(
        yup.object({
          timeFrame: yup.string().required(REQUIRE_MESSAGE),
          startHourName: yup.string().required(REQUIRE_MESSAGE),
          endHourName: yup.string().required(REQUIRE_MESSAGE),
          studySchedule: yup.string().required(REQUIRE_MESSAGE),
          startHour: yup.number().required(REQUIRE_MESSAGE).min(1),
          endHour: yup.number().required(REQUIRE_MESSAGE).min(1),
        })
      );
    },
    edit: () => {
      return yupResolver(
        yup.object({
          timeFrame: yup.string().required(REQUIRE_MESSAGE),
          startHourName: yup.string().required(REQUIRE_MESSAGE),
          endHourName: yup.string().required(REQUIRE_MESSAGE),
          studySchedule: yup.string().required(REQUIRE_MESSAGE),
          startHour: yup.number().required(REQUIRE_MESSAGE).min(1),
          endHour: yup.number().required(REQUIRE_MESSAGE).min(1),
        })
      );
    },
  },

  employee: {
    create: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          mobilePhone: yup
            .string()
            .matches(PHONE_REGEX, "Số điện thoại không hợp lệ")
            .required(REQUIRE_MESSAGE),
          departmentId: yup.string().required(REQUIRE_MESSAGE),
          locationIds: yup
            .array()
            .min(0, "Chọn ít nhất 1 cơ sở giảng dạy")
            .required(REQUIRE_MESSAGE),
        })
      );
    },
    edit: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          mobilePhone: yup
            .string()
            .matches(PHONE_REGEX, "Số điện thoại không hợp lệ")
            .required(REQUIRE_MESSAGE),
          departmentId: yup.string().required(REQUIRE_MESSAGE),
          locationIds: yup
            .array()
            .min(0, "Chọn ít nhất 1 cơ sở giảng dạy")
            .required(REQUIRE_MESSAGE),
        })
      );
    },
  },
  teacherRole: {
    create: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          subjectId: yup.string().required(REQUIRE_MESSAGE),
          employeeId: yup.string().required(REQUIRE_MESSAGE),
        })
      );
    },
    edit: () => {
      return yupResolver(
        yup.object({
          name: yup.string().required(REQUIRE_MESSAGE),
          subjectId: yup.string().required(REQUIRE_MESSAGE),
          employeeId: yup.string().required(REQUIRE_MESSAGE),
        })
      );
    },
  },
  classNumber: {
    create: () => {
      return yupResolver(
        yup.object({
          roomId: yup.string().required(REQUIRE_MESSAGE),
          subjectId: yup.string().required(REQUIRE_MESSAGE),
          locationId: yup.string().required(REQUIRE_MESSAGE),
          maxQuantity: yup.number().required(REQUIRE_MESSAGE),
        })
      );
    },
    edit: () => {
      return yupResolver(
        yup.object({
          roomId: yup.string().required(REQUIRE_MESSAGE),
          subjectId: yup.string().required(REQUIRE_MESSAGE),
          locationId: yup.string().required(REQUIRE_MESSAGE),
          maxQuantity: yup.number().required(REQUIRE_MESSAGE),
        })
      );
    },
  },
  teacher: {
    create: () => {
      return yupResolver(
        yup.object({
          employeeId: yup.string().required(REQUIRE_MESSAGE),
          teacherRoleIds: yup.array().required(REQUIRE_MESSAGE),
        })
      );
    },
    edit: () => {
      return yupResolver(
        yup.object({
          employeeId: yup.string().required(REQUIRE_MESSAGE),
          teacherRoleIds: yup.array().required(REQUIRE_MESSAGE),
        })
      );
    },
  },
};
