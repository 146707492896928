import { EuiProvider } from "@elastic/eui";
import { Provider as ReduxProvider } from "react-redux";
import { AuthProvider } from "./components/auth";
import Notification from "./components/common/Toast";
import { Navigator } from "./Navigator";
import store from "./redux/store";

const App = () => {
  return (
    <ReduxProvider store={store}>
      <AuthProvider>
        <EuiProvider colorMode="LIGHT">
          <Navigator />
          <Notification />
        </EuiProvider>
      </AuthProvider>
    </ReduxProvider>
  );
};

export default App;
