import {
  ARCHIVE_MANY_PERMISSION,
  CREATE_PERMISSION,
  DELETE_MANY_PERMISSION,
  DELETE_ONE_PERMISSION,
  GET_DETAIL_PERMISSION,
  GET_LIST_PERMISSION,
  SET_LOADING_PERMISSION,
  UNARCHIVE_MANY_PERMISSION,
  UPDATE_PERMISSION,
} from "../../constants";
import {
  IArchiveManyPayloadPermission,
  IDeleteManyPayloadPermission,
  ILoadingPermission,
  IUnarchiveManyPayloadPermission,
} from "../types";

export const getListPermission = (payload: any) => {
  return {
    type: GET_LIST_PERMISSION,
    payload,
  };
};

export const createPermission = (payload: any) => {
  return {
    type: CREATE_PERMISSION,
    payload,
  };
};

export const getDetailPermission = (payload: any) => {
  return {
    type: GET_DETAIL_PERMISSION,
    payload,
  };
};

export const updatePermission = (payload: any) => {
  return {
    type: UPDATE_PERMISSION,
    payload,
  };
};

export const deleteOnePermission = (payload: any) => {
  return {
    type: DELETE_ONE_PERMISSION,
    payload,
  };
};

export const deleteManyPermission = (payload: IDeleteManyPayloadPermission) => {
  return {
    type: DELETE_MANY_PERMISSION,
    payload,
  };
};

export const archiveManyPermission = (
  payload: IArchiveManyPayloadPermission
) => {
  return {
    type: ARCHIVE_MANY_PERMISSION,
    payload,
  };
};

export const unarchiveManyPermission = (
  payload: IUnarchiveManyPayloadPermission
) => {
  return {
    type: UNARCHIVE_MANY_PERMISSION,
    payload,
  };
};

export const setLoadingPermission = (payload: ILoadingPermission) => {
  return {
    type: SET_LOADING_PERMISSION,
    payload,
  };
};
