import {
  CHECK_AUTHENTICATION,
  GET_USER_PROFILE,
  LOGIN,
  LOGOUT,
  AUTH_FORGOT_PASSWORD,
  AUTH_RESET_PASSWORD
} from "../../constants";

export const handleLogin = (payload: any) => {
  return {
    type: LOGIN,
    payload,
  };
};

export const handleLogout = () => {
  return {
    type: LOGOUT,
  };
};

export const handleGetUserProfile: any = () => {
  return {
    type: GET_USER_PROFILE,
  };
};

export const handleCheckAuthentication: any = () => {
  return {
    type: CHECK_AUTHENTICATION,
  };
};

export const forgotPassword = (payload: any) => {
  return {
    type: AUTH_FORGOT_PASSWORD,
    payload,
  };
};

export const resetPassword = (payload: any) => {
  return {
    type: AUTH_RESET_PASSWORD,
    payload,
  };
};
