/* eslint-disable react-hooks/exhaustive-deps */
import { EuiBadge, EuiButton, EuiPageHeader, EuiSpacer } from "@elastic/eui";
import i18n from "i18n-js";
import { FC, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomizeFilter,
  CustomizeTablePagination,
  DeleteAction,
  EditAction,
  FilterInput,
  Access
} from "../../../components";
import { Paths, PERMISSIONS } from "../../../constants";
import { archiveManySubject, deleteManySubject, deleteOneSubject, getListSubject, unarchiveManySubject } from "../../../redux/actions/subject";
import { dateFormat, getInitParams } from "../../../utils";

const List: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState(getInitParams());

  useEffect(() => {
    dispatch(getListSubject(params));
  }, [params]);

  const { subjects, total } = useSelector(
    (state: RootStateOrAny) => state.subject.list
  );
  const { isLoadingRequest } = useSelector(
    (state: RootStateOrAny) => state.subject
  );

  const onChangePage = (value: any) => {
    setParams({ ...params, offset: value });
  };

  const onDeleteMany = (ids: string[]) => {
    dispatch(deleteManySubject({ ids }));
  };

  const onArchiveMany = (ids: string[]) => {
    dispatch(archiveManySubject({ ids, isArchived: true }));
  };

  const onUnarchiveMany = (ids: string[]) => {
    dispatch(unarchiveManySubject({ ids, isArchived: false }));
  };

  const columns = [
    {
      field: "code",
      name: "Mã môn học",
    },
    {
      field: "name",
      name: "Tên môn học",
      sortable: false,
    },
    {
      field: "numberOfLessons",
      name: "Số tiết học",
    },
    {
      field: "isArchived",
      name: "Lưu trữ",
      sortable: false,
      width: 120,
      render: (isArchived: boolean) => (
        <EuiBadge color={isArchived ? "warning" : "ghost"}>
          {isArchived ? "Đã lưu trữ" : "Không"}
        </EuiBadge>
      ),
    },
    {
      field: "createdAt",
      name: "Khởi tạo",
      render: (time: string) => <p>{dateFormat(time)}</p>,
    },
    {
      field: "updatedAt",
      name: "Cập nhật",
      render: (time: string) => <p>{dateFormat(time)}</p>,
    },
    {
      name: "Hành động",
      actions: [
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.SUBJECT_UPDATE} >
                <EditAction link={Paths.SUBJECT.EDIT.replace(":id", item._id)} />
              </Access>
            );
          },
        },
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.SUBJECT_DELETE} >
                <DeleteAction
                  onOk={() => {
                    dispatch(deleteOneSubject({ id: item._id }));
                  }}
                />
              </Access>
            );
          },
        },
      ],
    },
  ];

  const onChangeItemsPerPage = (per: any) => {
    setParams({ ...params, limit: per });
  };

  const filterInputs: FilterInput[] = [
    { name: "code", label: "Mã môn học", type: "input", visible: true },
  ];

  const onFilter = (data: any) => {
    const newFilter: any = { ...params, ...data };
    for (let key in newFilter) {
      if (newFilter[key] === "" || newFilter[key] === undefined) {
        delete newFilter[key];
      }
    }
    setParams(newFilter);
  };

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Quản lý môn học"
        rightSideItems={[
          <Access permissionKey={PERMISSIONS.SUBJECT_CREATE} >
            <EuiButton
              iconSide="left"
              iconType="plusInCircle"
              fill
              onClick={() => navigate({ pathname: Paths.SUBJECT.CREATE })}
            >
              {i18n.t("common.createButton")}
            </EuiButton>
          </Access>
        ]}
      />
      <EuiSpacer size="s" />

      <CustomizeFilter
        list={filterInputs}
        onFilter={onFilter}
        page={Paths.SUBJECT.LIST}
      />

      <EuiSpacer size="s" />

      <CustomizeTablePagination
        columns={columns}
        body={subjects}
        isLoading={isLoadingRequest}
        pagination={params}
        total={total}
        isSelectable={true}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onDeleteMany={onDeleteMany}
        onArchiveMany={onArchiveMany}
        onUnarchiveMany={onUnarchiveMany}
      />
    </>
  );
};

export default List;
