import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../../network/http"
import { IDeleteManyClassNumber, IPatchClassNumber } from "../../typing/classNumber";
const classNumbersUrl = "class-numbers"

const getListClassNumber = async (options?: any) => {
  return await getRequest(classNumbersUrl, options || {});
}

const getDetailClassNumber = async (id: string, options?: any) => {
  return await getRequest(`${classNumbersUrl}/${id}`, options || {});
}

const createClassNumber = async (data: any) => {
  return await postRequest(classNumbersUrl, data);
}

const updateClassNumber = async (id: string, data: any) => {
  return await putRequest(`${classNumbersUrl}/${id}`, data)
}

const deleteClassNumber = async (id: string) => {
  return await deleteRequest(`${classNumbersUrl}/${id}`);
}

const deleteManyClassNumberApi = async (data: IDeleteManyClassNumber) => {
  return await deleteRequest(classNumbersUrl, { data });
}

const archiveManyClassNumberApi = async (data: IPatchClassNumber) => {
  return await patchRequest(classNumbersUrl, data);
}

const unarchiveManyClassNumberApi = async (data: IPatchClassNumber) => {
  return await patchRequest(classNumbersUrl, data);
}

export {
  getListClassNumber,
  getDetailClassNumber,
  createClassNumber,
  updateClassNumber,
  deleteClassNumber,
  deleteManyClassNumberApi,
  archiveManyClassNumberApi,
  unarchiveManyClassNumberApi,
}