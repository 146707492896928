/* eslint-disable react-hooks/exhaustive-deps */
import {
  EuiBadge,
  EuiButton,
  EuiPageHeader,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomizeFilter,
  CustomizeTablePagination,
  DeleteAction,
  EditAction,
  FilterInput,
  Access
} from "../../../components";
import { Paths, PERMISSIONS } from "../../../constants";
import {
  archiveManyRole,
  deleteManyRole,
  deleteOneRole,
  getListRoles,
  unarchiveManyRole,
} from "../../../redux";
import { getInitParams, formatToLocalDate } from "../../../utils";

export default function List() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState(getInitParams());

  useEffect(() => {
    dispatch(getListRoles(params));
  }, [params]);

  const { data, total } = useSelector(
    (state: RootStateOrAny) => state.role.list
  );

  const { isLoadingRequest } = useSelector(
    (state: RootStateOrAny) => state.role
  );

  const onChangePage = (value: any) => {
    setParams({ ...params, offset: value });
  };

  const onDeleteMany = (ids: string[]) => {
    dispatch(deleteManyRole({ ids }));
  };

  const onArchiveMany = (ids: string[]) => {
    dispatch(archiveManyRole({ ids, isArchived: true }));
  };

  const onUnarchiveMany = (ids: string[]) => {
    dispatch(unarchiveManyRole({ ids, isArchived: false }));
  };

  const columns = [
    {
      field: "name",
      name: "Tên",
      render: (code: string) => <EuiBadge color="warning">{code}</EuiBadge>,
    },
    {
      field: "description",
      name: "Mô tả",
    },
    // {
    //   field: "isArchived",
    //   name: "Lưu trữ",
    //   sortable: false,
    //   width: 120,
    //   render: (isArchived: string) => {
    //     return (
    //       <EuiBadge color={isArchived ? "warning" : "ghost"}>
    //         {isArchived ? "Đã lưu trữ" : "Không"}
    //       </EuiBadge>
    //     )
    //   }
    // },
    {
      field: "createdAt",
      name: "Khởi tạo",
      render: (time: any) => <EuiText size="xs">{formatToLocalDate(time)}</EuiText>,
    },
    {
      name: "Hành động",
      actions: [
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.ROLE_UPDATE} >
              <EditAction link={Paths.ROLE.EDIT.replace(":id", item._id)} />
              </Access>
            );
          },
        },
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.ROLE_DELETE} >
              <DeleteAction
                onOk={() => {
                  dispatch(deleteOneRole({ id: item._id }));
                }}
              />
              </Access>
            );
          },
        },
      ],
    },
  ];

  const onChangeItemsPerPage = (per: any) => {
    setParams({ ...params, limit: per });
  };

  const filterInputs: FilterInput[] = [
    { name: "name", label: "Tên", type: "input", visible: true },
    {
      name: "isArchived",
      label: "Lưu trữ",
      type: "select",
      options: [
        { value: "", text: "Tất cả" },
        { value: "true", text: "Đã lưu trữ" },
        { value: "false", text: "Không" },
      ],
    },
  ];

  const onFilter = (data: any) => {
    const newFilter: any = { ...params, ...data };
    for (let key in newFilter) {
      if (newFilter[key] === "" || newFilter[key] === undefined) {
        delete newFilter[key];
      }
    }
    setParams({ ...newFilter, offset: 0 });
    // setParams(newFilter);
  };

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Quản lý vai trò"
        rightSideItems={[
          <Access permissionKey={PERMISSIONS.ROLE_CREATE} >
          <EuiButton
            iconSide="left"
            iconType="plusInCircle"
            fill
            onClick={() => navigate({ pathname: Paths.ROLE.CREATE })}
          >
            Thêm mới
          </EuiButton>
          </Access>
        ]}
      />
      <EuiSpacer size="s" />

      <CustomizeFilter
        list={filterInputs}
        onFilter={onFilter}
        page={Paths.ROLE.LIST}
      />

      <EuiSpacer size="s" />

      <CustomizeTablePagination
        columns={columns}
        body={data}
        isLoading={isLoadingRequest}
        pagination={params}
        total={total}
        isSelectable={true}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onDeleteMany={onDeleteMany}
        onArchiveMany={onArchiveMany}
        onUnarchiveMany={onUnarchiveMany}
      />
    </>
  );
}
