export const routeBar = [
  {
    name: "general",
    text: "Quản lý",
    children: [
      { basepath: "/users", text: "Người dùng" },
      { basepath: "/rooms", text: "Lớp học" },
      { basepath: "/subjects", text: "Môn học" },
      { basepath: "/branches", text: "Cơ Sở" },
      { basepath: "/locations", text: "Địa điểm lớp học" },
      { basepath: "/class-numbers", text: "Sĩ số tối đa" },
    ],
  },
  {
    name: "configuration",
    text: "Cấu hình chung",
    children: [
      { basepath: "/permissions", text: "Quyền hạn" },
      { basepath: "/roles", text: "Vai trò" },
    ],
  },
];