import {
  GET_DETAIL_SUBJECT_SUCCESS,
  GET_LIST_SUBJECT,
  GET_LIST_SUBJECT_SUCCESS,
  PAGINATION_DEFAULT,
  SET_LOADING_SUBJECT,
} from '../../constants';
import { ISubjectState } from '../types';

interface ActionProps {
  type: string;
  payload: any;
}

const initState: ISubjectState = {
  list: {
    subjects: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    subjects: {},
  },
  isLoadingRequest: false,
  isDeleteRequest: false,
  isUpdateRequest: false,
  isCreateRequest: false,
};

const subject = (state: ISubjectState = initState, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LIST_SUBJECT: {
      return {
        ...state,
      };
    }
    case GET_LIST_SUBJECT_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          subjects: payload.data,
          total: payload.total,
        },
      };
    }
    case GET_DETAIL_SUBJECT_SUCCESS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          subjects: payload.data,
        },
      };
    }
    case SET_LOADING_SUBJECT: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default subject;
