import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import { Paths } from "../../../constants";
import {
  getDepartmentsApi,
  getEmployeesApi,
  getJobsApi,
  getLocationsApi,
  getUsersApi,
} from "../../../network";
import { createEmployee } from "../../../redux";
import { validation } from "../../../utils";

interface IFormInput {
  name: string;
  avatar?: string;
  mobilePhone: string;
  facebook: string;
  workEmail: string;
  workAddress: string;
  startWorkingDate: any;
  departmentId: string | null;
  userId?: string | null;
  jobId?: string | null;
  managerId?: string | null;
  type: string;
  locationIds: string[];
  no2LocationIds: string[];
  no3LocationIds: string[];
}

interface IDepartment {
  _id: string;
  name: string;
}

interface IJob {
  _id: string;
  name: string;
}

const Create: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [jobs, setJobs] = useState<IJob[]>([]);
  const [users, setUsers] = useState<IJob[]>([]);
  const [managers, setManagers] = useState<IJob[]>([]);
  const [locations, setLocations] = useState<IJob[]>([]);
  const [no2Locations, setNo2Locations] = useState<IJob[]>([]);
  const [no3Locations, setNo3Locations] = useState<IJob[]>([]);
  const [departments, setDepartments] = useState<IDepartment[]>([]);

  const employeeState = useSelector((state: RootStateOrAny) => state.employee);

  const getListDepartment = async (searchValue?: string) => {
    const response = await getDepartmentsApi({ name: searchValue });
    if (response && response.data) {
      setDepartments(response.data);
    }
  };

  const getListUser = async (searchValue?: string) => {
    const response = await getUsersApi({ name: searchValue });
    if (response && response.data) {
      setUsers(response.data);
    }
  };

  const getListJob = async (searchValue?: string) => {
    const response = await getJobsApi({ name: searchValue });
    if (response && response.data) {
      setJobs(response.data);
    }
  };

  const getListManager = async (searchValue?: string) => {
    const response = await getEmployeesApi({ name: searchValue });
    if (response && response.data) {
      setManagers(response.data);
    }
  };

  const getListLocation = async (
    searchValue?: string,
    isInitRequest: boolean = false
  ) => {
    const response = await getLocationsApi({ name: searchValue });
    if (response && response.data) {
      setLocations(response.data);

      if (isInitRequest) {
        setNo2Locations(response.data);
        setNo3Locations(response.data);
      }
    }
  };

  const getListNo2Location = async (searchValue?: string) => {
    const response = await getLocationsApi({ name: searchValue });
    if (response && response.data) {
      setNo2Locations(response.data);
    }
  };

  const getListNo3Location = async (searchValue?: string) => {
    const response = await getLocationsApi({ name: searchValue });
    if (response && response.data) {
      setNo3Locations(response.data);
    }
  };

  useEffect(() => {
    getListDepartment();
    getListUser();
    getListJob();
    getListManager();
    getListLocation(undefined, true);
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>({
    defaultValues: {
      name: "",
      avatar: "",
      mobilePhone: "",
      facebook: "",
      workEmail: "",
      workAddress: "",
      startWorkingDate: moment(),
      departmentId: "",
      userId: null,
      jobId: null,
      managerId: null,
      type: "STAFF",
      locationIds: [],
      no2LocationIds: [],
      no3LocationIds: [],
    },
    resolver: validation.employee.create(),
  });

  const onSubmit = (data: any) => {
    console.log({ data });
    const employeeData = {
      ...data,
      startWorkingDate: data.startWorkingDate.toISOString(),
    };
    dispatch(createEmployee(employeeData));
  };

  const formInput = [
    { name: "name", label: "Tên", type: "text", required: true },
    {
      name: "mobilePhone",
      label: "Số điện thoại",
      type: "text",
      required: true,
    },
    { name: "facebook", label: "Facebook link", type: "text" },
    {
      name: "workEmail",
      label: "Email công việc",
      type: "text",
      inputType: "email",
    },
    { name: "workAddress", label: "Địa chỉ làm việc", type: "text" },
    {
      name: "startWorkingDate",
      label: "Thời gian bắt đầu làm việc",
      type: "date",
    },
    {
      name: "departmentId",
      label: "Phòng ban",
      type: "selectSearch",
      fullWidth: true,
      options: [{ _id: "", name: "Chọn" }, ...departments].map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      // onSearchChange: getListDepartment,
      onChangeValue: (value: string) => {
        setValue("departmentId", value);
      },
    },
    {
      name: "userId",
      label: "Liên kết với người dùng",
      type: "selectSearch",
      fullWidth: true,
      options: users.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      // onSearchChange: getListUser,
      onChangeValue: (value: string) => {
        setValue("userId", value);
      },
    },
    {
      name: "jobId",
      label: "Công việc",
      type: "selectSearch",
      fullWidth: true,
      options: [{ _id: "", name: "Chọn" }, ...jobs].map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      // onSearchChange: getListJob,
      onChangeValue: (value: string) => {
        setValue("jobId", value);
      },
    },
    {
      name: "managerId",
      label: "Người quản lý",
      type: "selectSearch",
      fullWidth: true,
      options: [{ _id: "", name: "Chọn" }, ...managers].map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      // onSearchChange: getListManager,
      onChangeValue: (value: string) => {
        setValue("managerId", value);
      },
    },
    {
      name: "locationIds",
      label: "Địa điểm làm việc mong muốn",
      type: "selectSearch",
      fullWidth: true,
      mode: "multiple",
      options: locations.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      onSearchChange: getListLocation,
      onChangeValue: (value: string[]) => {
        setValue("locationIds", value);
      },
    },
    {
      name: "no2LocationIds",
      label: "Địa điểm làm việc mong muốn: ưu tiên thứ 2",
      type: "selectSearch",
      fullWidth: true,
      mode: "multiple",
      options: no2Locations.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      // onSearchChange: getListNo2Location,
      onChangeValue: (value: string[]) => {
        setValue("no2LocationIds", value);
      },
    },
    {
      name: "no3LocationIds",
      label: "Địa điểm làm việc mong muốn: ưu tiên thứ 3",
      type: "selectSearch",
      fullWidth: true,
      mode: "multiple",
      options: no3Locations.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      // onSearchChange: getListNo3Location,
      onChangeValue: (value: string[]) => {
        setValue("no3LocationIds", value);
      },
    },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Thêm mới"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.EMPLOYEE.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formInput.map((item: any, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton
              isLoading={employeeState?.isCreateRequest || false}
              type="submit"
              fill
            >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Create;
