import {
  GET_DETAIL_DEPARTMENT_SUCCESS,
  // GET_DETAIL_DEPARTMENT_SUCCESS,
  GET_LIST_DEPARTMENT,
  GET_LIST_DEPARTMENT_SUCCESS,
  PAGINATION_DEFAULT,
  SET_LOADING_DEPARTMENT,
} from "../../constants";
import { IDepartmentState } from "../types";

interface ActionProps {
  type: string;
  payload: any;
}

const initState: IDepartmentState = {
  list: {
    departments: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    departments: {},
  },
  isLoadingRequest: false,
  isDeleteRequest: false,
  isUpdateRequest: false,
  isCreateRequest: false,
};

const user = (state: IDepartmentState = initState, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LIST_DEPARTMENT: {
      return {
        ...state,
      };
    }
    case GET_LIST_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          departments: payload.data,
          total: payload.total,
        },
      };
    }

    case GET_DETAIL_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          departments: payload.data,
        },
      };
    }

    case SET_LOADING_DEPARTMENT: {
      return {
        ...state,
        ...payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default user;
