export const DASHBOARD = "/";
export const PROFILE = {
  DETAIL: "/profile",
  EDIT: "/profile/edit",
};
export const USER = {
  LIST: "/users",
  CREATE: "/users/create",
  EDIT: "/users/:id",
};

export const ROLE = {
  LIST: "/roles",
  CREATE: "/roles/create",
  EDIT: "/roles/:id",
};

export const PERMISSION = {
  LIST: "/permissions",
  CREATE: "/permissions/create",
  EDIT: "/permissions/:id",
};

export const PUBLIC = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
};

export const ROOM = {
  LIST: "/rooms",
  CREATE: "/rooms/create",
  EDIT: "/rooms/:id",
};

export const SUBJECT = {
  LIST: "/subjects",
  CREATE: "/subjects/create",
  EDIT: "/subjects/:id",
};

export const LOCATION = {
  LIST: "/locations",
  CREATE: "/locations/create",
  EDIT: "/locations/:id",
};

export const DEPARTMENT = {
  LIST: "/departments",
  CREATE: "/departments/create",
  EDIT: "/departments/:id",
};

export const SCHOOL_HOUR = {
  LIST: "/school-hours",
  CREATE: "/school-hours/create",
  EDIT: "/school-hours/:id",
};

export const EMPLOYEE = {
  LIST: "/employees",
  CREATE: "/employees/create",
  EDIT: "/employees/:id",
};

export const TEACHER_ROLE = {
  LIST: "/teacher-roles",
  CREATE: "/teacher-roles/create",
  EDIT: "/teacher-roles/:id",
};
export const CLASS_NUMBER = {
  LIST: "/class-numbers",
  CREATE: "/class-numbers/create",
  EDIT: "/class-numbers/:id",
};

export const TEACHER = {
  LIST: "/teachers",
  CREATE: "/teachers/create",
  EDIT: "/teachers/:id",
};
