import {
  ARCHIVE_MANY_SCHOOL_HOUR,
  CREATE_SCHOOL_HOUR,
  DELETE_MANY_SCHOOL_HOUR,
  DELETE_ONE_SCHOOL_HOUR,
  GET_DETAIL_SCHOOL_HOUR,
  GET_LIST_SCHOOL_HOUR,
  SET_LOADING_SCHOOL_HOUR,
  UNARCHIVE_MANY_SCHOOL_HOUR,
  UPDATE_DETAIL_SCHOOL_HOUR,
} from "../../constants";
import {
  IArchiveManyPayloadSchoolHour,
  IDeleteManyPayloadSchoolHour,
  IDeleteOnePayloadSchoolHour,
  ILoadingSchoolHour,
  IUnarchiveManyPayloadSchoolHour,
} from "../types";

export const getListSchoolHour = (payload: any) => {
  return {
    type: GET_LIST_SCHOOL_HOUR,
    payload,
  };
};

export const getDetailSchoolHour = (payload: any) => {
  return {
    type: GET_DETAIL_SCHOOL_HOUR,
    payload,
  };
};

export const updateDetailSchoolHour = (payload: any) => {
  return {
    type: UPDATE_DETAIL_SCHOOL_HOUR,
    payload,
  };
};

export const createSchoolHour = (payload: any) => {
  return {
    type: CREATE_SCHOOL_HOUR,
    payload,
  };
};

export const deleteOneSchoolHour = (payload: IDeleteOnePayloadSchoolHour) => {
  return {
    type: DELETE_ONE_SCHOOL_HOUR,
    payload,
  };
};

export const deleteManySchoolHour = (payload: IDeleteManyPayloadSchoolHour) => {
  return {
    type: DELETE_MANY_SCHOOL_HOUR,
    payload,
  };
};

export const archiveManySchoolHour = (
  payload: IArchiveManyPayloadSchoolHour
) => {
  return {
    type: ARCHIVE_MANY_SCHOOL_HOUR,
    payload,
  };
};

export const unarchiveManySchoolHour = (
  payload: IUnarchiveManyPayloadSchoolHour
) => {
  return {
    type: UNARCHIVE_MANY_SCHOOL_HOUR,
    payload,
  };
};

export const setLoadingSchoolHour = (payload: ILoadingSchoolHour) => {
  return {
    type: SET_LOADING_SCHOOL_HOUR,
    payload,
  };
};
