import { useLocalStorageState } from "ahooks";
import { isEmpty } from "lodash";
import React, { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACCESS_TOKEN, IS_AUTHENTICATED, USER_INFO } from "../../constants";
import { LoginFormData } from "../../pages/login/Login";
import {
  handleCheckAuthentication,
  handleLogin,
  handleLogout,
} from "../../redux/actions/auth";
import { UserInfo } from "../../types";
import { sleep } from "../../utils/await";

export interface AuthContext {
  login: (data: LoginFormData) => void;
  logout: () => void;
  userInfo?: UserInfo | null;
  isAuthenticated: boolean | null;
}

const authContext = createContext<AuthContext>({} as AuthContext);

const useAuth = (): AuthContext => {
  const [isAuthenticated, setIsAuthenticated] = useLocalStorageState<
    string | null
  >(IS_AUTHENTICATED);
  // const [accessToken, setAccessToken] = useLocalStorageState<string | null>(
  //   ACCESS_TOKEN
  // );

  const [userInfo, setUserInfo] = useLocalStorageState<UserInfo | null>(
    USER_INFO
  );

  const dispatch = useDispatch();

  const profile = useSelector((state: any) => state.profile);
  const session = useSelector((state: any) => state.auth.session);

  const login = (data: LoginFormData) => {
    dispatch(handleLogin(data));
  };

  useEffect(() => {
    if (session.isExpire) {
      logout();
    }
  }, [session.isCheck]);

  const logout = () => {
    dispatch(handleLogout());
    // setAccessToken(null);
    // setUserInfo(null);
    // setIsAuthenticated(null);
  };

  useEffect(() => {
    const resolve = async () => {
      // setAccessToken(profile?.accessToken);
      // await sleep(300);
      setIsAuthenticated("true");
    };

    if (!isEmpty(profile?.accessToken)) {
      resolve();
    }
  }, [profile?.accessToken]);

  useEffect(() => {
    if (!!isAuthenticated) {
      dispatch(handleCheckAuthentication());
    }
  }, []);

  return {
    userInfo,
    login,
    logout,
    isAuthenticated: !!isAuthenticated,
  };
};

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuthContext = () => useContext(authContext);
