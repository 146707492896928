import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import React, { FC } from "react";

const Dashboard: FC = () => {
  return (
    <EuiFlexGroup gutterSize="l">
      <EuiFlexItem>
        <EuiCard
          icon={<EuiIcon size="l" type={"searchProfilerApp"} />}
          title={"CRM"}
          description=""
          onClick={() => {}}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          icon={<EuiIcon size="l" type={"spacesApp"} />}
          title={"Vận Hành Giáo Dục"}
          description=""
          onClick={() => {}}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          icon={<EuiIcon size="l" type={"reportingApp"} />}
          title={"Báo Cáo"}
          description=""
          onClick={() => {}}
          href="#"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Dashboard;
