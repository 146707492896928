import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import isEmpty from "lodash/isEmpty";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import { Paths } from "../../../constants";
import { getListRoles } from "../../../redux/actions/role";
import { getDetailUser, updateDetailUser } from "../../../redux/actions/user";
import { validation } from "../../../utils";

interface IFormInput {
  code: string;
  email: string;
  name: string;
  phone: string;
  username: string;
  status: string;
  type: string;
}

const Edit: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id }: any = useParams();
  const userState = useSelector((state: RootStateOrAny) => state.user);

  const userDetail = useSelector(
    (state: RootStateOrAny) => state.user.detail.users
  );
  const roles = useSelector(
    (state: RootStateOrAny) => state?.role?.list?.data || []
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { ...userDetail },
    resolver: validation.user.edit(),
  });

  useEffect(() => {
    reset({});
    dispatch(getDetailUser(id));
    dispatch(getListRoles({ limit: 1000, offset: 0 }));
  }, []);

  useEffect(() => {
    if (!isEmpty(userDetail) && userDetail._id === id) {
      reset({ ...userDetail });
    }
  }, [userDetail]);

  const onSubmit = (data: any) => {
    dispatch(updateDetailUser({ userId: id, data }));
  };

  const formFields = [
    {
      name: "code",
      label: "Mã",
      isDisable: true,
      type: "input",
    },
    { name: "email", label: "Email", type: "input" },
    { name: "name", label: "Tên", type: "input" },
    { name: "phone", label: "Số điện thoại", type: "input" },
    { name: "username", label: "Tên đăng nhập", type: "input", isDisable: true },
    {
      name: "status",
      label: "Trạng thái",
      type: "select",
      options: [
        { value: "ACTIVE", text: "Hoạt động" },
        { value: "BLOCKED", text: "Khoá" },
      ],
    },
    {
      name: "roleId",
      label: "Vai trò",
      type: "select",
      options: roles.map((item: any) => ({
        value: item?._id,
        text: item?.name,
      })),
    },
    { name: "password", label: "Mật khẩu", type: "password", initValue: '' },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Cập nhật"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.USER.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formFields.map((item: any, index: number) => (
              <CustomizeForm
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                isDisable={item?.isDisable || false}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton isLoading={userState?.isUpdateRequest || false} type="submit" fill>
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Edit;
