import {
  GET_DETAIL_PERMISSION_SUCCESS,
  GET_LIST_ACTION_SUCCESS,
  GET_LIST_PERMISSION_SUCCESS,
  PAGINATION_DEFAULT,
  UPDATE_PERMISSION_SUCCESS,
} from "../../constants";
import { IActionState, IPermissionState } from "../types";

interface ActionProps {
  type: string;
  payload: any;
}

const initState: IActionState = {
  list: {
    actions: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    actions: {},
  },
};

const role = (state: IActionState = initState, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LIST_ACTION_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          actions: payload.data,
          total: payload.total,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default role;
