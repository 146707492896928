import { getRequest, postRequest, putRequest, deleteRequest, patchRequest } from '../http';

interface IEmployees {
  name?: string;
  key?: string;
  ids?: string;
  limit?: number;
  offset?: number;
}

interface IEmployeeCreate {
  userId: string;
  departmentId: string;
  name: string;
  avatar?: string;
  mobilePhone?: string;
  facebook?: string;
  workEmail?: string;
  workAddress?: string;
  startWorkingDate?: string;
  jobId?: string;
  managerId?: string;
  type?: string;
  subjects?: ISubjectItem[];
}

interface ISubjectItem {
  name: string;
  role: 'TEACHER' | 'TUTORS' | 'INTERSHIP';
  subjectId: string;
  startWorkingDate: string;
}

interface IEmployeeUpdate {
  userId: string;
  departmentId: string;
  name: string;
  avatar?: string;
  mobilePhone?: string;
  facebook?: string;
  workEmail?: string;
  workAddress?: string;
  startWorkingDate?: string;
  jobId?: string;
  managerId?: string;
  subjects?: ISubjectItem[];
}

interface IEmployeeArchive {
  ids: Array<string>;
  isArchived: boolean;
}

export const getEmployeesApi = async (data?: IEmployees) => {
  const url = '/employees';
  return await getRequest(url, { params: data });
};

export const getEmployeeDetailApi = async (id: string) => {
  const url = `/employees/${id}`;
  return await getRequest(url);
};

export const createEmployeeApi = async (data: IEmployeeCreate) => {
  const url = `/employees`;
  return await postRequest(url, data);
};

export const updateEmployeeApi = async (id: string, data: IEmployeeUpdate) => {
  const url = `/employees/${id}`;
  return await putRequest(url, data);
};

export const archiveManyEmployeeApi = async (data: IEmployeeArchive) => {
  const url = `/employees`;
  return await patchRequest(url, data);
};

export const deleteEmployeeApi = async (id: string) => {
  const url = `/employees/${id}`;
  return await deleteRequest(url);
};

export const deleteManyEmployeeApi = async (ids: string[]) => {
  const url = `/employees`;
  return await deleteRequest(url, { data: { ids } });
};
