import React from 'react';
import i18n from 'i18n-js';
import { Link } from 'react-router-dom';
import { EuiButtonIcon } from '@elastic/eui';

interface IEditAction {
  children?: any;
  link: string;
}

export function EditAction(props: IEditAction) {
  return (
    <Link to={props.link}>
      <EuiButtonIcon
        iconType={'pencil'}
        title={i18n.t('common.editAction.title')}
        aria-label={i18n.t('common.editAction.title')}
        color={'primary'}
        display="base"
      >
      </EuiButtonIcon>
    </Link>
  );
}
