import { BAD_REQUEST } from '../../constants';

export class BadRequest extends Error {
  data: any;
  isLogAble: boolean;
  code: number = BAD_REQUEST;

  constructor(message: string, name: string, data: any) {
    super(message);
    this.name = name;
    this.data = data;
    this.isLogAble = true;
  }
}
