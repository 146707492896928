import { combineReducers } from "redux";
import action from "./actions";
import auth from "./auth";
import common from "./common";
import department from "./department";
import employee from "./employee";
import permission from "./permission";
import profile from "./profile";
import role from "./role";
import room from "./room";
import schoolHour from "./school-hour";
import subject from "./subject";
import teacher from "./teacher";
import teacherRole from "./teacher-role";
import user from "./user";

export default combineReducers({
  user,
  room,
  subject,
  profile,
  common,
  role,
  permission,
  action,
  auth,
  department,
  schoolHour,
  teacherRole,
  employee,
  teacher,
});
