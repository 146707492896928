import React from 'react';
import i18n from 'i18n-js';
import { useEuiTheme, EuiPopover, EuiButton } from '@elastic/eui';
import { FaCheck, FaWindowClose } from 'react-icons/fa';

interface IConfirmProps {
  onOk?: () => void;
  onClose?: () => void;
  text?: string;
  zIndex?: number;
  target: any;
  visible: boolean;
}

export function Confirm(props: IConfirmProps) {
  const theme = useEuiTheme();
  const text = props.text || i18n.t('common.deleteAction.title');

  const onOKClick = () => {
    if (props.onOk) props.onOk();
  };

  const onCloseClick = () => {
    if (props.onClose) props.onClose();
  };

  return (
    <EuiPopover
      button={props.target || null}
      closePopover={onCloseClick}
      isOpen={props.visible || false}
      zIndex={props.zIndex || 100}
    >
      <div>{text}</div>
      <div className='flex'>
        <div style={{marginTop: 10}} >
          <EuiButton size={'s'} minWidth={'auto'} iconType={'check'} color={'primary'} onClick={onOKClick}>
            {i18n.t('common.deleteAction.confirmButtonYes')}
          </EuiButton>
          <EuiButton size={'s'} minWidth={'auto'} iconType={'cross'} color={'danger'} style={{marginLeft: 10}} onClick={onCloseClick}>
            {i18n.t('common.deleteAction.confirmButtonNo')}
          </EuiButton>
        </div>
      </div>
    </EuiPopover>
  );
}
