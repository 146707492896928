import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from "../http";

interface ILocations {
  name?: string;
  email?: string;
  roleId?: string;
  limit?: number;
  offset?: number;
  ids?: string;
}

interface ILocationCreate {
  name: string;
  email: string;
  roleId: string;
  photo?: string | null;
  password: string;
  type: string;
  status: string;
}

interface ILocationUpdate {
  name?: string;
  email?: string;
  roleId?: string;
  photo?: string | null;
  password?: string;
  type?: string;
  status?: string;
}

interface ILocationArchive {
  ids: Array<string>;
  isArchived: boolean;
}

export const getLocationsApi = async (data?: ILocations) => {
  const url = "/locations";
  return await getRequest(url, { params: data });
};

export const getLocationDetailApi = async (id: string) => {
  const url = `/locations/${id}`;
  return await getRequest(url);
};

export const createLocationApi = async (data: ILocationCreate) => {
  const url = `/locations`;
  return await postRequest(url, data);
};

export const updateLocationApi = async (
  id: string,
  data: ILocationUpdate
) => {
  const url = `/locations/${id}`;
  return await putRequest(url, data);
};

export const archiveManyLocationApi = async (data: ILocationArchive) => {
  const url = `/locations`;
  return await patchRequest(url, data);
};

export const deleteLocationApi = async (id: string) => {
  const url = `/locations/${id}`;
  return await deleteRequest(url);
};

export const deleteManyLocationApi = async (ids: string[]) => {
  const url = `/locations`;
  return await deleteRequest(url, { data: { ids } });
};
