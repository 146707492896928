/* eslint-disable react-hooks/exhaustive-deps */
import {
  EuiButton,
  EuiLoadingContent,
  EuiPageContentBody,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import { isEmpty } from "lodash";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import CustomBackPage from "../../../components/Page/Header";
import PDW from "../../../components/ProvinceDistrictWard";
import { ActionTypes, Paths } from "../../../constants";
import { IUpsertLocation } from "../../../typing/location";
import { validation } from "../../../utils";
import { getDetailLocation, updateLocation } from "../services";
import history from "../../../navigation";
import { useDispatch } from "react-redux";

const Edit: FC = () => {
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const [isLoaded, setLoaded] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [locationDetail, setLocationDetail] = useState<IUpsertLocation>({
    name: "",
    code: "",
    address: "",
    provinceId: "",
    districtId: "",
    wardId: "",
    fullAddress: "",
    parkingAddress: "",
    hotline: ""
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<IUpsertLocation>({
    defaultValues: { ...locationDetail },
    resolver: validation.location.edit(),
  });

  const fetchDetail = async () => {
    try {
      setLoaded(true);
      const req = await getDetailLocation(id);
      const locationData = req.data;
      setLocationDetail({
        name: locationData.name,
        code: locationData.code,
        address: locationData.address,
        provinceId: locationData.provinceId,
        districtId: locationData.districtId,
        wardId: locationData.wardId,
        fullAddress: locationData.fullAddress,
        parkingAddress: locationData.parkingAddress,
        hotline: locationData.hotline,
      });
    }
    catch (e) { }
    finally {
      setLoaded(false);
    }
  }

  useEffect(() => {
    (async function fetchDetailLocation() {
      await fetchDetail();
    })();
  }, []);

  useEffect(() => {
    if (!isEmpty(locationDetail)) {
      reset({ ...locationDetail });
    }
  }, [locationDetail]);


  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await updateLocation(id, data);
      dispatch({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: "Cập nhật thông tin thành công",
          message: data?.message || "",
          type: "success",
        },
      })
      history.push("/locations");
    } catch (e) {
      dispatch({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: "Cập nhật thông tin thất bại",
          message: `${e}` || "",
          type: "danger",
        },
      })
    } finally {
      setLoading(false);
    }
  };

  const ars = [
    { name: "name", label: "Tên địa điểm", type: "input" },
    { name: "code", label: "Mã địa điểm", type: "input" },
    { name: "parkingAddress", label: "Địa điểm đỗ xe", type: "input" },
    { name: "hotline", label: "Đường dây nóng", type: "input" },
  ];

  return (
    !isLoaded ? <>
      <CustomBackPage pageTitle="Cập nhật địa điểm" pagePath={Paths.LOCATION.LIST} />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {ars.map((item, index: number) => (
              <CustomizeForm
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
              />
            ))}
            <PDW type="UPDATE" control={control} errors={errors} values={locationDetail} setValue={setValue} getValues={getValues} />
            <EuiButton isLoading={isLoading} type="submit" fill >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </> : <EuiLoadingContent />
  );
};

export default Edit;
