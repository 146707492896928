import React from 'react';
import { Controller } from 'react-hook-form';
import { EuiCheckableCard, EuiRadioGroup } from '@elastic/eui';

export interface ICheckboxGroupForm {
  control: any;
  name: string;
  require?: boolean | false;
  label: string;
  errors: any;
  isDisable?: boolean | false;
  id?: any;
  options?: any;
}

export function CheckboxGroupForm(props: ICheckboxGroupForm) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ required: props.require }}
      render={({ field }) => {
        const {ref, ...otherProps} = field;
        return (
          <EuiCheckableCard
            id={props.id}
            label={props.label}
            name={props.name}
            // value="radio2"
            // checked={true}
            // {...field}
            onChange={otherProps.onChange}
          >
            <EuiRadioGroup options={props.options} onChange={() => {}} />
          </EuiCheckableCard>
        )
      }}
    />
  );
}
