import React from "react";
import isEmpty from "lodash/isEmpty";
import { Controller } from "react-hook-form";
import { EuiFieldText, EuiFormRow } from "@elastic/eui";

export interface IInputForm {
  control: any;
  name: string;
  require?: boolean | false;
  label: string;
  errors: any;
  isDisable?: boolean | false;
  inputType?: string;
}

export function InputForm(props: IInputForm) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ required: props.require }}
      render={({ field }) => {
        const {ref, ...otherProps} = field;
        return (
          <EuiFormRow
            fullWidth
            isDisabled={props.isDisable}
            label={props.label}
            isInvalid={!isEmpty(props.errors[props.name])}
            error={props?.errors[props.name]?.message}
          >
            <EuiFieldText
              fullWidth
              {...otherProps}
              type={props?.inputType || 'text'}
              value={otherProps.value || ''}
            />
          </EuiFormRow>
        )
      }}
    />
  );
}
