import { IGetSubject, IUpsertSubject, IPatchSubject, IDeleteManySubject } from "../../typing/subject";
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http";

const subjectUrl = "/subjects";

const getSubjectsApi = async (data?: IGetSubject) => {
  return await getRequest(subjectUrl, { params: data });
};

const getSubjectDetailApi = async (id: string) => {
  const url = `${subjectUrl}/${id}`;
  return await getRequest(url)
}

const createSubjectApi = async (data: IUpsertSubject) => {
  return await postRequest(subjectUrl, data);
}

const updateSubjectApi = async (id: string, data: IUpsertSubject) => {
  const url = `${subjectUrl}/${id}`;
  return await putRequest(url, data);
}

const archiveManySubjectApi = async (data: IPatchSubject) => {
  return await patchRequest(subjectUrl, data);
}

const deleteSubjectApi = async (id: string) => {
  const url = `${subjectUrl}/${id}`;
  return await deleteRequest(url);
}

const deleteManySubjectApi = async (data: IDeleteManySubject) => {
  return await deleteRequest(subjectUrl, { data });
}

export {
  getSubjectsApi,
  getSubjectDetailApi,
  createSubjectApi,
  updateSubjectApi,
  archiveManySubjectApi,
  deleteSubjectApi,
  deleteManySubjectApi
}