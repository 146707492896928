import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { Paths, ACCESS_TOKEN, IS_AUTHENTICATED } from "../../constants";
import history from "../../navigation";
import * as actionTypes from "../../constants/ActionTypes";
import { getProfileApi, updateProfileApi } from "../../network";
import { RootStateOrAny } from "react-redux";

interface IPayload {
  type: string;
  payload: any;
}

function* getUserProfile() {
  try {
    const { data }: AxiosResponse = yield call(getProfileApi);
    yield put({
      type: actionTypes.GET_USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // @important clear localstorage
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(IS_AUTHENTICATED);
    localStorage.clear();
    console.log(error);

    history.push(Paths.PUBLIC.LOGIN);
    yield put({ type: actionTypes.GET_USER_PROFILE_FAILED, payload: error });
  }
}

function* updateUserProfile(action: IPayload) {
  try {
    const { data }: AxiosResponse = yield call(
      updateProfileApi,
      action.payload
    );
    yield put({ type: actionTypes.GET_USER_PROFILE });
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "",
        message: "Cập nhật thành công",
        type: "success",
      },
    });
    history.push(Paths.PROFILE.DETAIL);
  } catch (error) {
    yield put({ type: actionTypes.GET_USER_PROFILE_FAILED, payload: error });
  }
}

function* updateFilters(action: IPayload): any {
  try {
    const profile: RootStateOrAny = yield select(
      (state) => state?.profile?.data
    );
    yield call(updateProfileApi, { ...profile, ...action.payload });
    yield put({ type: actionTypes.GET_USER_PROFILE });
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_FILTERS_FAILED, payload: error });
  }
}

export default function* root() {
  yield all([
    takeLatest(actionTypes.GET_USER_PROFILE, getUserProfile),
    takeLatest(actionTypes.AUTH_UPDATE_PROFILE, updateUserProfile),
    takeLatest(actionTypes.UPDATE_FILTERS, updateFilters),
  ]);
}
