import { FC } from "react";
import { EuiCallOut } from "@elastic/eui";

export const Warning: FC = () => {
  return (
    <EuiCallOut title="Quan trọng" color="danger" iconType="alert">
      <div>
        Lưu ý: khu vực này chỉ dành cho Root Admin (Dev) quản lí, vui lòng không
        chỉnh sửa.
      </div>
    </EuiCallOut>
  );
};
