/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { EuiButton, EuiPageContentBody, EuiPanel, EuiSpacer } from '@elastic/eui';

import CustomizeForm from '../../../components/common/form';
import { getDetailActionRole, getDetailPermissionRole, getDetailRole, updateRole } from '../../../redux';
import { validation } from '../../../utils';
import { Paths } from '../../../constants';
import { PermissionItem } from './PermissionItem';
import isEmpty from 'lodash/isEmpty';
import CustomBackPage from '../../../components/Page/Header';

interface IFormPermission {
  id: string;
  actionIds: string[];
}

interface IFormInput {
  name: string;
  description: string;
  permissions: IFormPermission[];
}

const Edit: FC = () => {
  const dispatch = useDispatch();
  const { id }: any = useParams();

  const roleDetail = useSelector(
    (state: RootStateOrAny) => state.role.detail,
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    // setValue,
    getValues,
  } = useForm<IFormInput>({
    defaultValues: {
      name: roleDetail?.name || '',
      description: roleDetail?.description || '',
      permissions: roleDetail?.permissions || [],
    },
    resolver: validation.role.edit(),
  });

  useEffect(() => {
    dispatch(getDetailRole({ id }));
    dispatch(getDetailPermissionRole({ limit: 1000, offset: 0 }));
    dispatch(getDetailActionRole({ limit: 1000, offset: 0 }));
  }, []);

  useEffect(() => {
    // set init data to form
    if (!isEmpty(roleDetail) && roleDetail?.data?._id === id) {
      const { data, permissions } = roleDetail;
      let permissionsData = [];
      for (let i = 0; i < data?.permissions.length; i++) {
        const { id } = data.permissions[i];

        // check permission not found
        const permissionItem = permissions?.find((item: any) => item._id === id);
        if (!permissionItem) continue;

        let actionIds = [];
        for (let j = 0; j < data.permissions[i].actionIds.length; j++) {
          const actionId = data.permissions[i].actionIds[j];

          // check action not found
          const actionItem = permissionItem.actionIds.find((value: string) => value === actionId);
          if (!actionItem) continue;
          actionIds.push(actionId);
        }

        // add to new permissions
        if (actionIds.length > 0) {
          permissionsData.push({ id, actionIds });
        }
      }
      reset({ ...roleDetail.data, permissions: permissionsData });
    }
  }, [roleDetail]);

  const onPermissionChange = (data: any) => {
    let permissionsValue = getValues('permissions');
    const index = permissionsValue.findIndex(item => item.id === data.id);
    if (index >= 0) {
      if (data.actionIds.length > 0) {
        permissionsValue[index].actionIds = data.actionIds;
      } else {
        permissionsValue.splice(index, 1);
      }
    } else {
      permissionsValue.push(data);
    }
  };

  const onSubmit = (data: any) => {
    dispatch(updateRole({ data, id }));
  };

  const formData = [
    { name: 'name', label: 'Tên', type: 'input' },
    { name: 'description', label: 'Mô tả', type: 'textarea' },
  ];

  return (
    <>
      <CustomBackPage pageTitle="Cập nhật vai trò" pagePath={Paths.ROLE.LIST} />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
            {formData.map((item: any, index: number) => (
              <CustomizeForm
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
              />
            ))}
            </div>

            <div>
            {roleDetail?.permissions?.map((permissionItem: any, index: number) => {
              const initialPermission = roleDetail?.data?.permissions?.find((jItem: any) => jItem.id === permissionItem._id);

              const listAction = roleDetail.actions?.filter((actionItem: any) => permissionItem.actionIds.includes(actionItem._id));

              // check list action
              if (!listAction || listAction.length === 0) return null;

              return (
                <PermissionItem
                  key={`${index}_${index}`}
                  initPermission={initialPermission}
                  permission={permissionItem}
                  actions={listAction || []}
                  onChange={onPermissionChange}
                />
              );
            })}
            </div>

            <EuiButton type="submit" fill> Lưu </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Edit;
