import {
  GET_DETAIL_EMPLOYEE_SUCCESS,
  GET_LIST_EMPLOYEE,
  GET_LIST_EMPLOYEE_SUCCESS,
  PAGINATION_DEFAULT,
  SET_LOADING_EMPLOYEE,
} from "../../constants";
import { IEmployeeState } from '../types';

interface ActionProps {
  type: string;
  payload: any;
}

const initState: IEmployeeState = {
  list: {
    data: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    data: {},
  },
  isLoadingRequest: false,
  isDeleteRequest: false,
  isUpdateRequest: false,
  isCreateRequest: false,
};

export default (state: IEmployeeState = initState, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LIST_EMPLOYEE: {
      return {
        ...state,
      };
    }
    case GET_LIST_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          data: payload.data,
          total: payload.total,
        },
      };
    }

    case GET_DETAIL_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          data: payload.data,
        },
      };
    }

    case SET_LOADING_EMPLOYEE: {
      return {
        ...state,
        ...payload,
      };
    }

    default: {
      return state;
    }
  }
};
