import {
  ARCHIVE_MANY_TEACHER,
  CREATE_TEACHER,
  DELETE_MANY_TEACHER,
  DELETE_ONE_TEACHER,
  GET_DETAIL_TEACHER,
  GET_LIST_TEACHER,
  SET_LOADING_TEACHER,
  UNARCHIVE_MANY_TEACHER,
  UPDATE_DETAIL_TEACHER,
} from "../../constants";
import {
  IArchiveManyPayloadTeacher,
  IDeleteManyPayloadTeacher,
  IDeleteOnePayloadTeacher,
  ILoadingTeacher,
  IUnarchiveManyPayloadTeacher,
} from "../types";

export const getListTeacher = (payload: any) => {
  return {
    type: GET_LIST_TEACHER,
    payload,
  };
};

export const getDetailTeacher = (payload: any) => {
  return {
    type: GET_DETAIL_TEACHER,
    payload,
  };
};

export const updateDetailTeacher = (payload: any) => {
  return {
    type: UPDATE_DETAIL_TEACHER,
    payload,
  };
};

export const createTeacher = (payload: any) => {
  return {
    type: CREATE_TEACHER,
    payload,
  };
};

export const deleteOneTeacher = (payload: IDeleteOnePayloadTeacher) => {
  return {
    type: DELETE_ONE_TEACHER,
    payload,
  };
};

export const deleteManyTeacher = (payload: IDeleteManyPayloadTeacher) => {
  return {
    type: DELETE_MANY_TEACHER,
    payload,
  };
};

export const archiveManyTeacher = (payload: IArchiveManyPayloadTeacher) => {
  return {
    type: ARCHIVE_MANY_TEACHER,
    payload,
  };
};

export const unarchiveManyTeacher = (payload: IUnarchiveManyPayloadTeacher) => {
  return {
    type: UNARCHIVE_MANY_TEACHER,
    payload,
  };
};

export const setLoadingTeacher = (payload: ILoadingTeacher) => {
  return {
    type: SET_LOADING_TEACHER,
    payload,
  };
};
