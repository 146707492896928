import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { EuiButton, EuiPageContentBody, EuiPageHeader, EuiPanel, EuiSpacer } from '@elastic/eui';

import CustomizeForm from '../../../components/common/form';
import { createRole, getDetailActionRole, getDetailPermissionRole } from '../../../redux';
import { validation } from '../../../utils';
import { Paths } from '../../../constants';
import { PermissionItem } from './PermissionItem';

interface IFormPermission {
  id: string;
  actionIds: string[];
}

interface IFormInput {
  name: string;
  description: string;
  permissions: IFormPermission[];
}

const Create: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { detail = {} } = useSelector((state: RootStateOrAny) => state.role);

  useEffect(() => {
    dispatch(getDetailPermissionRole({ limit: 1000, offset: 0 }));
    dispatch(getDetailActionRole({ limit: 1000, offset: 0 }));
  }, []);

  useEffect(() => {

  }, [detail]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IFormInput>({
    defaultValues: {
      name: '',
      description: '',
      permissions: [],
    },
    resolver: validation.role.create(),
  });

  const onPermissionChange = (data: any) => {
    let permissionsValue = getValues('permissions');
    const index = permissionsValue.findIndex(item => item.id === data.id);
    if (index >= 0) {
      if (data.actionIds.length > 0) {
        permissionsValue[index].actionIds = data.actionIds;
      } else {
        permissionsValue.splice(index, 1);
      }
    } else {
      permissionsValue.push(data);
    }
  };

  const onSubmit = (data: any) => {
    console.log('data: ', data);
    dispatch(createRole(data));
  };

  const formData = [
    { name: 'name', label: 'Tên', type: 'input', require: true },
    { name: 'description', label: 'Mô tả', type: 'textarea' },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Thêm vai trò mới"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType='arrowLeft'
            onClick={() => navigate(Paths.ROLE.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formData.map((item: any, index: number) => (
              <CustomizeForm
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
              />
            ))}

            {detail.permissions?.map((permissionItem: any, index: number) => {
              const listAction = detail.actions?.filter((actionItem: any) => permissionItem.actionIds.includes(actionItem._id));

              // check list action
              if (!listAction || listAction.length === 0) return <></>;

              return (
                <PermissionItem
                  key={index}
                  permission={permissionItem}
                  actions={listAction || []}
                  onChange={onPermissionChange}
                />
              );
            })}

            <EuiButton type='submit' fill>
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Create;
