import {
  GET_DETAIL_TEACHER_SUCCESS,
  GET_LIST_TEACHER,
  GET_LIST_TEACHER_SUCCESS,
  PAGINATION_DEFAULT,
  SET_LOADING_TEACHER,
} from "../../constants";
import { ITeacherState } from "../types";

interface ActionProps {
  type: string;
  payload: any;
}

const initState: ITeacherState = {
  list: {
    teachers: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    teachers: {},
  },
  isLoadingRequest: false,
  isDeleteRequest: false,
  isUpdateRequest: false,
  isCreateRequest: false,
};

const teacher = (state: ITeacherState = initState, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LIST_TEACHER: {
      return {
        ...state,
      };
    }
    case GET_LIST_TEACHER_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          teachers: payload.data,
          total: payload.total,
        },
      };
    }

    case GET_DETAIL_TEACHER_SUCCESS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          teachers: payload.data,
        },
      };
    }

    case SET_LOADING_TEACHER: {
      return {
        ...state,
        ...payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default teacher;
