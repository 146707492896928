export const configs = {
  // apiHost:
  //   process.env.NODE_ENV === "production"
  //     ? "https://mtb-stagingapi.dansolutions.vn/"
  //     : "http://localhost:9000",
  portalHost: "http://localhost:3000",
  apiHost: "https://mtb-stagingapi.dansolutions.vn/",
  api: "https://mtb-stagingapi.dansolutions.vn",
  // apiHost: "http://localhost:9000/"
};
