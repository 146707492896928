import {
  ARCHIVE_MANY_TEACHER_ROLE,
  CREATE_TEACHER_ROLE,
  DELETE_MANY_TEACHER_ROLE,
  DELETE_ONE_TEACHER_ROLE,
  GET_DETAIL_TEACHER_ROLE,
  GET_LIST_TEACHER_ROLE,
  SET_LOADING_TEACHER_ROLE,
  UNARCHIVE_MANY_TEACHER_ROLE,
  UPDATE_DETAIL_TEACHER_ROLE,
} from "../../constants";
import {
  IArchiveManyPayloadTeacherRole,
  IDeleteManyPayloadTeacherRole,
  IDeleteOnePayloadTeacherRole,
  ILoadingTeacherRole,
  IUnarchiveManyPayloadTeacherRole,
} from "../types";

export const getListTeacherRole = (payload: any) => {
  return {
    type: GET_LIST_TEACHER_ROLE,
    payload,
  };
};

export const getDetailTeacherRole = (payload: any) => {
  return {
    type: GET_DETAIL_TEACHER_ROLE,
    payload,
  };
};

export const updateDetailTeacherRole = (payload: any) => {
  return {
    type: UPDATE_DETAIL_TEACHER_ROLE,
    payload,
  };
};

export const createTeacherRole = (payload: any) => {
  return {
    type: CREATE_TEACHER_ROLE,
    payload,
  };
};

export const deleteOneTeacherRole = (payload: IDeleteOnePayloadTeacherRole) => {
  return {
    type: DELETE_ONE_TEACHER_ROLE,
    payload,
  };
};

export const deleteManyTeacherRole = (
  payload: IDeleteManyPayloadTeacherRole
) => {
  return {
    type: DELETE_MANY_TEACHER_ROLE,
    payload,
  };
};

export const archiveManyTeacherRole = (
  payload: IArchiveManyPayloadTeacherRole
) => {
  return {
    type: ARCHIVE_MANY_TEACHER_ROLE,
    payload,
  };
};

export const unarchiveManyTeacherRole = (
  payload: IUnarchiveManyPayloadTeacherRole
) => {
  return {
    type: UNARCHIVE_MANY_TEACHER_ROLE,
    payload,
  };
};

export const setLoadingTeacherRole = (payload: ILoadingTeacherRole) => {
  return {
    type: SET_LOADING_TEACHER_ROLE,
    payload,
  };
};
