import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from "../http";

interface IJobs {
  name?: string;
  email?: string;
  roleId?: string;
  limit?: number;
  offset?: number;
  ids?: string;
}

interface IJobCreate {
  name: string;
  email: string;
  roleId: string;
  photo?: string | null;
  password: string;
  type: string;
  status: string;
}

interface IJobUpdate {
  name?: string;
  email?: string;
  roleId?: string;
  photo?: string | null;
  password?: string;
  type?: string;
  status?: string;
}

interface IJobArchive {
  ids: Array<string>;
  isArchived: boolean;
}

export const getJobsApi = async (data?: IJobs) => {
  const url = "/jobs";
  return await getRequest(url, { params: data });
};

export const getJobDetailApi = async (id: string) => {
  const url = `/jobs/${id}`;
  return await getRequest(url);
};

export const createJobApi = async (data: IJobCreate) => {
  const url = `/jobs`;
  return await postRequest(url, data);
};

export const updateJobApi = async (
  id: string,
  data: IJobUpdate
) => {
  const url = `/jobs/${id}`;
  return await putRequest(url, data);
};

export const archiveManyJobApi = async (data: IJobArchive) => {
  const url = `/jobs`;
  return await patchRequest(url, data);
};

export const deleteJobApi = async (id: string) => {
  const url = `/jobs/${id}`;
  return await deleteRequest(url);
};

export const deleteManyJobApi = async (ids: string[]) => {
  const url = `/jobs`;
  return await deleteRequest(url, { data: { ids } });
};
