import { getRequest } from "../../network/http";
import { ResponseData } from "../../types";

export const getProvinces = async (options?: Record<string, any>): Promise<typeof ResponseData> => {
  const result = await getRequest('provinces', { params: options || {} });
  return {
    data: result?.data || [],
    total: result?.total || [],
  };
};

export const getDistricts = async (options?: Record<string, any>): Promise<typeof ResponseData> => {
  const result = await getRequest('districts', { params: options || {} });
  return {
    data: result?.data || [],
    total: result?.total || [],
  };
};

export const getWards = async (options?: Record<string, any>): Promise<typeof ResponseData> => {
  const result = await getRequest('wards', { params: options || {} });
  return {
    data: result?.data || [],
    total: result?.total || [],
  };
};