import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import isEmpty from "lodash/isEmpty";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import { Paths } from "../../../constants";
import history from "../../../navigation";
import { getListRoles } from "../../../redux";
import { updateProfile } from "../../../redux/actions/profile";
import { validation } from "../../../utils";
import { columns } from "../column";

interface IFormInput {
  code: string;
  email: string;
  name: string;
  phone: string;
  username: string;
  status: string;
  type: string;
}

const Detail: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(
    (state: RootStateOrAny) => state?.profile?.data || {}
  );

  useEffect(() => {
    dispatch(getListRoles({ limit: 1000, offset: 0 }));
  }, []);

  useEffect(() => {
    if (!isEmpty(profile)) {
      reset({ ...profile });
    }
  }, [profile]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { ...profile },
  });

  const onSubmit = () => {
    history.push(Paths.PROFILE.EDIT);
  };

  const ars = [
    {
      name: "code",
      label: "Mã",
      isDisable: true,
      type: "input",
    },
    {
      name: "email",
      label: "Email",
      isDisable: true,
      type: "input",
    },
    { name: "name", label: "Tên", type: "input", isDisable: true },
    {
      name: "phone",
      label: "Số điện thoại",
      type: "input",
      isDisable: true,
    },
    {
      name: "username",
      label: "Tên đăng nhập",
      type: "input",
      isDisable: true,
    },
    {
      name: "status",
      label: "Trạng thái",
      type: "select",
      isDisable: true,
      options: [
        { value: "ACTIVE", text: "Hoạt động" },
        { value: "BLOCKED", text: "Chặn" },
      ],
    },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Cập nhật"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.USER.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <>
            {ars.map((item, index: number) => (
              <CustomizeForm
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                isDisable={item?.isDisable || false}
                type={item.type}
                options={item.options}
              />
            ))}
            <EuiSpacer />
            <EuiFlexGroup>
              <EuiButton
                style={{ marginRight: 16 }}
                onClick={() => navigate(Paths.PROFILE.EDIT)}
                color="danger"
                iconType="documentEdit"
              >
                Chỉnh sửa
              </EuiButton>
              <EuiSpacer />

              {/*<EuiButton*/}
              {/*  onClick={() => navigate(Paths.PROFILE.EDIT)}*/}
              {/*  color="danger"*/}
              {/*  iconType="documentEdit"*/}
              {/*>*/}
              {/*  Đổi mật khẩu*/}
              {/*</EuiButton>*/}
            </EuiFlexGroup>
          </>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Detail;
