import React, {useState} from 'react';
import { Controller } from 'react-hook-form';
import { EuiCheckbox, EuiFieldText, EuiFormRow, useGeneratedHtmlId } from '@elastic/eui';
import isEmpty from 'lodash/isEmpty';

export interface ICheckboxForm {
  control: any;
  name: string;
  require?: boolean | false;
  label: string;
  errors: any;
  isDisable?: boolean | false;
  id?: any;
}

export function CheckboxForm(props: ICheckboxForm) {
  const basicCheckboxId = useGeneratedHtmlId({ prefix: 'basicCheckbox' });

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ required: props.require }}
      render={({ field }) => {
        const {ref, ...otherProps} = field;
        return (
          <EuiFormRow
            fullWidth
            isDisabled={props.isDisable}
            isInvalid={!isEmpty(props.errors[props.name])}
            error={props?.errors[props.name]?.message}
            style={{marginTop: 10, marginBottom: 10}}
          >
            <EuiCheckbox
              {...otherProps}
              id={props.id || basicCheckboxId}
              label={props.label || 'Checkbox'}
              checked={otherProps.value}
            />
          </EuiFormRow>
        )
      }}
    />
  );
}
