/* eslint-disable react-hooks/exhaustive-deps */
import {
  EuiButton,
  EuiLoadingContent,
  EuiPageContentBody,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import { isEmpty } from "lodash";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import CustomBackPage from "../../../components/Page/Header";
import { ActionTypes, Paths } from "../../../constants";
import { IUpsertClassNumber } from "../../../typing/classNumber";
import { validation } from "../../../utils";
import { getDetailClassNumber, updateClassNumber } from "../services";
import history from "../../../navigation";
import { useDispatch } from "react-redux";

const Edit: FC = () => {
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const [isLoaded, setLoaded] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [classNumberDetail, setClassNumberDetail] = useState<IUpsertClassNumber>({
    roomId: "",
    subjectId: "",
    locationId: "",
    maxQuantity: 0
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<IUpsertClassNumber>({
    defaultValues: { ...classNumberDetail },
    resolver: validation.classNumber.edit(),
  });

  const fetchDetail = async () => {
    try {
      setLoaded(true);
      const req = await getDetailClassNumber(id);
      const classNumberData = req.data;
      setClassNumberDetail({
        roomId: classNumberData.roomId,
        subjectId: classNumberData.subjectId,
        locationId: classNumberData.locationId,
        maxQuantity: classNumberData.maxQuantity
      });
    }
    catch (e) { }
    finally {
      setLoaded(false);
    }
  }

  useEffect(() => {
    (async function fetchDetailClassNumber() {
      await fetchDetail();
    })();
  }, []);

  useEffect(() => {
    if (!isEmpty(classNumberDetail)) {
      reset({ ...classNumberDetail });
    }
  }, [classNumberDetail]);


  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await updateClassNumber(id, data);
      dispatch({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: "Cập nhật thông tin thành công",
          message: data?.message || "",
          type: "success",
        },
      })
      history.push("/class-numbers");
    } catch (e) {
      dispatch({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: "Cập nhật thông tin thất bại",
          message: `${e}` || "",
          type: "danger",
        },
      })
    } finally {
      setLoading(false);
    }
  };

  const ars = [
    { name: "roomId", label: "Phòng học", type: "selectSearch" },
    { name: "subjectId", label: "Môn học", type: "selectSearch" },
    { name: "locationId", label: "Địa điểm học", type: "selectSearch" },
    { name: "maxQuantity", label: "Sĩ số tối đa", type: "number" },
  ];

  return (
    !isLoaded ? <>
      <CustomBackPage pageTitle="Cập nhật sĩ số" pagePath={Paths.LOCATION.LIST} />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {ars.map((item, index: number) => (
              <CustomizeForm
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
              />
            ))}
            <EuiButton isLoading={isLoading} type="submit" fill >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </> : <EuiLoadingContent />
  );
};

export default Edit;
