import { EuiBadge, EuiButton, EuiPageHeader, EuiSpacer } from "@elastic/eui";
import i18n from "i18n-js";
import React, { FC, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteAction, EditAction, Access } from "../../../components";
import CustomizeFilter, {
  FilterInput,
} from "../../../components/common/CustomizeFilter";
import CustomizeTablePagination from "../../../components/common/CustomizeTablePagination";
import { Paths, PERMISSIONS } from "../../../constants";
import {
  archiveManyPermission,
  deleteManyPermission,
  deleteOnePermission,
  getListPermission,
  unarchiveManyPermission,
} from "../../../redux/actions/permission";
import { formatToLocalDate, getInitParams } from "../../../utils";
import { Warning } from "../Warning";

const List: FC = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useState(getInitParams());
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getListPermission(params));
  }, [params]);

  const { permissions, total } = useSelector(
    (state: RootStateOrAny) => state.permission.list
  );

  const onChangePage = (value: any) => {
    setParams({ ...params, offset: value });
  };

  const columns = [
    {
      field: "name",
      name: "Tên",

      render: (code: string) => <EuiBadge color="warning">{code}</EuiBadge>,
    },
    {
      field: "key",
      name: "Từ khoá",
    },
    // {
    //   field: "isArchived",
    //   name: "Lưu trữ",
    //   sortable: false,
    //   width: 120,
    //   render: (isArchived: boolean) => (
    //     <EuiBadge color={isArchived ? "warning" : "ghost"}>
    //       {isArchived ? "Đã lưu trữ" : "Không"}
    //     </EuiBadge>
    //   ),
    // },
    {
      field: "createdAt",
      name: "Khởi tạo",
      render: (time: string) => <p>{formatToLocalDate(time)}</p>,
    },
    {
      name: "Hành động",
      actions: [
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.PERMISSION_UPDATE} >
                <EditAction
                  link={Paths.PERMISSION.EDIT.replace(":id", item._id)}
                />
              </Access>
            );
          },
        },
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.PERMISSION_DELETE} >
              <DeleteAction
                onOk={() => {
                  dispatch(deleteOnePermission({ id: item._id }));
                }}
              />
              </Access>
            );
          },
        },
      ],
    },
  ];

  const onChangeItemsPerPage = (per: any) => {
    setParams({ ...params, limit: per });
  };
  const filterInputs: FilterInput[] = [
    { name: "name", label: "Tên", type: "input", visible: true },
    { name: "key", label: "Từ khóa", type: "input", visible: true },
    {
      name: "isArchived",
      label: "Lưu trữ",
      type: "select",
      options: [
        { value: "", text: "Tất cả" },
        { value: "true", text: "Đã lưu trữ" },
        { value: "false", text: "Không" },
      ],
    },
  ];

  const onFilter = (data: any) => {
    setParams({ ...params, ...data, offset: 0 });
  };

  const onDeleteMany = (ids: string[]) => {
    dispatch(deleteManyPermission({ ids }));
  };

  const onArchiveMany = (ids: string[]) => {
    dispatch(archiveManyPermission({ ids, isArchived: true }));
  };

  const onUnarchiveMany = (ids: string[]) => {
    dispatch(unarchiveManyPermission({ ids, isArchived: false }));
  };

  return (
    <>
      <Warning />
      <EuiSpacer size="s" />
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Quản lý quyền hạn"
        rightSideItems={[
          <Access permissionKey={PERMISSIONS.PERMISSION_CREATE} >
          <EuiButton
            iconSide="left"
            iconType="plusInCircle"
            fill
            onClick={() => navigate({ pathname: Paths.PERMISSION.CREATE })}
          >
            {i18n.t("common.createButton")}
          </EuiButton>
          </Access>
        ]}
      />
      <EuiSpacer size="s" />
      <CustomizeFilter
        list={filterInputs}
        onFilter={onFilter}
        page={Paths.PERMISSION.LIST}
      />
      <EuiSpacer size="s" />
      <CustomizeTablePagination
        columns={columns}
        body={permissions}
        pagination={params}
        total={total}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        isSelectable={true}
        onDeleteMany={onDeleteMany}
        onArchiveMany={onArchiveMany}
        onUnarchiveMany={onUnarchiveMany}
      />
    </>
  );
};

export default List;
