import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

interface IAccessProps {
  permissionKey: string | null;
  children?: any;
}

interface IAction {
  name: string;
  key: string;
  url: string;
  method: string;
}

interface IPermissionItem {
  name: string;
  key: string;
  actions: Array<IAction>;
}

export const checkPermission = (key: string, permissions: any[], isRootAdmin?: boolean) => {
  const keyTemp = (key || '').split('.');
  let isAccess: boolean = false;

  // check key in permission
  for (let i = 0; i < permissions.length; i++) {
    const permission = permissions.find((item: IPermissionItem) => {
      return item.key === keyTemp[0];
    });

    if (!permission) continue;

    // check action
    const action = permission.actions.find((item: IAction) => item.key === keyTemp[1]);
    if (action) isAccess = true;
  }

  // check if null
  if (key === null) isAccess = true;

  return isRootAdmin || isAccess;
};

export function Access(props: IAccessProps) {
  // get permissions in profile
  const { permissions = [], isRootAdmin} = useSelector(
    (state: RootStateOrAny) => state.profile?.data || {},
  );

  const isAccess = checkPermission(props.permissionKey || '', permissions, isRootAdmin);

  // check user can not access to this content
  if (!isAccess) return <></>;

  return (
    <>
      {props.children}
    </>
  );
}
