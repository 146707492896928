import {
    EuiButton,
    EuiPageContentBody,
    EuiPanel,
    EuiSpacer,
} from "@elastic/eui";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";

import CustomizeForm from "../../../components/common/form";
import {validation} from "../../../utils";
import {Paths} from "../../../constants";
import {IUpsertSubject} from "../../../typing/subject";
import {createSubject} from "../../../redux/actions/subject";
import CustomBackPage from "../../../components/Page/Header";
import {useEffect, useState} from "react";
import {getListSubjectForm, getlIstSubjectLevel, getListSubjectObject, getListSubjectType} from "../services";

const Create: React.FC = () => {
    const dispatch = useDispatch();

    const [masterData, setMasterData] = useState<{
        subjectType: any[],
        subjectObject: any[],
        subjectLevel: any[],
        subjectForm: any[],
    }>();

    useEffect(() => {
        (async function fetchMasterData() {
            const [sType, sObject, sLevel, sForm] = await Promise.all([
                getListSubjectType({pageSize: 1000}),
                getListSubjectObject({pageSize: 1000}),
                getlIstSubjectLevel({pageSize: 1000}),
                getListSubjectForm({pageSize: 1000})
            ]);
            setMasterData({
                subjectType: sType.data.map((t: any) => {
                    return {
                        id: t._id, 
                        value: t._id,
                        label: t.name
                    }
                }),
                subjectObject: sObject.data.map((o: any) => {
                    return {
                        id: o._id, 
                        value: o._id,
                        label: o.name
                    }
                }),
                subjectLevel: sLevel.data.map((l: any) => {
                    return {
                        id: l._id, 
                        value: l._id,
                        label: l.name
                    }
                }),
                subjectForm: sForm.data.map((f: any) => {
                    return {
                        id: f._id, 
                        value: f._id,
                        label: f.name}
                }),
            })
        })();
    }, [])

    const {handleSubmit, control, formState: {errors} ,setValue} = useForm<IUpsertSubject>({
        defaultValues: {
            name: "",
            code: "",
            subjectTypeId: "",
            subjectObjectId: "",
            subjectLevelId: "",
            subjectFormId: "",
            numberOfLessons: 0,
        },
        resolver: validation.subject.create(),
    });

    const onSubmit = (data: any) => {
        dispatch(createSubject(data));
    };

    const ars = [
        {name: "code", label: "Mã môn học", type: "input"},
        {name: "name", label: "Tên môn học", type: "input"},
        {
            name: "subjectTypeId",
            label: "Phân loại",
            type: "selectSearch",
            options: masterData?.subjectType,
            mode: 'single',
            fullWidth: true,
            onChangeValue: (value: string) => {
                setValue("subjectTypeId", value);
              },
        },
        {
            name: "subjectObjectId",
            label: "Đối tượng",
            type: "selectSearch",
            options: masterData?.subjectObject,
            mode: 'single',
            fullWidth: true,
            onChangeValue: (value: string) => {
                setValue("subjectObjectId", value);
              },
        },
        {
            name: "subjectLevelId",
            label: "Trình độ",
            type: "selectSearch",
            options: masterData?.subjectLevel,
            mode: 'single',
            fullWidth: true,
            onChangeValue: (value: string) => {
                setValue("subjectLevelId", value);
              },
        },
        {
            name: "subjectFormId",
            label: "Hình thức",
            type: "selectSearch",
            options: masterData?.subjectForm,
            mode: 'single',
            fullWidth: true,
            onChangeValue: (value: string) => {
                setValue("subjectFormId", value);
              },
        },
        {name: "numberOfLessons", label: "Số tiết học", type: "number"},
    ];

    return (
        <>
            <CustomBackPage pageTitle="Thêm lớp học mới" pagePath={Paths.SUBJECT.LIST}/>
            <EuiSpacer/>
            <EuiPageContentBody>
                <EuiPanel hasBorder>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {ars.map((item, index: number) => (
                            <CustomizeForm
                                {...item}
                                key={index}
                                name={item.name}
                                control={control}
                                label={item.label}
                                errors={errors}
                                type={item.type}
                                options={item?.options}
                                mode={item.mode || 'single'}
                                fullWidth={item?.fullWidth}
                            />
                        ))}
                        <EuiButton type="submit" fill>
                            Lưu
                        </EuiButton>
                    </form>
                </EuiPanel>
            </EuiPageContentBody>
        </>
    );
};

export default Create;
