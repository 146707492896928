import { AUTH_UPDATE_PROFILE, UPDATE_FILTERS } from "../../constants";

export const updateProfile = (payload: any) => {
  return {
    type: AUTH_UPDATE_PROFILE,
    payload,
  };
};

export const updateFilters = (payload: any) => {
  return {
    type: UPDATE_FILTERS,
    payload,
  };
};
