import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import isEmpty from "lodash/isEmpty";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import CustomizeForm from "../../../components/common/form";
import { getListRoles } from "../../../redux/actions/role";
import { createUser } from "../../../redux/actions/user";
import { validation } from "../../../utils";
import { Paths } from "../../../constants";

interface IFormInput {
  name: string;
  username: string;
  email: string;
  phone: string;
  type: string;
  roleId: string;
  status: string;
  password: string;
  repassword: string;
}

const Create: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userState = useSelector(
    (state: RootStateOrAny) => state.user
  );
  const roles = useSelector(
    (state: RootStateOrAny) => state?.role?.list?.data || []
  );

  useEffect(() => {
    dispatch(getListRoles({ limit: 1000, offset: 0 }));
  }, []);

  useEffect(() => {
    if (!isEmpty(roles)) {
      reset({
        roleId: roles[0]?._id,
      });
    }
  }, [roles]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      name: "",
      username: "",
      email: "",
      phone: "",
      type: "USER",
      roleId: "",
      status: "ACTIVE",
      password: "",
      repassword: "",
    },
    resolver: validation.user.create(),
  });
  const onSubmit = (data: any) => {
    console.log("data: ", data);
    dispatch(createUser(data));
  };

  const ars = [
    { name: "name", label: "Tên", type: "input" },
    { name: "username", label: "Tên đăng nhập", type: "input" },
    { name: "email", label: "Email", type: "input" },
    { name: "phone", label: "Số điện thoại", type: "input" },
    {
      name: "type",
      label: "Loại",
      type: "select",
      options: [{ value: "USER", text: "User" }],
    },
    {
      name: "roleId",
      label: "Vai trò",
      type: "select",
      options: roles.map((item: any) => ({ value: item._id, text: item.name })),
    },
    {
      name: "status",
      label: "Trạng thái",
      type: "select",
      options: [
        { value: "ACTIVE", text: "Hoạt động" },
        { value: "BLOCKED", text: "Chặn" },
      ],
    },
    { name: "password", label: "Mật khẩu", type: "password" },
    { name: "repassword", label: "Nhập lại mật khẩu", type: "password" },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Thêm người dùng mới"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.USER.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {ars.map((item, index: number) => (
              <CustomizeForm
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton isLoading={userState?.isCreateRequest || false} type="submit" fill>
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Create;
