import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
  EuiIcon,
  useEuiTheme,
} from '@elastic/eui';
import size from 'lodash/size';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Images, Paths } from '../../constants';
import { authAction, getAuthState } from '../../redux';

interface FormData {
  email: string;
}

export function ForgotPassword() {
  const { euiTheme } = useEuiTheme();
  const dispatch = useDispatch();
  const authState = useSelector(getAuthState);

  const [form, setForm] = useState<FormData>({
    email: '',
  });
  const [validation, setValidation] = useState({
    email: true,
  });

  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const checkValidation = () => {
    if (size(form.email) < 5) {
      setValidation({ ...validation, email: false });
      return false;
    }

    setValidation({ email: true });
    return true;
  };

  const handleForgotPassword = () => {
    if (!checkValidation()) return;

    // dispatch
    dispatch(authAction.forgotPassword(form));
  };

  const onKeyDown = (e: any) => e.key === 'Enter' && handleForgotPassword();

  return (
    <EuiFlexGroup
      justifyContent='center'
      alignItems='center'
      style={{
        width: '100%',
        height: '100%',
        background: euiTheme.colors.body,
      }}
    >
      <EuiFlexGroup
        style={{
          maxWidth: `calc(${euiTheme.size.base} * 45)`,
          boxShadow: `0 4px 20px 0 rgb(0 0 0 / 15%)`,
        }}
      >
        <EuiFlexItem grow={5}>
          <img src={Images.logoBrand} alt='logo' />
        </EuiFlexItem>
        <EuiFlexItem
          grow={7}
          style={{
            padding: `calc(${euiTheme.size.base} * 1.5)`,
          }}
        >
          <EuiTitle size='s'>
            <EuiTextColor>Quên mật khẩu</EuiTextColor>
          </EuiTitle>
          <EuiText size='s'>Hệ thống ERP Mỹ Thuật Bụi</EuiText>
          <EuiSpacer size='l' />
          <EuiForm component='form' onKeyDown={onKeyDown}>
            <EuiFormRow
              isInvalid={!validation.email}
              error={'Email ít nhất 5 kí tự'}
            >
              <EuiFieldText
                onChange={handleChange}
                name='email'
                placeholder='Email'
                isInvalid={!validation.email}
                type={'email'}
                prepend={<EuiIcon type={'email'} />}
              />
            </EuiFormRow>
            <EuiSpacer size='m' />
            <EuiButton
              isLoading={authState?.isLoadingRequest}
              fullWidth
              fill
              color='primary'
              style={{ textDecoration: 'none' }}
              onClick={handleForgotPassword}
            >
              Lấy lại mật khẩu
            </EuiButton>

            <EuiFlexItem style={{marginTop: 10}}>
              <EuiLink href={Paths.PUBLIC.LOGIN}>Đăng nhập</EuiLink>
            </EuiFlexItem>
          </EuiForm>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
}
