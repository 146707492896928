import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import { Paths } from "../../../constants";
import { getEmployeesApi, getSubjectsApi } from "../../../network";
import { createTeacherRole } from "../../../redux/actions/teacher-role";
import { getListLocation as getListLocationApi } from "../../location/services";

interface IFormInput {
  name: string;
  subjectId: string;
  employeeId: string;
  provinceId: string;
  startWorkingDate: string;
}

interface IEmployee {
  _id: string;
  name: string;
}

interface ISubject {
  _id: string;
  name: string;
}

interface ILocation {
  _id: string;
  name: string;
}

const Create: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [subjects, setSubjects] = useState<ISubject[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const teacherRoleState = useSelector(
    (state: RootStateOrAny) => state.teacherRole
  );

  const getListEmployee = async (searchValue?: string) => {
    const responseEmployee = await getEmployeesApi({ name: searchValue });
    if (responseEmployee && responseEmployee.data) {
      setEmployees(responseEmployee.data);
    }
  };

  const getListSubject = async (searchValue?: string) => {
    const response = await getSubjectsApi({ name: searchValue });
    if (response && response.data) {
      setSubjects(response.data);
    }
  };

  const getListLocation = async (searchValue?: string) => {
    const response = await getListLocationApi({ name: searchValue });
    if (response && response.data) {
      setLocations(response.data);
    }
  };

  useEffect(() => {
    getListEmployee();
    getListSubject();
    getListLocation();
  }, []);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>({
    defaultValues: {
      name: "",
      subjectId: "",
      employeeId: "",
      provinceId: "",
      startWorkingDate: "",
    },
    // resolver: validation.teacherRole.create(),
  });

  const onSubmit = (data: any) => {
    console.log("ss: ", {
      ...data,
      startWorkingDate: data.startWorkingDate.format("DD-MM-YYYY HH:MM"),
    });
    dispatch(createTeacherRole(data));
  };

  const formInput = [
    { name: "name", label: "Tên", type: "input", required: true },
    {
      name: "employeeId",
      label: "Nhân viên quản lý",
      type: "selectSearch",
      required: true,
      fullWidth: true,
      options: employees.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      // onSearchChange: getListEmployee,
      onChangeValue: (value: string) => {
        setValue("employeeId", value);
      },
    },
    {
      name: "subjectId",
      label: "Môn học",
      type: "selectSearch",
      required: true,
      fullWidth: true,
      options: subjects.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      // onSearchChange: getListSubject,
      onChangeValue: (value: string) => {
        setValue("subjectId", value);
      },
    },
    {
      name: "provinceId",
      label: "Địa điểm làm việc",
      type: "selectSearch",
      required: true,
      fullWidth: true,
      options: locations.map((item: any) => ({
        id: item.provinceId,
        value: item.provinceId,
        label: item.name,
      })),
      // onSearchChange: getListLocation,
      onChangeValue: (value: string) => {
        setValue("provinceId", value);
      },
    },
    {
      name: "startWorkingDate",
      label: "Ngày bắt đầu",
      type: "date",
      required: true,
      fullWidth: true,
    },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Thêm phân môn giảng dạy"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.TEACHER_ROLE.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formInput.map((item: any, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton
              isLoading={teacherRoleState?.isCreateRequest || false}
              type="submit"
              fill
            >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Create;
