import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../../network/http"
import { IDeleteManyLocation, IPatchLocation } from "../../typing/location";
const locationUrl = "locations"

const getListLocation = async (options?: any) => {
  return await getRequest(locationUrl, options || {});
}

const getDetailLocation = async (id: string, options?: any) => {
  return await getRequest(`${locationUrl}/${id}`, options || {});
}

const createLocation = async (data: any) => {
  return await postRequest(locationUrl, data);
}

const updateLocation = async (id: string, data: any) => {
  return await putRequest(`${locationUrl}/${id}`, data)
}

const deleteLocation = async (id: string) => {
  return await deleteRequest(`${locationUrl}/${id}`);
}

const deleteManyLocationApi = async (data: IDeleteManyLocation) => {
  return await deleteRequest(locationUrl, { data });
}

const archiveManyLocationApi = async (data: IPatchLocation) => {
  return await patchRequest(locationUrl, data);
}

const unarchiveManyLocationApi = async (data: IPatchLocation) => {
  return await patchRequest(locationUrl, data);
}

export {
  getListLocation,
  getDetailLocation,
  createLocation,
  updateLocation,
  deleteLocation,
  deleteManyLocationApi,
  archiveManyLocationApi,
  unarchiveManyLocationApi,
}