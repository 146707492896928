import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../components/auth";
import { Paths } from "../constants";

export const RequireAuth: React.FC = () => {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate to={Paths.PUBLIC.LOGIN} />;
  }

  return <Outlet />;
};
