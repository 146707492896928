import i18n from "i18n-js";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../../constants";
import * as actionTypes from "../../constants/ActionTypes";
import history from "../../navigation";
import {
  archiveManyRoleApi,
  createRoleApi,
  deleteManyRoleApi,
  deleteRoleApi,
  getActionsApi,
  getPermissionsApi,
  getRoleDetailApi,
  getRolesApi,
  updateRoleApi,
} from "../../network";
import { convertObjectParamsToStringParams, getInitParams } from "../../utils";
import { getRoleState } from "../selectors";
import { IRoleState } from "../types";

interface IPayload {
  type: string;
  payload: any;
}

interface IResponse {
  data: any;
  total: number;
}

function* getListRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const role: IRoleState = yield select<any>(getRoleState);

    // validate and set requesting
    if (role.isLoadingRequest) return;
    yield put({
      type: ActionTypes.SET_LOADING_ROLE,
      payload: { isLoadingRequest: true },
    });

    const { data, total }: IResponse = yield call(getRolesApi, action.payload);
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`);
    yield put({
      type: ActionTypes.GET_LIST_ROLE_SUCCESS,
      payload: { data, total },
    });
  } catch (error) {
    yield put({ type: ActionTypes.GET_LIST_ROLE_FAILED, payload: error });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isLoadingRequest: false },
    });
  }
}

function* getDetailRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const role: IRoleState = yield select<any>(getRoleState);

    // validate and set requesting
    if (role.isLoadingRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isLoadingRequest: true },
    });

    // get detail
    const { data, total }: IResponse = yield call(
      getRoleDetailApi,
      action.payload.id
    );

    yield put({
      type: ActionTypes.GET_DETAIL_ROLE_SUCCESS,
      payload: { data, total },
    });
  } catch (error) {
    yield put({ type: ActionTypes.GET_DETAIL_ROLE_FAILED, payload: error });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isLoadingRequest: false },
    });
  }
}

function* getPermissions(action: IPayload) {
  try {
    // get list permission
    const { data }: IResponse = yield call(getPermissionsApi, action.payload);
    if (data) {
      yield put({
        type: ActionTypes.GET_DETAIL_PERMISSION_ROLE_SUCCESS,
        payload: { permissions: data },
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* getActions(action: IPayload) {
  try {
    // get list action
    const { data }: IResponse = yield call(getActionsApi, { limit: 1000 });
    if (data) {
      yield put({
        type: ActionTypes.GET_DETAIL_ACTION_ROLE_SUCCESS,
        payload: { actions: data },
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* createRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const role: IRoleState = yield select<any>(getRoleState);

    // validate and set requesting
    if (role.isCreateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isCreateRequest: true },
    });

    const { data, total }: IResponse = yield call(
      createRoleApi,
      action.payload
    );

    history.push("/roles");
    yield put({
      type: ActionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    });
  } catch (error) {
    yield put({ type: ActionTypes.CREATE_ROLE_FAILED, payload: error });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isCreateRequest: false },
    });
  }
}

function* updateRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const role: IRoleState = yield select<any>(getRoleState);

    // validate and set requesting
    if (role.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isUpdateRequest: true },
    });

    const { data, total }: IResponse = yield call(
      updateRoleApi,
      action.payload.id,
      action.payload.data
    );
    history.push("/roles");
    yield put({
      type: ActionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    });
  } catch (error: any) {
    yield put({
      type: ActionTypes.NOTIFICATION,
      payload: {
        title: "Có lỗi xảy ra",
        message: error?.message || "",
        type: "danger",
      },
    });
    yield put({ type: ActionTypes.CREATE_ROLE_FAILED, payload: error });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isUpdateRequest: false },
    });
  }
}

function* deleteOneRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const role: IRoleState = yield select<any>(getRoleState);

    // validate and set requesting
    if (role.isDeleteRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isDeleteRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      deleteRoleApi,
      action.payload.id
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ROLE,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isDeleteRequest: false },
    });
  }
}

function* deleteManyRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const role: IRoleState = yield select<any>(getRoleState);

    // validate and set requesting
    if (role.isDeleteRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isDeleteRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      deleteManyRoleApi,
      action.payload.ids
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ROLE,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    console.log(error);
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isDeleteRequest: false },
    });
  }
}

function* archiveManyRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const role: IRoleState = yield select<any>(getRoleState);

    // validate and set requesting
    if (role.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isUpdateRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      archiveManyRoleApi,
      action.payload
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ROLE,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    console.log(error);
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isUpdateRequest: false },
    });
  }
}

function* unarchiveManyRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const role: IRoleState = yield select<any>(getRoleState);

    // validate and set requesting
    if (role.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isUpdateRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      archiveManyRoleApi,
      action.payload
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ROLE,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    console.log(error);
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROLE,
      payload: { isUpdateRequest: false },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_LIST_ROLE, getListRole),
    takeLatest(ActionTypes.GET_DETAIL_ROLE, getDetailRole),
    takeLatest(ActionTypes.CREATE_ROLE, createRole),
    takeLatest(ActionTypes.UPDATE_ROLE, updateRole),
    takeLatest(actionTypes.DELETE_ONE_ROLE, deleteOneRole),
    takeLatest(actionTypes.DELETE_MANY_ROLE, deleteManyRole),
    takeLatest(actionTypes.ARCHIVE_MANY_ROLE, archiveManyRole),
    takeLatest(actionTypes.UNARCHIVE_MANY_ROLE, unarchiveManyRole),

    takeLatest(actionTypes.GET_DETAIL_PERMISSION_ROLE, getPermissions),
    takeLatest(actionTypes.GET_DETAIL_ACTION_ROLE, getActions),
  ]);
}
