import {
  EuiButton,
  EuiFieldPassword,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import size from "lodash/size";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Images, Paths } from '../../constants';
import { getAuthState, authAction } from "../../redux";

interface FormData {
  verifyCode: string;
  password: string;
}

export function ResetPassword() {
  const { euiTheme } = useEuiTheme();
  const dispatch = useDispatch();
  const authState = useSelector(getAuthState);
  const [searchParams, setSearchParams] = useSearchParams();
  console.log({
    a: searchParams.get('verifyCode')
  });

  const [form, setForm] = useState<FormData>({
    verifyCode: searchParams.get('verifyCode') || '',
    password: "",
  });
  const [validation, setValidation] = useState({
    verifyCode: true,
    password: true,
  });

  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const checkValidation = () => {
    if (size(form.verifyCode) < 1) {
      setValidation({ ...validation, verifyCode: false });
      return false;
    }

    if (size(form.password) < 6) {
      setValidation({ ...validation, password: false });
      return false;
    }

    setValidation({ verifyCode: true, password: true });
    return true;
  };

  const handleResetPassword = () => {
    if (!checkValidation()) return;

    // dispatch
    dispatch(authAction.resetPassword(form))
  };

  const onKeyDown = (e: any) => e.key === "Enter" && handleResetPassword();

  return (
    <EuiFlexGroup
      justifyContent="center"
      alignItems="center"
      style={{
        width: "100%",
        height: "100%",
        background: euiTheme.colors.body,
      }}
    >
      <EuiFlexGroup
        style={{
          maxWidth: `calc(${euiTheme.size.base} * 45)`,
          boxShadow: `0 4px 20px 0 rgb(0 0 0 / 15%)`,
        }}
      >
        <EuiFlexItem grow={5}>
          <img src={Images.logoBrand} alt="logo" />
        </EuiFlexItem>
        <EuiFlexItem
          grow={7}
          style={{
            padding: `calc(${euiTheme.size.base} * 1.5)`,
          }}
        >
          <EuiTitle size="s">
            <EuiTextColor>Đặt lại mật khẩu</EuiTextColor>
          </EuiTitle>
          <EuiText size="s">Hệ thống ERP Mỹ Thuật Bụi</EuiText>
          <EuiSpacer size="l" />
          <EuiForm component="form" onKeyDown={onKeyDown}>
            <EuiFormRow
              isInvalid={!validation.password}
              error={"Mật khẩu ít nhất 6 kí tự"}
            >
              <EuiFieldPassword
                onChange={handleChange}
                name="password"
                placeholder="Password"
                isInvalid={!validation.password}
                type={'dual'}
              />
            </EuiFormRow>
            <EuiSpacer size="m" />
            <EuiButton
              isLoading={authState?.isLoadingRequest}
              fullWidth
              fill
              color="primary"
              style={{ textDecoration: "none" }}
              onClick={handleResetPassword}
            >
              Cập nhật
            </EuiButton>

            <EuiFlexItem style={{marginTop: 10}}>
              <EuiLink href={Paths.PUBLIC.LOGIN}>Đăng nhập</EuiLink>
            </EuiFlexItem>
          </EuiForm>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
}
