import React from 'react';
// import PropTypes from 'prop-types';
// import { withScope, captureException } from '@sentry/browser';

class ErrorBoundary extends React.PureComponent {
  state: StateProps;

  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: { message: '' } };
  }

  // noinspection JSUnusedGlobalSymbols
  static getDerivedStateFromError(error: any, info: any) {
    // You can also log the error to an error reporting service
    /*withScope(scope => {
      scope.setExtra('info', info);
      captureException(error);
    });*/
    return { hasError: true, error };
  }

  render() {
    const { props, state } = this;
    const { children } = props;
    const { hasError, error } = state;

    if (hasError) {
      return (
        <article className='error-boundary message is-danger'>
          <div className='message-header'>
            <p>This page crashed!</p>
          </div>
          <div className='message-body'>{error?.message}</div>
        </article>
      );
    }

    return children;
  }
}

/*ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
};*/

interface StateProps {
  hasError: boolean;
  error: {
    message: string;
  },
}

export default ErrorBoundary;
