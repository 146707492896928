import { Route, Routes } from "react-router-dom";
import Create from "./views/Create";
import Edit from "./views/Edit";
import List from "./views/List";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="create" element={<Create />} />
      <Route path=":id" element={<Edit />} />
    </Routes>
  );
};
