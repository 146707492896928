import {
  EuiButton,
  EuiPageContentBody,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import CustomizeForm from "../../../components/common/form";
import CustomBackPage from "../../../components/Page/Header";
import { Paths } from "../../../constants";
import { getLocationsApi } from "../../../network";
import { createRoom } from "../../../redux/actions/room";
import { IUpsertRoom } from "../../../typing/room";
import { validation } from "../../../utils";

const Create: React.FC = () => {
  const dispatch = useDispatch();

  const [locations, setLocations] = useState<any[]>([]);

  const getListLocation = async (searchValue?: string) => {
    const response = await getLocationsApi({ name: searchValue });
    if (response && response.data) {
      setLocations(response.data);
    }
  };

  useEffect(() => {
    getListLocation();
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<IUpsertRoom>({
    defaultValues: {
      code: "",
      locationId: "",
      maxQuantity: 0,
    },
    resolver: validation.room.create(),
  });

  const onSubmit = (data: any) => {
    dispatch(createRoom(data));
  };

  const ars = [
    { name: "code", label: "Mã lớp", type: "input" },
    {
      name: "locationId",
      label: "Cơ sở",
      type: "selectSearch",
      fullWidth: true,
      options: locations.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      // onSearchChange: getListLocation,
      onChangeValue: (value: string) => {
        setValue("locationId", value);
      },
    },
    { name: "maxQuantity", label: "Sĩ số tối đa", type: "number" },
  ];

  return (
    <>
      <CustomBackPage pageTitle="Thêm lớp học mới" pagePath={Paths.ROOM.LIST} />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {ars.map((item, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton type="submit" fill>
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Create;
