/* eslint-disable react-hooks/exhaustive-deps */
import {
  EuiButton,
  EuiPageContentBody,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import isEmpty from "lodash/isEmpty";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import CustomBackPage from "../../../components/Page/Header";
import { Paths } from "../../../constants";
import { getLocationsApi } from "../../../network";
import { getListRoles } from "../../../redux/actions/role";
import { getDetailRoom, updateDetailRoom } from "../../../redux/actions/room";
import { IUpsertRoom } from "../../../typing/room";
import { validation } from "../../../utils";

const Edit: FC = () => {
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const roomDetail = useSelector(
    (state: RootStateOrAny) => state.room.detail.rooms
  );

  const [locations, setLocations] = useState<any[]>([]);
  const [locationsSelected, setLocationsSelected] = useState<any>([]);

  const getListLocation = async (searchValue?: string) => {
    const response = await getLocationsApi({ name: searchValue });
    if (response && response.data) {
      setLocations(response.data);
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IUpsertRoom>({
    defaultValues: { ...roomDetail },
    resolver: validation.room.edit(),
  });

  useEffect(() => {
    dispatch(getDetailRoom(id));
    dispatch(getListRoles({ limit: 1000, offset: 0 }));
  }, []);

  useEffect(() => {
    if (!isEmpty(roomDetail) && roomDetail._id === id) {
      reset({ ...roomDetail });

      // set location selected
      const locationData = roomDetail?.locationId
        ? [
            {
              _id: roomDetail?.locationId,
              value: roomDetail?.locationId,
              label: roomDetail?.location?.name,
            },
          ]
        : [];
      setLocationsSelected(locationData);
    }
  }, [roomDetail]);

  const onSubmit = (data: any) => {
    dispatch(updateDetailRoom({ roomId: id, data }));
  };

  const ars = [
    { name: "code", label: "Mã lớp", isDisable: true, type: "input" },
    {
      name: "locationId",
      label: "Cơ sở",
      type: "selectSearch",
      fullWidth: true,
      options: locations.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: locationsSelected,
      // onSearchChange: getListLocation,
      onChangeValue: (value: string) => {
        setLocationsSelected(null);
        setValue("locationId", value);
      },
    },
    { name: "maxQuantity", label: "Sĩ số tối đa", type: "number" },
  ];

  return (
    <>
      <CustomBackPage pageTitle="Thêm lớp học mới" pagePath={Paths.ROOM.LIST} />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {ars.map((item, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                isDisable={item?.isDisable || false}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton type="submit" fill>
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Edit;
