import { EuiComboBox, EuiFormRow } from "@elastic/eui";
import isEmpty from "lodash/isEmpty";
import { useCallback, useState } from "react";
import { Controller } from "react-hook-form";

export interface ISelectForm {
  control: any;
  name: string;
  label: string;
  errors: any;
  options: any[];
  customSelected?: any[];
  setCustomSelected?: any;
  required?: boolean | false;
  isDisable?: boolean | false;
  fullWidth?: boolean;
  mode?: "multiple" | "single";
  onChangeValue?: (value: any) => void;
  onSearchChange?: (value: any) => void;

  [key: string]: any;
}

export function SelectSearchForm(props: ISelectForm) {
  const {
    control,
    name,
    label,
    errors,
    options,
    required,
    fullWidth,
    isDisable,
    customSelected,
    onChangeValue,
    setCustomSelected,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOpts, setSelected] = useState<any[]>([]);

  const onSelectChange = (selectedOptions: any) => {
    if (setCustomSelected) setCustomSelected(selectedOptions);
    else setSelected(selectedOptions);

    // check function
    if (onChangeValue) {
      const value =
        props.mode === "multiple"
          ? selectedOptions.map((item: any) => item.id)
          : selectedOptions[0]?.id;
      onChangeValue(value);
    }
  };

  const onSearchChange = useCallback(async (searchValue: string) => {
    try {
      setIsLoading(true);
      if (!props?.onSearchChange) return;

      await props.onSearchChange(searchValue);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => {
        const { ref, ...otherProps } = field;

        return (
          <EuiFormRow
            fullWidth={fullWidth ? fullWidth : true}
            isDisabled={isDisable}
            label={label}
            isInvalid={!isEmpty(errors[name])}
            error={errors[name]?.message}
          >
            <EuiComboBox
              fullWidth={fullWidth ? fullWidth : true}
              singleSelection={
                props.mode === "single" ? { asPlainText: true } : false
              }
              options={options}
              selectedOptions={customSelected ? customSelected : selectedOpts}
              onChange={onSelectChange}
              onSearchChange={onSearchChange}
              isClearable={true}
              isLoading={isLoading}
            />
          </EuiFormRow>
        );
      }}
    />
  );
}
