import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import CustomizeForm from "../../../components/common/form";
import { validation } from "../../../utils";
import { Paths } from "../../../constants";
import { getEmployeesApi, getDepartmentsApi } from "../../../network";
import { createDepartment } from "../../../redux/actions/department";

interface IFormInput {
  name: string;
  parentDepartmentId: string;
  employeeIdManagement: string;
  isDefault: boolean;
  isSpecialize: boolean;
  isOperator: boolean;
}

interface IEmployee {
  _id: string;
  name: string;
}

interface IDepartment {
  _id: string;
  name: string;
}

const Create: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const departmentState = useSelector(
    (state: RootStateOrAny) => state.department
  );

  const getListEmployee = async (searchValue?: string) => {
    const responseEmployee = await getEmployeesApi({name: searchValue});
    if (responseEmployee && responseEmployee.data) {
      setEmployees(responseEmployee.data);
    }
  }

  const getListDepartment = async (searchValue?: string) => {
    const response = await getDepartmentsApi({name: searchValue});
    if (response && response.data) {
      setDepartments(response.data);
    }
  }

  useEffect(() => {
    getListEmployee();
    getListDepartment();
  }, []);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm<IFormInput>({
    defaultValues: {
      name: "",
      parentDepartmentId: "",
      employeeIdManagement: "",
      isSpecialize: false,
      isDefault: false,
      isOperator: false,
    },
    resolver: validation.department.create(),
  });
  const onSubmit = (data: any) => {
    console.log({data});
    dispatch(createDepartment(data));
  };

  const formInput = [
    { name: "name", label: "Tên", type: "input", required: true },
    {
      name: "parentDepartmentId",
      label: "Thuộc phòng ban",
      type: "selectSearch",
      fullWidth: true,
      options: [{_id: '', name: 'Chọn'}, ...departments].map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      // onSearchChange: getListDepartment,
      onChangeValue: (value: string) => {
        setValue('parentDepartmentId', value);
      }
    },
    {
      name: "employeeIdManagement",
      label: "Nhân viên quản lý",
      type: "selectSearch",
      required: true,
      fullWidth: true,
      options: employees.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      // onSearchChange: getListEmployee,
      onChangeValue: (value: string) => {
        setValue('employeeIdManagement', value);
      }
    },
    {
      id: "isDefault",
      name: "isDefault",
      label: "Phòng ban cơ bản",
      type: "checkbox",
    },
    {
      id: "isSpecialize",
      name: "isSpecialize",
      label: "Phòng ban chuyên môn",
      type: "checkbox",
    },
    {
      name: "isOperator",
      label: "Phòng ban vận hành",
      type: "checkbox",
    },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Thêm phòng ban"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.DEPARTMENT.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formInput.map((item: any, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton
              isLoading={departmentState?.isCreateRequest || false}
              type="submit"
              fill
            >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Create;
