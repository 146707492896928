/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { EuiGlobalToastList } from "@elastic/eui";
import { RootStateOrAny, useSelector } from "react-redux";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";


const Toaster: React.FC = () => {
  const [toast, setToast] = useState<Toast[]>([]);
  const { title, message, isNoti, type } = useSelector(
    (state: RootStateOrAny) => state.common.notification
  );

  useEffect(() => {
    if (message || title) {
      setToast(
        toast.concat([
          { id: "", title: title, text: message, color: type },
        ])
      );
    }
  }, [isNoti]);

  return (
    <div style={{ maxWidth: 320 }}>
      <EuiGlobalToastList
        side="right"
        toasts={toast}
        dismissToast={() => {
          setToast([]);
        }}
        toastLifeTimeMs={2000}
      />
    </div>
  );
};

export default Toaster;
