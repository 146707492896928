import React from "react";
import { Route, Routes } from "react-router-dom";
import Edit from "./views/Edit";
import Detail from "./views/Detail";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Detail />} />
      <Route path="edit" element={<Edit />} />
    </Routes>
  );
};
