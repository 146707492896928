import {getRequest} from "../../network/http";

const subjectTypeUrl = "subject-types";
const subjectObjectUrl = "subject-objects";
const subjectFormUrl = "subject-forms";
const subjectLevelUrl = "subject-levels";
const subjectUrl = "subjects";

const getListSubjectType = async (options?: any) => {
    return await getRequest(subjectTypeUrl, options || {});
}

const getListSubjectObject = async(options?: any) => {
    return await getRequest(subjectObjectUrl, options || {});
}

const getListSubjectForm = async (options?: any) =>{
    return await getRequest(subjectFormUrl, options || {});
}

const getlIstSubjectLevel = async (options?: any) => {
    return await getRequest(subjectLevelUrl, options || {});
}

const getDetailSubject = async (id: string, options?: any) => {
    return await getRequest(`${subjectUrl}/${id}`, options || {});
  }

export {
    getListSubjectType,
    getListSubjectObject,
    getListSubjectForm,
    getlIstSubjectLevel,
    getDetailSubject
}