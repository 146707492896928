/* eslint-disable react-hooks/exhaustive-deps */
import {
    EuiButton,
    EuiPageContentBody,
    EuiPanel,
    EuiSpacer,
} from "@elastic/eui";
import isEmpty from "lodash/isEmpty";
import {FC, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import CustomBackPage from "../../../components/Page/Header";
import {Paths} from "../../../constants";
import {updateDetailSubject} from "../../../redux/actions/subject";
import {IUpsertSubject} from "../../../typing/subject";
import {validation} from "../../../utils";
import {
    getListSubjectType,
    getListSubjectObject,
    getlIstSubjectLevel,
    getListSubjectForm,
    getDetailSubject
} from "../services";

const Edit: FC = () => {
    const dispatch = useDispatch();
    const {id}: any = useParams();

    const [subjectDetail, setSubjectDetail] = useState<IUpsertSubject>({
        name: "",
        code: "",
        subjectTypeId: "",
        subjectObjectId: "",
        subjectLevelId: "",
        subjectFormId: "",
        numberOfLessons: 0,
    });
    const [masterData, setMasterData] = useState<{
        subjectType: any[],
        subjectObject: any[],
        subjectLevel: any[],
        subjectForm: any[],
    }>();
    const [selectedSType, setSelectedSType] = useState<any>([]);
    const [selectedSLevel, setSelectedSLevel] = useState<any>([]);
    const [selectedSForm, setSelectedSForm] = useState<any>([]);
    const [selectedSObject, setSelectedSObject] = useState<any>([]);


    useEffect(() => {
        if (!isEmpty(subjectDetail)) {
            reset({...subjectDetail});
        }
    }, [subjectDetail]);

    useEffect(() => {
        (async function fetchMasterData() {
            const [sType, sObject, sLevel, sForm] = await Promise.all([
                getListSubjectType({pageSize: 1000}),
                getListSubjectObject({pageSize: 1000}),
                getlIstSubjectLevel({pageSize: 1000}),
                getListSubjectForm({pageSize: 1000})
            ]);
            setMasterData({
                subjectType: sType.data.map((t: any) => {
                    return {
                        id: t._id,
                        value: t._id,
                        label: t.name
                    }
                }),
                subjectObject: sObject.data.map((o: any) => {
                    return {
                        id: o._id,
                        value: o._id,
                        label: o.name
                    }
                }),
                subjectLevel: sLevel.data.map((l: any) => {
                    return {
                        id: l._id,
                        value: l._id,
                        label: l.name
                    }
                }),
                subjectForm: sForm.data.map((f: any) => {
                    return {
                        id: f._id,
                        value: f._id,
                        label: f.name
                    }
                }),
            })
        })().then(async () => {
            const req = await getDetailSubject(id);
            const subjectDetail = req.data;
            setSubjectDetail({
                name: subjectDetail.name,
                code: subjectDetail.code,
                subjectTypeId: subjectDetail.subjectTypeId,
                subjectObjectId: subjectDetail.subjectObjectId,
                subjectLevelId: subjectDetail.subjectLevelId,
                subjectFormId: subjectDetail.subjectFormId,
                numberOfLessons: subjectDetail.numberOfLessons,
            });
          
            setSelectedSLevel( [{
                _id: subjectDetail?.subjectLevel?._id,
                value: subjectDetail?.subjectLevel?._id,
                label: subjectDetail?.subjectLevel?.name,
               }]);

               setSelectedSType( [{
                _id: subjectDetail?.subjectType?._id,
                value: subjectDetail?.subjectType?._id,
                label: subjectDetail?.subjectType?.name,
               }]);

               setSelectedSForm( [{
                _id: subjectDetail?.subjectForm?._id,
                value: subjectDetail?.subjectForm?._id,
                label: subjectDetail?.subjectForm?.name,
               }]);

               setSelectedSObject( [{
                _id: subjectDetail?.subjectObject?._id,
                value: subjectDetail?.subjectObject?._id,
                label: subjectDetail?.subjectObject?.name,
               }]);
        });
    }, [])

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        setValue
    } = useForm<IUpsertSubject>({
        defaultValues: {...subjectDetail},
        resolver: validation.subject.edit(),
    });

    const onSubmit = (data: any) => {
        dispatch(updateDetailSubject({subjectId: id, data}));
    };

    const ars = [
        {name: "code", label: "Mã môn học", type: "input"},
        {name: "name", label: "Tên môn học", type: "input"},
        {
            name: "subjectTypeId",
            label: "Phân loại",
            type: "selectSearch",
            options: masterData?.subjectType,
            mode: 'single',
            fullWidth: true,
            customSelected: selectedSType,
            setCustomSelected: setSelectedSType,
            onChangeValue: (value: string) => {
                setValue("subjectTypeId", value);
            },
        },
        {
            name: "subjectObjectId",
            label: "Đối tượng",
            type: "selectSearch",
            options: masterData?.subjectObject,
            mode: 'single',
            fullWidth: true,
            customSelected: selectedSObject,
            setCustomSelected: setSelectedSObject,
            onChangeValue: (value: string) => {
                setValue("subjectObjectId", value);
            },
        },
        {
            name: "subjectLevelId",
            label: "Trình độ",
            type: "selectSearch",
            options: masterData?.subjectLevel,
            mode: 'single',
            fullWidth: true,
            customSelected: selectedSLevel,
            setCustomSelected: setSelectedSLevel,
            onChangeValue: (value: string) => {
                setValue("subjectLevelId", value);
            },
        },
        {
            name: "subjectFormId",
            label: "Hình thức",
            type: "selectSearch",
            options: masterData?.subjectForm,
            mode: 'single',
            fullWidth: true,
            customSelected: selectedSForm,
            setCustomSelected: setSelectedSForm,
            onChangeValue: (value: string) => {
                setValue("subjectFormId", value);
            },
        },
        {name: "numberOfLessons", label: "Số tiết học", type: "number"},
    ];

    return (
        <>
            <CustomBackPage pageTitle="Cập nhật môn học" pagePath={Paths.SUBJECT.LIST}/>
            <EuiSpacer/>
            <EuiPageContentBody>
                <EuiPanel hasBorder>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {ars.map((item, index: number) => (
                            <CustomizeForm
                                {...item}
                                key={index}
                                name={item.name}
                                control={control}
                                label={item.label}
                                errors={errors}
                                // isDisable={item?.isDisable || false}
                                type={item.type}
                                // options={item.options}
                            />
                        ))}

                        <EuiButton type="submit" fill>
                            Lưu
                        </EuiButton>
                    </form>
                </EuiPanel>
            </EuiPageContentBody>
        </>
    );
};

export default Edit;
