/* eslint-disable react-hooks/exhaustive-deps */
import { EuiBadge, EuiButton, EuiPageHeader, EuiSpacer } from "@elastic/eui";
import i18n from "i18n-js";
import { FC, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomizeFilter,
  CustomizeTablePagination,
  DeleteAction,
  EditAction,
  FilterInput,
  Access,
} from "../../../components";
import { Paths, PERMISSIONS } from "../../../constants";
import {
  archiveManySchoolHour,
  deleteManySchoolHour,
  deleteOneSchoolHour,
  getListSchoolHour,
  unarchiveManySchoolHour,
} from "../../../redux";
import { getInitParams, formatToLocalDate } from "../../../utils";

const List: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState(getInitParams());

  useEffect(() => {
    dispatch(getListSchoolHour(params));
  }, [params]);

  const { data = [], total } = useSelector(
    (state: RootStateOrAny) => state?.schoolHour?.list || {}
  );

  const { isLoadingRequest } = useSelector(
    (state: RootStateOrAny) => state.schoolHour
  );

  const onChangePage = (value: any) => {
    setParams({ ...params, offset: value });
  };

  const onDeleteMany = (ids: string[]) => {
    dispatch(deleteManySchoolHour({ ids }));
  };

  const onArchiveMany = (ids: string[]) => {
    dispatch(archiveManySchoolHour({ ids, isArchived: true }));
  };

  const onUnarchiveMany = (ids: string[]) => {
    dispatch(unarchiveManySchoolHour({ ids, isArchived: false }));
  };

  const columns = [
    {
      field: "timeFrame",
      name: "Khung giờ",
      sortable: true,
    },
    {
      field: "startHourName",
      name: 'Giờ bắt đầu',
    },
    {
      field: "endHourName",
      name: 'Giờ kết thúc',
    },
    {
      field: "studySchedule",
      name: 'Buổi',
      render: (studySchedule: string) => {
        let name = '';
        switch (studySchedule) {
          case 'MORNING': {
            name = 'Sáng';
            break;
          }
          case 'AFTERNOON': {
            name = 'Chiều';
            break;
          }
          case 'EVENING': {
            name = 'Tối';
            break;
          }
        }
        return (<EuiBadge>{name}</EuiBadge>);
      }
    },
    {
      field: "isArchived",
      name: "Lưu trữ",
      sortable: false,
      render: (isArchived: boolean) => (
        <EuiBadge color={isArchived ? "warning" : "ghost"}>
          {isArchived ? "Đã lưu trữ" : "Không"}
        </EuiBadge>
      ),
    },
    {
      field: "createdAt",
      name: "Khởi tạo",
      render: (time: string) => <p>{formatToLocalDate(time)}</p>,
    },
    {
      name: "Hành động",
      actions: [
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.SCHOOL_HOUR_UPDATE}>
                <EditAction
                  link={Paths.SCHOOL_HOUR.EDIT.replace(":id", item._id)}
                />
              </Access>
            );
          },
        },
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.SCHOOL_HOUR_DELETE}>
                <DeleteAction
                  onOk={() => {
                    dispatch(deleteOneSchoolHour({ id: item._id }));
                  }}
                />
              </Access>
            );
          },
        },
      ],
    },
  ];

  const onChangeItemsPerPage = (per: any) => {
    setParams({ ...params, limit: per });
  };

  const filterInputs: FilterInput[] = [
    { name: "timeFrame", label: "Tên", type: "input", visible: true },
    {
      name: "isArchived",
      label: "Lưu trữ",
      type: "select",
      options: [
        { value: "", text: "Tất cả" },
        { value: "true", text: "Đã lưu trữ" },
        { value: "false", text: "Không" },
      ],
    },
  ];

  const onFilter = (data: any) => {
    const newFilter: any = { ...params, ...data };
    for (let key in newFilter) {
      if (newFilter[key] === "" || newFilter[key] === undefined) {
        delete newFilter[key];
      }
    }
    setParams({ ...newFilter, offset: 0 });
  };

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Quản lý ca học"
        rightSideItems={[
          <Access permissionKey={PERMISSIONS.SCHOOL_HOUR_CREATE}>
            <EuiButton
              iconSide="left"
              iconType="plusInCircle"
              fill
              onClick={() => navigate({ pathname: Paths.SCHOOL_HOUR.CREATE })}
            >
              {i18n.t("common.createButton")}
            </EuiButton>
          </Access>,
        ]}
      />
      <EuiSpacer size="s" />

      <CustomizeFilter
        list={filterInputs}
        onFilter={onFilter}
        page={Paths.SCHOOL_HOUR.LIST}
      />

      <EuiSpacer size="s" />

      <CustomizeTablePagination
        columns={columns}
        body={data}
        isLoading={isLoadingRequest}
        pagination={params}
        total={total}
        isSelectable={true}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onDeleteMany={onDeleteMany}
        onArchiveMany={onArchiveMany}
        onUnarchiveMany={onUnarchiveMany}
      />
    </>
  );
};

export default List;
