import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import CustomizeForm from "../../../components/common/form";
import { validation } from "../../../utils";
import { Paths } from "../../../constants";
import { createSchoolHour } from "../../../redux";

interface IFormInput {
  timeFrame: string;
  startHourName: string;
  endHourName: string;
  studySchedule: string;
  startHour: number;
  endHour: number;
}

const Create: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schoolHourState = useSelector(
    (state: RootStateOrAny) => state.schoolHour
  );

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
    setValue
  } = useForm<IFormInput>({
    defaultValues: {
      timeFrame: "",
      startHourName: "",
      endHourName: "",
      studySchedule: "",
      startHour: 0,
      endHour: 0,
    },
    resolver: validation.schoolHour.create(),
  });

  const watchFields = watch(["startHourName", "endHourName"]);

  const updateTimeFrame = (value: any) => {
    if (value.startHourName && value.endHourName) {
      // @ts-ignore
      let tHour = value.startHourName.hours();
      if (tHour < 10) tHour = '0'+tHour;
      // @ts-ignore
      let tMinute = value.startHourName.minutes();
      if (tMinute < 10) tMinute = '0'+tMinute;

      // @ts-ignore
      let eHour = value.endHourName.hours();
      if (eHour < 10) eHour = '0'+eHour;
      // @ts-ignore
      let eMinute = value.endHourName.minutes();
      if (eMinute < 10) eMinute = '0'+eMinute;

      setValue('timeFrame', `${tHour}:${tMinute} - ${eHour}:${eMinute}`);
    }
  }

  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  useEffect(() => {
    watch((value, { name, type }) => {
      let startHour = 0;
      let endHour = 0;
      if (name === 'startHourName' && type === 'change') {
        // @ts-ignore
        startHour = value.startHourName.hours() * 60 + value.startHourName.minutes();
        setValue('startHour', startHour);

        updateTimeFrame(value);
      }

      if (name === 'endHourName' && type === 'change') {
        // @ts-ignore
        endHour = value.endHourName.hours() * 60 + value.endHourName.minutes();
        setValue('endHour', endHour);

        updateTimeFrame(value);
      }
    });
  }, [watch]);


  const onSubmit = (data: any) => {
    console.log({data});
    dispatch(createSchoolHour(data));
  };

  const formInput = [
    { name: "timeFrame", label: "Khung giờ", type: "input", isDisable: true },
    {
      name: "startHourName",
      label: "Từ giờ",
      type: "time",
      required: true
    },
    {
      name: "endHourName",
      label: "Đến giờ",
      type: "time",
      required: true
    },
    {
      name: "studySchedule",
      label: "Buổi",
      type: "select",
      options: [
        {value: '', label: 'Chọn buổi', text: 'Chọn buổi'},
        {value: 'MORNING', label: 'Sáng', text: 'Sáng'},
        {value: 'AFTERNOON', label: 'Chiều', text: 'Chiều'},
        {value: 'EVENING', label: 'Tối', text: 'Tối'},
      ]
    },

  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Thêm mới"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.SCHOOL_HOUR.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formInput.map((item: any, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton
              isLoading={schoolHourState?.isCreateRequest || false}
              type="submit"
              fill
            >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Create;
