/* eslint-disable react-hooks/exhaustive-deps */
import { EuiBadge, EuiButton, EuiPageHeader, EuiSpacer } from "@elastic/eui";
import i18n from "i18n-js";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomizeFilter,
  CustomizeTablePagination,
  DeleteAction,
  EditAction,
  FilterInput,
} from "../../../components";
import { Paths } from "../../../constants";
import { dateFormat, getInitParams } from "../../../utils";
import { archiveManyClassNumberApi, deleteClassNumber, deleteManyClassNumberApi, getListClassNumber, unarchiveManyClassNumberApi } from "../services";

const List: FC = () => {
  const navigate = useNavigate();
  const [classNumber, setClassNumber] = useState<{
    data: any[],
    total: number,
  }>({
    data: [],
    total: 0
  });
  const [params, setParams] = useState(getInitParams());
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchList = async () => {
    try {
      setLoading(true);
      const result = await getListClassNumber(params);
      setClassNumber(result)
    }
    catch (e) {
      return;
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchList();
  }, [params]);

  const onChangePage = (value: any) => {
    setParams({ ...params, offset: value });
  };

  const onDeleteMany = async (ids: string[]) => {
    await deleteManyClassNumberApi({ ids }).then(() => fetchList());
  };

  const onArchiveMany = async (ids: string[]) => {
    await archiveManyClassNumberApi({ ids, isArchived: true }).then(() => fetchList());
  };

  const onUnarchiveMany = async (ids: string[]) => {
    await unarchiveManyClassNumberApi({ ids, isArchived: false }).then(() => fetchList());
  };

  const columns = [
    {
      field: "maxQuantity",
      name: "Sĩ số tối đa",
    },
    {
      field: "isArchived",
      name: "Lưu trữ",
      sortable: false,
      width: 120,
      render: (isArchived: boolean) => (
        <EuiBadge color={isArchived ? "warning" : "ghost"}>
          {isArchived ? "Đã lưu trữ" : "Không"}
        </EuiBadge>
      ),
    },
    {
      field: "createdAt",
      name: "Khởi tạo",
      render: (time: string) => <p>{dateFormat(time)}</p>,
    },
    {
      field: "updatedAt",
      name: "Cập nhật",
      render: (time: string) => <p>{dateFormat(time)}</p>,
    },
    {
      name: "Hành động",
      actions: [
        {
          render: (item: any) => {
            return (
              <EditAction link={Paths.CLASS_NUMBER.EDIT.replace(":id", item._id)} />
            );
          },
        },
        {
          render: (item: any) => {
            return (
              <DeleteAction
                onOk={() => {
                  deleteClassNumber(item._id).then(() => {
                    fetchList();
                  });
                }}
              />
            );
          },
        },
      ],
    },
  ];

  const onChangeItemsPerPage = (per: any) => {
    setParams({ ...params, limit: per });
  };

  const filterInputs: FilterInput[] = [
    { name: "name", label: "Tên địa điểm", type: "input", visible: true },
    { name: "code", label: "Mã địa điểm", type: "input", visible: true },

  ];

  const onFilter = (data: any) => {
    const newFilter: any = { ...params, ...data };
    for (let key in newFilter) {
      if (newFilter[key] === "" || newFilter[key] === undefined) {
        delete newFilter[key];
      }
    }
    setParams(newFilter);
  };

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Quản lý sĩ số tối đa"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="plusInCircle"
            fill
            onClick={() => navigate({ pathname: Paths.CLASS_NUMBER.CREATE })}
          >
            {i18n.t("common.createButton")}
          </EuiButton>,
        ]}
      />
      <EuiSpacer size="s" />

      <CustomizeFilter
        list={filterInputs}
        onFilter={onFilter}
        page={Paths.ROOM.LIST}
      />

      <EuiSpacer size="s" />

      <CustomizeTablePagination
        columns={columns}
        body={classNumber.data}
        isLoading={isLoading}
        pagination={params}
        total={classNumber.total}
        isSelectable={true}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onDeleteMany={onDeleteMany}
        onArchiveMany={onArchiveMany}
        onUnarchiveMany={onUnarchiveMany}
      />
    </>
  );
};

export default List;
