import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiTitle,
  useGeneratedHtmlId,
} from "@elastic/eui";
import React, { FC } from "react";

interface Props {
  isVisible: boolean;
  onToggle: () => void;
  onSubmit: () => void;
  onReset: () => void;
}

export const Flyout: FC<Props> = ({
  isVisible,
  onToggle,
  onSubmit,
  children,
  onReset,
}) => {
  const largeFlyoutTitleId = useGeneratedHtmlId({ prefix: "largeFlyoutTitle" });

  let flyout;
  if (isVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={onToggle}
        size="s"
        aria-labelledby={largeFlyoutTitleId}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id={largeFlyoutTitleId}>Bộ lọc khác</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>{children}</EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiButton minWidth={'auto'} color={'danger'} iconType="cross" onClick={onToggle}>
                Đóng
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton minWidth={'auto'} onClick={onReset}>Reset</EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                minWidth={'auto'}
                onClick={() => {
                  onSubmit();
                  onToggle();
                }}
                fill
              >
                Tìm kiếm
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    );
  }
  return <div>{flyout}</div>;
};
