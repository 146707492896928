import { NETWORK_ERROR } from '../../constants';

export class NetworkError extends Error {
  data: any;
  isLogAble: boolean;
  code: number = NETWORK_ERROR;

  constructor(message: string, name: string, data: any) {
    super(message);
    this.name = name;
    this.data = data;
    this.isLogAble = false;
  }
}
