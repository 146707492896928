import {
  EuiButton,
  EuiFieldPassword,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
  useEuiTheme,
} from "@elastic/eui";
import size from "lodash/size";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";

import { useAuthContext } from "../../components/auth";
import { Images, IS_AUTHENTICATED, Paths } from "../../constants";

export interface LoginFormData {
  username: string;
  password: string;
}

export default function Login() {
  const { euiTheme } = useEuiTheme();
  const auth = useAuthContext();

  const [form, setForm] = useState<LoginFormData>({
    username: "",
    password: "",
  });
  const [validation, setValidation] = useState({
    username: true,
    password: true,
  });

  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const checkValidation = () => {
    if (size(form.username) < 5) {
      setValidation({ ...validation, username: false });
      return false;
    }

    if (size(form.password) < 6) {
      setValidation({ username: true, password: false });
      return false;
    }

    setValidation({ username: true, password: true });
    return true;
  };

  const handleLogin = () => {
    if (!checkValidation()) {
      return;
    } else {
      auth.login(form);
    }
  };

  const onKeyDown = (e: any) => e.key === "Enter" && handleLogin();

  // @important no change
  // if (auth.isAuthenticated && localStorage.getItem(IS_AUTHENTICATED)) {
  if (localStorage.getItem(IS_AUTHENTICATED)) {
    return <Navigate to={"/"} />;
  }

  return (
    <EuiFlexGroup
      justifyContent="center"
      alignItems="center"
      style={{
        width: "100%",
        height: "100%",
        background: euiTheme.colors.body,
      }}
    >
      <EuiFlexGroup
        style={{
          maxWidth: `calc(${euiTheme.size.base} * 45)`,
          boxShadow: `0 4px 20px 0 rgb(0 0 0 / 15%)`,
        }}
      >
        <EuiFlexItem grow={5}>
          <img src={Images.logoBrand} alt="logo" />
        </EuiFlexItem>
        <EuiFlexItem
          grow={7}
          style={{
            padding: `calc(${euiTheme.size.base} * 1)`,
          }}
        >
          <EuiTitle size="s">
            <EuiTextColor>Đăng nhập </EuiTextColor>
          </EuiTitle>
          <EuiText size="s">Hệ thống ERP Mỹ Thuật Bụi</EuiText>
          <EuiSpacer size="l" />
          <EuiForm component="form" onKeyDown={onKeyDown}>
            <EuiFormRow
              isInvalid={!validation.username}
              error={"Tên đăng nhập ít nhất 5 kí tự"}
            >
              <EuiFieldText
                style={{ width: 500 }}
                prepend={
                  <EuiIcon type={"user"} style={{ background: "#fbfcfd" }} />
                }
                onChange={handleChange}
                name="username"
                placeholder="Tên đăng nhập"
                isInvalid={!validation.username}
              />
            </EuiFormRow>
            <EuiSpacer size="m" />
            <EuiFormRow
              isInvalid={!validation.password}
              error={"Mật khẩu ít nhất 6 kí tự"}
            >
              <EuiFieldPassword
                onChange={handleChange}
                name="password"
                type={"dual"}
                placeholder="Mật khẩu"
                isInvalid={!validation.password}
              />
            </EuiFormRow>
            <EuiButton
              fullWidth
              fill
              color="primary"
              style={{ textDecoration: "none" }}
              onClick={handleLogin}
            >
              Đăng nhập
            </EuiButton>
            <EuiFlexItem style={{ marginTop: 10 }}>
              <EuiLink href={Paths.PUBLIC.FORGOT_PASSWORD}>
                Quên mật khẩu
              </EuiLink>
            </EuiFlexItem>
          </EuiForm>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
}
