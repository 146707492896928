import {
  ApiNotFound,
  BadRequest,
  NetworkError,
  Unauthorized,
  RequestError,
} from "../../exceptions";
import { ActionTypes, ErrorCodes } from "../../constants";
import store from "../../redux/store";

const { BAD_REQUEST, UNAUTHORIZED, API_NOT_FOUND } = ErrorCodes;

interface ResProps {
  message?: string;
  data?: any;
  status?: number;
}

export const processResponseError = (res: ResProps, apiName: string = "") => {
  // handle network error
  if (res?.message === "Network Error") {
    throw new NetworkError(res.message, apiName, null);
  }

  // handle error
  if (res && res.status) {
    const { data, status, message = "" } = res;
    switch (status) {
      case BAD_REQUEST: {
        throw new BadRequest(message, apiName, data);
      }
      case UNAUTHORIZED: {
        store.dispatch({ type: ActionTypes.SESSION_EXPIRED, payload: null });
        store.dispatch({
          type: ActionTypes.NOTIFICATION,
          payload: {
            title: "Phiên làm việc hết hạn",
            message: "Vui lòng đăng nhập lại để làm việc",
            type: "danger",
          },
        });
        throw new Unauthorized(message, apiName, data);
      }
      case API_NOT_FOUND: {
        throw new ApiNotFound(message, apiName, data);
      }
      default: {
        throw new RequestError(message, apiName, data);
      }
    }
  }

  return res;
};
