/** @jsxImportSource @emotion/react */

import {
  EuiButton,
  EuiFlexGrid,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
} from "@elastic/eui";
import { useToggle } from "ahooks";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { Flyout } from "../filter/flyout";
import { usePresets } from "../filter/hooks";
import { SavedFilters } from "../filter/SavedFilters";
import { SaveFilterModal } from "../filter/SaveFilterModal";
import CustomizeForm from "./form";

export type FilterInputType = "input" | "select" | "selectSearch";

export interface Option {
  value: string | number | boolean;
  text: string;
}
export interface FilterInput {
  name: string;
  label: string;
  type: FilterInputType;
  visible?: boolean;
  options?: Option[];
}
interface Props {
  list: FilterInput[];
  onFilter: (params: any) => void;
  page: string;
}

export const getDefaultValues = (list: FilterInput[]) =>
  list.reduce((result: any, quote: any) => {
    if (quote.type === "input") {
      result[quote.name] = "";
    }
    if (quote.type === "select") {
      result[quote.name] = quote.options[0]?.value;
    }
    return result;
  }, {});

export default function CustomizeFilter(props: Props) {
  const [isFlyoutVisible, actions] = useToggle(false);
  const [isSaveFilterModalVisible, modalActions] = useToggle(false);
  const { presets, savePreset, deletePreset } = usePresets(props.page);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm<any>({
    defaultValues: getDefaultValues(props.list),
  });

  // load data from query
  const getInitDataFromQuery = () => {
    try {
      for (let i = 0; i < props.list.length; i++) {
        const { name } = props.list[i];
        if (searchParams.get(name)) {
          setValue(name, searchParams.get(name));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // component did mount
  useEffect(() => {
    getInitDataFromQuery();
  }, []);
  const onReset = () => {
    reset(getDefaultValues(props.list));
    handleSubmit(props.onFilter)();
  };

  const onSave = (label: string) => {
    savePreset(props.page, label, getValues());
    modalActions.toggle();
  };

  const onSubmitSavedFilter = (data: Record<string, any>) => {
    reset(data);
    handleSubmit(onFilterChange)();
  };

  const onFilterChange = (data: any) => {
    let filterData: any = {};
    for (let key in data) {
      if (data[key]) {
        filterData[key] = data[key];
      } else {
        filterData[key] = "";
      }
    }
    if (props.onFilter) {
      props.onFilter(filterData);
    }
  };

  const hasMoreFilters = props.list.filter((e) => !e.visible).length > 0;

  return (
    <EuiPanel hasBorder paddingSize="s">
      <form style={{ padding: 10 }} onSubmit={handleSubmit(onFilterChange)}>
        <EuiFlexGrid columns={0}>
          {props.list
            .filter((e) => e.visible)
            .map((item: any, ix) => (
              <EuiFlexItem key={ix}>
                <CustomizeForm
                  name={item.name}
                  control={control}
                  label={item.label}
                  errors={errors}
                  type={item.type}
                  options={item?.options}
                />
              </EuiFlexItem>
            ))}
          {hasMoreFilters && (
            <EuiFlexItem>
              <EuiFormRow fullWidth label="&nbsp; &nbsp; &nbsp; &nbsp;">
                <EuiButton
                  fill
                  iconType="menuLeft"
                  color="text"
                  onClick={() => actions.set(true)}
                >
                  Bộ lọc khác
                </EuiButton>
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>
        <EuiFlexGrid style={{ justifyContent: "flex-end" }}>
          <EuiFlexItem>
            <EuiFormRow fullWidth>
              <SavedFilters
                onSave={modalActions.toggle}
                onDelete={deletePreset}
                savedFilters={presets ?? []}
                onFilter={onSubmitSavedFilter}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow fullWidth>
              <EuiButton onClick={onReset}>Reset</EuiButton>
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiFormRow fullWidth>
              <EuiButton
                fill
                color="primary"
                type="submit"
                iconType="magnifyWithPlus"
              >
                Tìm kiếm
              </EuiButton>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGrid>
        {hasMoreFilters && (
          <Flyout
            isVisible={isFlyoutVisible}
            onToggle={actions.toggle}
            onSubmit={handleSubmit(onFilterChange)}
            onReset={onReset}
          >
            <EuiFlexGrid columns={1}>
              {props.list
                .filter((e) => !e.visible)
                .map((item: any, index: number) => (
                  <EuiFlexItem key={index}>
                    <CustomizeForm
                      name={item.name}
                      control={control}
                      label={item.label}
                      errors={errors}
                      type={item.type}
                      options={item?.options}
                    />
                  </EuiFlexItem>
                ))}
            </EuiFlexGrid>
          </Flyout>
        )}
      </form>

      <SaveFilterModal
        isVisible={isSaveFilterModalVisible}
        onToggle={modalActions.toggle}
        onSubmit={onSave}
      />
    </EuiPanel>
  );
}
