import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  useGeneratedHtmlId,
} from "@elastic/eui";
import React, { FC, useState } from "react";

interface Props {
  isVisible: boolean;
  onToggle: () => void;
  onSubmit: (label: string) => void;
}

export const SaveFilterModal: FC<Props> = (props) => {
  const modalFormId = useGeneratedHtmlId({ prefix: "modalForm" });
  const [label, setLabel] = useState("");

  const formSample = (
    <EuiForm id={modalFormId} component="form">
      <EuiFormRow label="Tên gợi nhớ">
        <EuiFieldText name="label" onChange={(e) => setLabel(e.target.value)} />
      </EuiFormRow>
    </EuiForm>
  );

  let modal;

  if (props.isVisible) {
    modal = (
      <EuiModal onClose={props.onToggle} initialFocus="[name=popswitch]">
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Lưu tìm kiếm hiện tại</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>{formSample}</EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={props.onToggle}>Bỏ qua</EuiButtonEmpty>

          <EuiButton
            form={modalFormId}
            onClick={() => {
              props.onSubmit(label);
            }}
            fill
          >
            Lưu
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }
  return <div>{modal}</div>;
};
