import {
  ARCHIVE_MANY_DEPARTMENT,
  CREATE_DEPARTMENT,
  DELETE_MANY_DEPARTMENT,
  DELETE_ONE_DEPARTMENT,
  GET_DETAIL_DEPARTMENT,
  GET_LIST_DEPARTMENT,
  SET_LOADING_DEPARTMENT,
  UNARCHIVE_MANY_DEPARTMENT,
  UPDATE_DETAIL_DEPARTMENT,
} from "../../constants";
import {
  IArchiveManyPayloadDepartment,
  IDeleteManyPayloadDepartment,
  IDeleteOnePayloadDepartment,
  ILoadingDepartment,
  IUnarchiveManyPayloadDepartment,
} from "../types";

export const getListDepartment = (payload: any) => {
  return {
    type: GET_LIST_DEPARTMENT,
    payload,
  };
};

export const getDetailDepartment = (payload: any) => {
  return {
    type: GET_DETAIL_DEPARTMENT,
    payload,
  };
};

export const updateDetailDepartment = (payload: any) => {
  return {
    type: UPDATE_DETAIL_DEPARTMENT,
    payload,
  };
};

export const createDepartment = (payload: any) => {
  return {
    type: CREATE_DEPARTMENT,
    payload,
  };
};

export const deleteOneDepartment = (payload: IDeleteOnePayloadDepartment) => {
  return {
    type: DELETE_ONE_DEPARTMENT,
    payload,
  };
};

export const deleteManyDepartment = (payload: IDeleteManyPayloadDepartment) => {
  return {
    type: DELETE_MANY_DEPARTMENT,
    payload,
  };
};

export const archiveManyDepartment = (
  payload: IArchiveManyPayloadDepartment
) => {
  return {
    type: ARCHIVE_MANY_DEPARTMENT,
    payload,
  };
};

export const unarchiveManyDepartment = (
  payload: IUnarchiveManyPayloadDepartment
) => {
  return {
    type: UNARCHIVE_MANY_DEPARTMENT,
    payload,
  };
};

export const setLoadingDepartment = (payload: ILoadingDepartment) => {
  return {
    type: SET_LOADING_DEPARTMENT,
    payload,
  };
};
