/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getDistricts, getProvinces, getWards } from "./services";
import { MasterDataPDW } from "./typing";
import _ from 'lodash';
import { SelectSearchForm } from "../common/form/SelectSearchForm";
import { EuiFlexGroup, EuiFlexItem, EuiLoadingContent } from "@elastic/eui";
import { InputForm } from "../common/form/InputForm";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";


interface IPDWProps {
  type: "READ" | "CREATE" | "UPDATE",
  values?: any,
  isDisable?: boolean,
  control: any,
  errors: any,
  setValue: UseFormSetValue<any>,
  getValues: UseFormGetValues<any>
}

const PDW: React.FC<IPDWProps> = (props) => {
  const { type, values, control, errors, isDisable, setValue, getValues } = props;
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [location, setLocation] = useState<MasterDataPDW>({
    ward: [],
    district: [],
    province: [],
  });

  const [selectedProvince, setSelectedProvince] = useState<any[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<any[]>([]);
  const [selectedWard, setSelectedWard] = useState<any[]>([]);

  useEffect(() => {
    const address = getValues('address');
    setValue('provinceId', selectedProvince[0]?.id);
    setValue('fullAddress', `${address}, ${selectedProvince[0]?.label}`);
  }, [
    selectedProvince,
  ])
  useEffect(() => {
    const address = getValues('address');
    setValue('districtId', selectedDistrict[0]?.id);
    setValue('fullAddress', `${address}, ${selectedDistrict[0]?.label}, ${selectedProvince[0]?.label} `);

  }, [
    selectedDistrict,
  ])
  useEffect(() => {
    const address = getValues('address');
    setValue('wardId', selectedWard[0]?.id);
    setValue('fullAddress', `${address}, ${selectedWard[0]?.label}, ${selectedDistrict[0]?.label}, ${selectedProvince[0]?.label}`);
  }, [
    selectedWard,
  ])

  useEffect(() => {
    (async function fetchMasterData() {
      setLoaded(false);
      try {
        const getP = await getProvinces({ limit: 100 });
        let newLocation;
        if (type === 'UPDATE' || type === "READ") {
          const [getD, getW] = await Promise.all([
            getDistricts({ provinceId: values.provinceId }),
            getWards({
              provinceId: values.provinceId,
              districtId: values.districtId,
            })
          ])
          newLocation = _.cloneDeep({
            ...location,
            province: getP.data.map((p: any) => {
              return {
                id: p.id,
                label: p.name,
              }
            }),
            district: getD.data.map((d: any) => {
              return {
                id: d.id,
                label: d.name,
              }
            }),
            ward: getW.data.map((w: any) => {
              return {
                id: w.id,
                label: w.name,
              }
            }),
          });
          setSelectedProvince([{ id: values.provinceId, label: getP.data.find((p: any) => p.id.toString() === values.provinceId)?.name }])
          setSelectedDistrict([{ id: values.districtId, label: getD.data.find((d: any) => d.id.toString() === values.districtId)?.name }])
          setSelectedWard([{ id: values.wardId, label: getW.data.find((w: any) => w.id.toString() === values.wardId)?.name }])
        } else {
          newLocation = _.cloneDeep({
            ...location, province: getP.data.map((p: any) => {
              return {
                id: p.id,
                label: p.name,
              }
            })
          });
        }
        setLocation(newLocation);
      }
      catch (e) {
        return;
      }
      finally {
        setLoaded(true)
      }
    })();
  }, []);

  const onSelectProvince = async (provinceId: any) => {
    if (!provinceId) {
      setSelectedDistrict([]);
      setSelectedWard([]);
      setLocation(_.cloneDeep({
        ...location, district: [], ward: []
      }));
      return;
    }
    const getD = await getDistricts({ provinceId, limit: 100 });
    const newLocation = _.cloneDeep({
      ...location, district: getD.data.map((d: any) => {
        return {
          id: d.id,
          label: d.name,
        }
      }), ward: []
    });
    setSelectedDistrict([]);
    setSelectedWard([]);
    setLocation(newLocation);
  };

  const onSelectDistrict = async (districtId: any) => {
    if (!districtId) {
      setSelectedWard([]);
      setLocation(_.cloneDeep({
        ...location, ward: []
      }));
      return;
    }
    const getW = await getWards({ districtId, limit: 100 });
    const newLocation = _.cloneDeep({
      ...location, ward: getW.data.map((w: any) => {
        return {
          id: w.id,
          label: w.name,
        }
      })
    });
    setSelectedWard([]);
    setLocation(newLocation);
  };

  if (!isLoaded) return <EuiLoadingContent />

  return <EuiFlexGroup style={{ maxWidth: "100%" }}>

    {/* ADDRESS */}
    <EuiFlexItem>
      <InputForm
        name="address"
        label="Địa chỉ"
        control={control}
        errors={errors}
        isDisable={isDisable || false}
      />
    </EuiFlexItem>

    {/* PROVINCE */}
    <EuiFlexItem>
      <SelectSearchForm
        customSelected={selectedProvince}
        setCustomSelected={setSelectedProvince}
        name="provinceId"
        label="Tỉnh/ thành"
        control={control}
        errors={errors}
        isDisable={isDisable || false}
        options={location.province || []}
        placeholder="Tỉnh/ Thành"
        onChangeValue={onSelectProvince}
      />
    </EuiFlexItem>

    {/* DISTRICT */}
    <EuiFlexItem>
      <SelectSearchForm
        customSelected={selectedDistrict}
        setCustomSelected={setSelectedDistrict}
        name="districtId"
        label="Quận/ Huyện"
        control={control}
        errors={errors}
        isDisable={isDisable || false}
        options={location.district || []}
        placeholder="Quận/ Huyện"
        onChangeValue={onSelectDistrict}
      />
    </EuiFlexItem>

    {/* WARD */}
    <EuiFlexItem>
      <SelectSearchForm
        customSelected={selectedWard}
        setCustomSelected={setSelectedWard}
        name="wardId"
        label="Phường/ Xã"
        control={control}
        errors={errors}
        isDisable={isDisable || false}
        options={location.ward || []}
        placeholder="Phường/ Xã"
      />
    </EuiFlexItem>

  </EuiFlexGroup>
}
export default PDW
