import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import { Paths } from "../../../constants";
import {
  getDepartmentsApi,
  getEmployeesApi,
  getJobsApi,
  getLocationsApi,
  getUsersApi,
} from "../../../network";
import { getDetailEmployee, updateDetailEmployee } from "../../../redux";
import { validation } from "../../../utils";

interface IFormInput {
  name: string;
  avatar?: string;
  mobilePhone: string;
  facebook: string;
  workEmail: string;
  workAddress: string;
  startWorkingDate: any;
  departmentId: string | null;
  userId?: string | null;
  jobId?: string | null;
  managerId?: string | null;
  type: string;
  locationIds: string[];
  no2LocationIds: string[];
  no3LocationIds: string[];
}

interface IDepartment {
  _id: string;
  name: string;
}

interface IJob {
  _id: string;
  name: string;
}

const Edit: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id }: any = useParams();

  const [jobs, setJobs] = useState<IJob[]>([]);
  const [jobsSelected, setJobsSelected] = useState<any>([]);
  const [users, setUsers] = useState<IJob[]>([]);
  const [usersSelected, setUsersSelected] = useState<any>([]);
  const [managers, setManagers] = useState<IJob[]>([]);
  const [managersSelected, setManagersSelected] = useState<any>([]);
  const [locations, setLocations] = useState<IJob[]>([]);
  const [locationsSelected, setLocationsSelected] = useState<any>([]);
  const [no2Locations, setNo2Locations] = useState<IJob[]>([]);
  const [no2LocationsSelected, setNo2LocationsSelected] = useState<any>([]);
  const [no3Locations, setNo3Locations] = useState<IJob[]>([]);
  const [no3LocationsSelected, setNo3LocationsSelected] = useState<any>([]);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [departmentsSelected, setDepartmentsSelected] = useState<any>([]);

  const employeeState = useSelector((state: RootStateOrAny) => state.employee);

  const employeeDetail = useSelector(
    (state: RootStateOrAny) => state.employee.detail.data
  );

  const getListDepartment = async (searchValue?: string) => {
    const response = await getDepartmentsApi({ name: searchValue });
    if (response && response.data) {
      setDepartments(response.data);
    }
  };

  const getListUser = async (searchValue?: string) => {
    const response = await getUsersApi({ name: searchValue });
    if (response && response.data) {
      setUsers(response.data);
    }
  };

  const getListJob = async (searchValue?: string) => {
    const response = await getJobsApi({ name: searchValue });
    if (response && response.data) {
      setJobs(response.data);
    }
  };

  const getListManager = async (searchValue?: string) => {
    const response = await getEmployeesApi({ name: searchValue });
    if (response && response.data) {
      setManagers(response.data);
    }
  };

  const getListLocation = async (
    searchValue?: string,
    isInitRequest: boolean = false
  ) => {
    const response = await getLocationsApi({ name: searchValue });
    if (response && response.data) {
      setLocations(response.data);

      if (isInitRequest) {
        setNo2Locations(response.data);
        setNo3Locations(response.data);
      }
    }
  };

  const getListNo2Location = async (searchValue?: string) => {
    const response = await getLocationsApi({ name: searchValue });
    if (response && response.data) {
      setNo2Locations(response.data);
    }
  };

  const getListNo3Location = async (searchValue?: string) => {
    const response = await getLocationsApi({ name: searchValue });
    if (response && response.data) {
      setNo3Locations(response.data);
    }
  };

  useEffect(() => {
    getListDepartment();
    getListUser();
    getListJob();
    getListManager();
    getListLocation(undefined, true);
  }, []);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      name: employeeDetail.name || "",
      avatar: employeeDetail.avatar || "",
      mobilePhone: employeeDetail.mobilePhone || "",
      facebook: employeeDetail.facebook || "",
      workEmail: employeeDetail.workEmail || "",
      workAddress: employeeDetail.workAddress || "",
      startWorkingDate: moment(employeeDetail.startWorkingDate),
      departmentId: employeeDetail.departmentId || "",
      userId: employeeDetail.userId || "",
      jobId: employeeDetail.jobId || "",
      managerId: employeeDetail.managerId || "",
      type: employeeDetail.type || "STAFF",
      locationIds: employeeDetail.locationIds || [],
      no2LocationIds: employeeDetail.no2LocationIds || [],
      no3LocationIds: employeeDetail.nameno3LocationIds || [],
    },
    resolver: validation.employee.edit(),
  });

  useEffect(() => {
    reset({});
    dispatch(getDetailEmployee(id));
  }, []);

  useEffect(() => {
    if (!isEmpty(employeeDetail) && employeeDetail._id === id) {
      reset({
        name: employeeDetail.name || "",
        avatar: employeeDetail.avatar || "",
        mobilePhone: employeeDetail.mobilePhone || "",
        facebook: employeeDetail.facebook || "",
        workEmail: employeeDetail.workEmail || "",
        workAddress: employeeDetail.workAddress || "",
        startWorkingDate: employeeDetail?.startWorkingDate
          ? moment(employeeDetail.startWorkingDate)
          : moment(),
        departmentId: employeeDetail.departmentId || "",
        userId: employeeDetail.userId || "",
        jobId: employeeDetail.jobId || "",
        managerId: employeeDetail.managerId || "",
        type: employeeDetail.type || "STAFF",
        locationIds: employeeDetail.locationIds || [],
        no2LocationIds: employeeDetail.no2LocationIds || [],
        no3LocationIds: employeeDetail.nameno3LocationIds || [],
      });

      // set department selected
      const departmentData = employeeDetail?.departmentId
        ? [
            {
              _id: employeeDetail?.departmentId,
              value: employeeDetail?.departmentId,
              label: employeeDetail?.department?.name,
            },
          ]
        : [];
      setDepartmentsSelected(departmentData);

      // set job selected
      const jobData = employeeDetail?.jobId
        ? [
            {
              _id: employeeDetail?.jobId,
              value: employeeDetail?.jobId,
              label: employeeDetail?.job?.name,
            },
          ]
        : [];
      setJobsSelected(jobData);

      // set user selected
      const userData = employeeDetail?.userId
        ? [
            {
              _id: employeeDetail?.userId,
              value: employeeDetail?.userId,
              label: employeeDetail?.user?.name,
            },
          ]
        : [];
      setUsersSelected(userData);

      // set manager selected
      const managerData = employeeDetail?.managerId
        ? [
            {
              _id: employeeDetail?.managerId,
              value: employeeDetail?.managerId,
              label: employeeDetail?.manager?.name,
            },
          ]
        : [];
      setManagersSelected(managerData);

      // set location selected
      let locationData: any = [];
      if (employeeDetail?.locations?.length > 0) {
        locationData = employeeDetail?.locations.map((item: any) => {
          return {
            _id: item._id,
            value: item._id,
            label: item.name,
          };
        });
      }
      setLocationsSelected(locationData);

      // set no 2 location selected
      let no2LocationData: any = [];
      if (employeeDetail?.locations?.length > 0) {
        no2LocationData = employeeDetail?.no2Locations.map((item: any) => {
          return {
            _id: item._id,
            value: item._id,
            label: item.name,
          };
        });
      }
      setNo2LocationsSelected(no2LocationData);

      // set no 2 location selected
      let no3LocationData: any = [];
      if (employeeDetail?.locations?.length > 0) {
        no3LocationData = employeeDetail?.no3Locations.map((item: any) => {
          return {
            _id: item._id,
            value: item._id,
            label: item.name,
          };
        });
      }
      setNo3LocationsSelected(no3LocationData);
    }
  }, [employeeDetail]);

  const onSubmit = (data: any) => {
    const employeeData = {
      ...data,
      departmentId: data.departmentId || null,
      userId: data.userId || null,
      jobId: data.jobId || null,
      managerId: data.managerId || null,
      startWorkingDate: data.startWorkingDate.toISOString(),
    };
    console.log({ data, employeeData });
    dispatch(updateDetailEmployee({ id, data: employeeData }));
  };

  const formInput = [
    { name: "name", label: "Tên", type: "text", required: true },
    {
      name: "mobilePhone",
      label: "Số điện thoại",
      type: "text",
      required: true,
    },
    { name: "facebook", label: "Facebook link", type: "text" },
    {
      name: "workEmail",
      label: "Email công việc",
      type: "text",
      inputType: "email",
    },
    { name: "workAddress", label: "Địa chỉ làm việc", type: "text" },
    {
      name: "startWorkingDate",
      label: "Thời gian bắt đầu làm việc",
      type: "date",
    },
    {
      name: "departmentId",
      label: "Phòng ban",
      type: "selectSearch",
      fullWidth: true,
      options: [{ _id: "", name: "Chọn" }, ...departments].map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: departmentsSelected,
      // onSearchChange: getListDepartment,
      onChangeValue: (value: string) => {
        setDepartmentsSelected(null);
        setValue("departmentId", value);
      },
    },
    {
      name: "userId",
      label: "Liên kết với người dùng",
      type: "selectSearch",
      fullWidth: true,
      options: users.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: usersSelected,
      // onSearchChange: getListUser,
      onChangeValue: (value: string) => {
        setUsersSelected(null);
        setValue("userId", value);
      },
    },
    {
      name: "jobId",
      label: "Công việc",
      type: "selectSearch",
      fullWidth: true,
      options: [{ _id: "", name: "Chọn" }, ...jobs].map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: jobsSelected,
      // onSearchChange: getListJob,
      onChangeValue: (value: string) => {
        setJobsSelected(null);
        setValue("jobId", value);
      },
    },
    {
      name: "managerId",
      label: "Người quản lý",
      type: "selectSearch",
      fullWidth: true,
      options: [{ _id: "", name: "Chọn" }, ...managers].map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: managersSelected,
      // onSearchChange: getListManager,
      onChangeValue: (value: string) => {
        setManagersSelected(null);
        setValue("managerId", value);
      },
    },
    {
      name: "locationIds",
      label: "Địa điểm làm việc mong muốn",
      type: "selectSearch",
      fullWidth: true,
      mode: "multiple",
      options: locations.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: locationsSelected,
      onSearchChange: getListLocation,
      onChangeValue: (value: string[]) => {
        setLocationsSelected(null);
        setValue("locationIds", value);
      },
    },
    {
      name: "no2LocationIds",
      label: "Địa điểm làm việc mong muốn: ưu tiên thứ 2",
      type: "selectSearch",
      fullWidth: true,
      mode: "multiple",
      options: no2Locations.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: no2LocationsSelected,
      // onSearchChange: getListNo2Location,
      onChangeValue: (value: string[]) => {
        setNo2LocationsSelected(null);
        setValue("no2LocationIds", value);
      },
    },
    {
      name: "no3LocationIds",
      label: "Địa điểm làm việc mong muốn: ưu tiên thứ 3",
      type: "selectSearch",
      fullWidth: true,
      mode: "multiple",
      options: no3Locations.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: no3LocationsSelected,
      // onSearchChange: getListNo3Location,
      onChangeValue: (value: string[]) => {
        setNo3LocationsSelected(null);
        setValue("no3LocationIds", value);
      },
    },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Cập nhật"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.EMPLOYEE.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formInput.map((item: any, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                isDisable={item?.isDisable || false}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton
              isLoading={employeeState?.isUpdateRequest || false}
              type="submit"
              fill
            >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Edit;
