export const ACCESS_TOKEN = "access-token";
export const IS_AUTHENTICATED = "is-authenticated";
export const USER_INFO = "user-info";
export const PAGINATION_DEFAULT = { limit: 20, offset: 0 };
export const FORMAT_MOMENT = {
  DDMMYYY: "DD-MM-YYYY HH:MM:SS",
};

export const PHONE_REGEX = /((^(\+84|84|0|0084){1})(3|5|7|8|9))+([0-9]{8})$/;
export const NORMAL_CHARACTER_REGEX = /^([a-zA-Z0-9 _ \@.]+)$/;
export const PERMISSIONS = {
  // user
  USER_GET_MANY: "user.get_many",
  USER_GET_ONE: "user.get_one",
  USER_CREATE: "user.create",
  USER_UPDATE: "user.update",
  USER_DELETE: "user.delete",

  // room
  ROOM_GET_MANY: "room.get_many",
  ROOM_GET_ONE: "room.get_one",
  ROOM_CREATE: "room.create",
  ROOM_UPDATE: "room.update",
  ROOM_DELETE: "room.delete",

  // subject
  SUBJECT_GET_MANY: "subject.get_many",
  SUBJECT_GET_ONE: "subject.get_one",
  SUBJECT_CREATE: "subject.create",
  SUBJECT_UPDATE: "subject.update",
  SUBJECT_DELETE: "subject.delete",

  // location
  LOCATION_GET_MANY: "location.get_many",
  LOCATION_GET_ONE: "location.get_one",
  LOCATION_CREATE: "location.create",
  LOCATION_UPDATE: "location.update",
  LOCATION_DELETE: "location.delete",

  // role
  ROLE_GET_MANY: "role.get_many",
  ROLE_GET_ONE: "role.get_one",
  ROLE_CREATE: "role.create",
  ROLE_UPDATE: "role.update",
  ROLE_DELETE: "role.delete",

  // role
  PERMISSION_GET_MANY: "permission.get_many",
  PERMISSION_GET_ONE: "permission.get_one",
  PERMISSION_CREATE: "permission.create",
  PERMISSION_UPDATE: "permission.update",
  PERMISSION_DELETE: "permission.delete",

  // department
  DEPARTMENT_GET_MANY: "department.get_many",
  DEPARTMENT_GET_ONE: "department.get_one",
  DEPARTMENT_CREATE: "department.create",
  DEPARTMENT_UPDATE: "department.update",
  DEPARTMENT_DELETE: "department.delete",

  // school hour
  SCHOOL_HOUR_GET_MANY: "school_hour.get_many",
  SCHOOL_HOUR_GET_ONE: "school_hour.get_one",
  SCHOOL_HOUR_CREATE: "school_hour.create",
  SCHOOL_HOUR_UPDATE: "school_hour.update",
  SCHOOL_HOUR_DELETE: "school_hour.delete",

  // employee
  EMPLOYEE_GET_MANY: "employee.get_many",
  EMPLOYEE_GET_ONE: "employee.get_one",
  EMPLOYEE_CREATE: "employee.create",
  EMPLOYEE_UPDATE: "employee.update",
  EMPLOYEE_DELETE: "employee.delete",

  // teacher role
  TEACHER_ROLE_GET_MANY: "teacher_role.get_many",
  TEACHER_ROLE_GET_ONE: "teacher_role.get_one",
  TEACHER_ROLE_CREATE: "teacher_role.create",
  TEACHER_ROLE_UPDATE: "teacher_role.update",
  TEACHER_ROLE_DELETE: "teacher_role.delete",

  // class number
  CLASS_NUMBER_GET_MANY: "classNumber.get_many",
  CLASS_NUMBER_GET_ONE: "classNumber.get_one",
  CLASS_NUMBER_CREATE: "classNumber.create",
  CLASS_NUMBER_UPDATE: "classNumber.update",
  CLASS_NUMBER_DELETE: "classNumber.delete",

  // role
  TEACHER_GET_MANY: "teacher.get_many",
  TEACHER_GET_ONE: "teacher.get_one",
  TEACHER_CREATE: "teacher.create",
  TEACHER_UPDATE: "teacher.update",
  TEACHER_DELETE: "teacher.delete",
};
