import {
  AUTH_SET_LOADING,
  AUTH_FORGOT_PASSWORD_UPDATE,
  SESSION_EXPIRED_SUCCESS,
} from "../../constants";
import { IAuthState } from "../types";

interface ActionProps {
  type: string;
  payload: any;
}

const initState: any = {
  isLoadingRequest: false,
  forgotPassword: null,
  session: {
    isExpire: false,
    isCheck: false,
  },
};

const auth = (state: IAuthState = initState, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_FORGOT_PASSWORD_UPDATE: {
      return {
        ...state,
        forgotPassword: action.payload.data,
      };
    }

    case AUTH_SET_LOADING: {
      return {
        ...state,
        ...payload,
      };
    }

    case SESSION_EXPIRED_SUCCESS: {
      return {
        ...state,
        session: {
          isExpire: true,
          isCheck: !state.session.isCheck,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default auth;
