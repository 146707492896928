import {
  EuiCollapsibleNav,
  EuiCollapsibleNavGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiHorizontalRule,
  EuiIcon,
  EuiPinnableListGroup,
  EuiPinnableListGroupItemProps,
  useGeneratedHtmlId,
} from "@elastic/eui";
import { find, findIndex } from "lodash";
import * as React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Paths, PERMISSIONS } from "../../constants";
import { checkPermission } from "../common";

// import { AiOutlineUser } from "react-icons/ai";
interface Props {
  navIsOpen: boolean;
  setNavIsOpen(isOpen: boolean): void;
}

interface IProfile {
  isRootAdmin: boolean;
  permissions: any;
}

export const ConfigurationLinks = (profile: IProfile) => {
  const { permissions, isRootAdmin } = profile || {};

  let links: any = [];
  const allLink = [
    {
      path: Paths.ROLE.LIST,
      label: "Vai trò",
      permissionKey: PERMISSIONS.ROLE_GET_MANY,
    },
    {
      path: Paths.PERMISSION.LIST,
      label: "Quyền hạn",
      permissionKey: PERMISSIONS.PERMISSION_GET_MANY,
    },
  ];

  for (let i = 0; i < allLink.length; i++) {
    const { permissionKey, path, label } = allLink[i];
    const isAccess = profile?.isRootAdmin
      ? true
      : checkPermission(permissionKey, permissions, isRootAdmin);
    if (isAccess) {
      links.push({
        label: <Link to={path}> {label}</Link>,
        onClick: () => {},
      });
    }
  }

  return links;
};

export const generalLinks = (profile: IProfile) => {
  const { permissions, isRootAdmin } = profile || {};

  let links: any = [];
  const allLink = [
    {
      path: Paths.USER.LIST,
      label: "Người dùng",
      permissionKey: PERMISSIONS.USER_GET_MANY,
    },
    {
      path: Paths.ROOM.LIST,
      label: "Lớp học",
      permissionKey: PERMISSIONS.ROOM_GET_MANY,
    },
    {
      path: Paths.SUBJECT.LIST,
      label: "Môn học",
      permissionKey: PERMISSIONS.SUBJECT_GET_MANY,
    },
    {
      path: Paths.LOCATION.LIST,
      label: "Cơ sở giảng dạy",
      permissionKey: PERMISSIONS.LOCATION_GET_MANY,
    },
    {
      path: Paths.DEPARTMENT.LIST,
      label: "Phòng ban",
      permissionKey: PERMISSIONS.DEPARTMENT_GET_MANY,
    },
    {
      path: Paths.SCHOOL_HOUR.LIST,
      label: "Khung giờ học",
      permissionKey: PERMISSIONS.SCHOOL_HOUR_GET_MANY,
    },
    {
      path: Paths.EMPLOYEE.LIST,
      label: "Nhân viên",
      permissionKey: PERMISSIONS.EMPLOYEE_GET_MANY,
    },
    {
      path: Paths.TEACHER_ROLE.LIST,
      label: "Phân môn giảng dạy",
      permissionKey: PERMISSIONS.TEACHER_ROLE_GET_MANY,
    },
    {
      path: Paths.CLASS_NUMBER.LIST,
      label: "Sĩ số tối đa",
      permissionKey: PERMISSIONS.CLASS_NUMBER_GET_MANY,
    },
    {
      path: Paths.TEACHER.LIST,
      label: "Giáo viên",
      permissionKey: PERMISSIONS.TEACHER_GET_MANY,
    },
  ];

  for (let i = 0; i < allLink.length; i++) {
    const { permissionKey, path, label } = allLink[i];
    const isAccess = profile?.isRootAdmin
      ? true
      : checkPermission(permissionKey, permissions, isRootAdmin);
    if (isAccess) {
      links.push({
        label: <Link to={path}> {label}</Link>,
        onClick: () => {},
      });
    }
  }

  return links;
};

const TopLinks: EuiPinnableListGroupItemProps[] = [
  {
    label: <Link to={"/"}>Dashboard</Link>,
    iconType: "home",
    isActive: true,
    "aria-current": true,
    pinnable: false,
  },
];

const SideNav: React.FC<Props> = (props) => {
  // const exitPath = useExitPath();
  const [navIsOpen, setNavIsOpen] = React.useState(true);
  const profile = useSelector((state: RootStateOrAny) => state.profile.data);
  /**
   * Accordion toggling
   */
  const [openGroups, setOpenGroups] = React.useState(
    JSON.parse(String(localStorage.getItem("openNavGroups"))) || [
      "general",
      "configuration",
    ]
  );

  // Save which groups are open and which are not with state and local store
  const toggleAccordion = (isOpen: boolean, title?: string) => {
    if (!title) return;
    const itExists = openGroups.includes(title);
    if (isOpen) {
      if (itExists) return;
      openGroups.push(title);
    } else {
      const index = openGroups.indexOf(title);
      if (index > -1) {
        openGroups.splice(index, 1);
      }
    }
    setOpenGroups([...openGroups]);
    localStorage.setItem("openNavGroups", JSON.stringify(openGroups));
  };

  /**
   * Pinning
   */
  const [pinnedItems, setPinnedItems] = React.useState<
    EuiPinnableListGroupItemProps[]
  >(JSON.parse(String(localStorage.getItem("pinnedItems"))) || []);

  const addPin = (item: any) => {
    if (!item || find(pinnedItems, { label: item.label })) {
      return;
    }
    item.pinned = true;
    const newPinnedItems = pinnedItems ? pinnedItems.concat(item) : [item];
    setPinnedItems(newPinnedItems);
    localStorage.setItem("pinnedItems", JSON.stringify(newPinnedItems));
  };

  const removePin = (item: any) => {
    const pinIndex = findIndex(pinnedItems, { label: item.label });
    if (pinIndex > -1) {
      item.pinned = false;
      const newPinnedItems = pinnedItems;
      newPinnedItems.splice(pinIndex, 1);
      setPinnedItems([...newPinnedItems]);
      localStorage.setItem("pinnedItems", JSON.stringify(newPinnedItems));
    }
  };

  function alterLinksWithCurrentState(
    links: EuiPinnableListGroupItemProps[],
    showPinned = false
  ): EuiPinnableListGroupItemProps[] {
    return links.map((link) => {
      const { pinned, ...rest } = link;
      return {
        pinned: showPinned ? pinned : false,
        ...rest,
      };
    });
  }

  function addLinkNameToPinTitle(listItem: EuiPinnableListGroupItemProps) {
    return `Pin ${listItem.label} to top`;
  }

  function addLinkNameToUnpinTitle(listItem: EuiPinnableListGroupItemProps) {
    return `Unpin ${listItem.label}`;
  }

  const collapsibleNavId = useGeneratedHtmlId({ prefix: "collapsibleNav" });

  const collapsibleNav = (
    <EuiCollapsibleNav
      id={collapsibleNavId}
      aria-label="Main navigation"
      isOpen={navIsOpen}
      className="mtb-side-nav"
      isDocked
      button={
        <EuiHeaderSectionItemButton
          aria-label="Toggle main navigation"
          onClick={() => setNavIsOpen(!navIsOpen)}
        >
          <EuiIcon type={"menu"} size="m" aria-hidden="true" />
        </EuiHeaderSectionItemButton>
      }
      onClose={() => setNavIsOpen(false)}
    >
      {/* Shaded pinned section always with a home item */}
      <EuiFlexItem grow={false} style={{ flexShrink: 0 }}>
        <EuiCollapsibleNavGroup
          background="light"
          className="eui-yScroll"
          style={{ maxHeight: "40vh" }}
        >
          <EuiPinnableListGroup
            aria-label="Pinned links" // A11y : Since this group doesn't have a visible `title` it should be provided an accessible description
            listItems={alterLinksWithCurrentState(TopLinks).concat(
              alterLinksWithCurrentState(pinnedItems, true)
            )}
            unpinTitle={addLinkNameToUnpinTitle}
            onPinClick={removePin}
            maxWidth="none"
            color="text"
            gutterSize="none"
            size="s"
          />
        </EuiCollapsibleNavGroup>
      </EuiFlexItem>

      <EuiHorizontalRule margin="none" />

      {/* BOTTOM */}
      <EuiFlexItem className="eui-yScroll">
        {/* General section */}
        <EuiCollapsibleNavGroup
          title="Quản lý"
          buttonElement="div"
          iconType="usersRolesApp"
          isCollapsible={true}
          initialIsOpen={openGroups.includes("General")}
          onToggle={(isOpen: boolean) => toggleAccordion(isOpen, "General")}
        >
          <EuiPinnableListGroup
            aria-label="General" // A11y : EuiCollapsibleNavGroup can't correctly pass the `title` as the `aria-label` to the right HTML element, so it must be added manually
            listItems={alterLinksWithCurrentState(generalLinks(profile))}
            pinTitle={addLinkNameToPinTitle}
            onPinClick={addPin}
            maxWidth="none"
            color="subdued"
            gutterSize="none"
            size="s"
          />
        </EuiCollapsibleNavGroup>

        {/* Configuration section */}
        <EuiCollapsibleNavGroup
          title="Cấu hình chung"
          buttonElement="div"
          iconType="managementApp"
          isCollapsible={true}
          initialIsOpen={openGroups.includes("Configuration")}
          onToggle={(isOpen: boolean) =>
            toggleAccordion(isOpen, "Configuration")
          }
        >
          <EuiPinnableListGroup
            aria-label="Configuration" // A11y : EuiCollapsibleNavGroup can't correctly pass the `title` as the `aria-label` to the right HTML element, so it must be added manually
            listItems={alterLinksWithCurrentState(ConfigurationLinks(profile))}
            pinTitle={addLinkNameToPinTitle}
            onPinClick={addPin}
            maxWidth="none"
            color="subdued"
            gutterSize="none"
            size="s"
          />
        </EuiCollapsibleNavGroup>
      </EuiFlexItem>
    </EuiCollapsibleNav>
  );
  if (profile) {
    return collapsibleNav;
  }
  return null;
};

export default SideNav;
