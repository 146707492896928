export const AUTH_UPDATE_TOKEN = "auth.update_token";
export const AUTH_UPDATE_PROFILE = "auth.update_profile";
export const LOGIN = "auth.login";
export const LOGIN_SUCCESS = "auth.login_success";
export const LOGIN_FAILED = "auth.login_failed";
export const CHECK_AUTHENTICATION = "auth.check_authentication";
export const LOGOUT = "auth.logout";
export const AUTH_SET_LOADING = "auth.set_loading";
export const AUTH_FORGOT_PASSWORD = "auth.forgot_password";
export const AUTH_FORGOT_PASSWORD_UPDATE = "auth.forgot_password_update";
export const AUTH_RESET_PASSWORD = "auth.reset_password";
export const SESSION_EXPIRED = "auth.session_expired";
export const SESSION_EXPIRED_SUCCESS = "auth.session_expired_success";
// User
export const GET_LIST_USER = "user.get_list_user";
export const GET_LIST_USER_SUCCESS = "user.get_list_user_success";
export const GET_LIST_USER_FAILED = "user.get_list_user_failed";
export const SET_LOADING_USER = "user.set_loading";
export const DELETE_ONE_USER = "user.delete_one";
export const DELETE_MANY_USER = "user.delete_many";
export const ARCHIVE_MANY_USER = "user.archive_many";
export const UNARCHIVE_MANY_USER = "user.unarchive_many";

export const GET_DETAIL_USER = "user.get_detail_user";
export const GET_DETAIL_USER_SUCCESS = "user.get_detail_user_success";
export const GET_DETAIL_USER_FAILED = "user.get_detail_user_failed";

export const UPDATE_DETAIL_USER = "user.update_detail_user";
export const UPDATE_DETAIL_USER_SUCCESS = "user.update_detail_user_success";
export const UPDATE_DETAIL_USER_FAILED = "user.update_detail_user_failed";

export const CREATE_USER = "user.create_user";
export const CREATE_SUCCESS = "user.create_user_success";
export const CREATE_USER_FAILED = "user.create_user_failed";

// Role
export const GET_LIST_ROLE = "role.get_list_role";
export const GET_LIST_ROLE_SUCCESS = "role.get_list_role_success";
export const GET_LIST_ROLE_FAILED = "role.get_list_role_failed";

export const SET_LOADING_ROLE = "role.set_loading";
export const DELETE_ONE_ROLE = "role.delete_one";
export const DELETE_MANY_ROLE = "role.delete_many";
export const ARCHIVE_MANY_ROLE = "role.archive_many";
export const UNARCHIVE_MANY_ROLE = "role.unarchive_many";

export const GET_DETAIL_ACTION_ROLE = "role.get_detail_action";
export const GET_DETAIL_ACTION_ROLE_SUCCESS = "role.get_detail_action_success";
export const GET_DETAIL_PERMISSION_ROLE = "role.get_detail_permission";
export const GET_DETAIL_PERMISSION_ROLE_SUCCESS =
  "role.get_detail_permission_success";
export const GET_DETAIL_ROLE = "role.get_detail_user";
export const GET_DETAIL_ROLE_SUCCESS = "role.get_detail_role_success";
export const GET_DETAIL_ROLE_FAILED = "role.get_detail_role_failed";

export const CREATE_ROLE = "role.create_role";
export const CREATE_ROLE_SUCCESS = "role.create_role_success";
export const CREATE_ROLE_FAILED = "role.create_role_failed";

export const UPDATE_ROLE = "role.update_role";
export const UPDATE_ROLE_SUCCESS = "role.update_role_success";
export const UPDATE_ROLE_FAILED = "role.update_role_failed";

// Permission
export const GET_LIST_PERMISSION = "permission.get_permission";
export const GET_LIST_PERMISSION_SUCCESS = "permission.get_permission_success";
export const GET_LIST_PERMISSION_FAILED = "permission.get_permission_failed";

export const SET_LOADING_PERMISSION = "permission.set_loading";
export const DELETE_ONE_PERMISSION = "permission.delete_one";
export const DELETE_MANY_PERMISSION = "permission.delete_many";
export const ARCHIVE_MANY_PERMISSION = "permission.archive_many";
export const UNARCHIVE_MANY_PERMISSION = "permission.unarchive_many";

export const CREATE_PERMISSION = "permission.create_permission";
export const CREATE_PERMISSION_SUCCESS = "permission.create_permission_success";
export const CREATE_PERMISSION_FAILED = "permission.create_permission_failed";

export const GET_DETAIL_PERMISSION = "permission.get_detail_permission";
export const GET_DETAIL_PERMISSION_SUCCESS =
  "permission.get_detail_permission_success";
export const GET_DETAIL_PERMISSION_FAILED =
  "permission.get_detail_permission_failed";

export const UPDATE_PERMISSION = "permission.update_permission";
export const UPDATE_PERMISSION_SUCCESS = "permission.update_permission_success";
export const UPDATE_PERMISSION_FAILED = "permission.update_permission_failed";

// notification
export const NOTIFICATION = "common.notification";

// profile
export const GET_USER_PROFILE = "profile.get_user_profile";
export const GET_USER_PROFILE_SUCCESS = "profile.get_user_profile_success";
export const GET_USER_PROFILE_FAILED = "profile.get_user_profile_failed";

export const UPDATE_FILTERS = "profile.update_filters";
export const UPDATE_FILTERS_FAILED = "profile.update_filter_failed";

// action
export const GET_LIST_ACTION = "action.get_list_action";
export const GET_LIST_ACTION_SUCCESS = "action.get_list_action_success";
export const GET_LIST_ACTION_FAILED = "action.get_list_action_failed";

//#region room
export const GET_LIST_ROOM = "room.get_list_room";
export const GET_LIST_ROOM_SUCCESS = "room.get_list_room_success";
export const GET_LIST_ROOM_FAILED = "room.get_list_room_failed";
export const CREATE_ROOM = "room.create_room";
export const CREATE_ROOM_FAILED = "room.create_room_failed";
export const UPDATE_DETAIL_ROOM = "room.update_room";
export const ARCHIVE_MANY_ROOM = "room.archive_many";
export const UNARCHIVE_MANY_ROOM = "room.unarchive_many";
export const GET_DETAIL_ROOM = "room.get_detail";
export const DELETE_ONE_ROOM = "room.delete_one";
export const DELETE_MANY_ROOM = "room.delete_many";
export const SET_LOADING_ROOM = "room.set_loading";
export const GET_DETAIL_ROOM_SUCCESS = "room.get_detail_room_success";
export const GET_DETAIL_ROOM_FAILED = "room.get_detail_room_failed";
export const UPDATE_DETAIL_ROOM_SUCCESS = "room.update_room_success";
export const UPDATE_DETAIL_ROOM_FAILED = "room.update_room_failed";
//#endregion

//#region subject
export const GET_LIST_SUBJECT = "subject.get_list_subject";
export const GET_LIST_SUBJECT_SUCCESS = "subject.get_list_subject_success";
export const GET_LIST_SUBJECT_FAILED = "subject.get_list_subject_failed";
export const CREATE_SUBJECT = "subject.create_subject";
export const CREATE_SUBJECT_FAILED = "subject.create_subject_failed";
export const UPDATE_DETAIL_SUBJECT = "subject.update_subject";
export const ARCHIVE_MANY_SUBJECT = "subject.archive_many";
export const UNARCHIVE_MANY_SUBJECT = "subject.unarchive_many";
export const GET_DETAIL_SUBJECT = "subject.get_detail";
export const DELETE_ONE_SUBJECT = "subject.delete_one";
export const DELETE_MANY_SUBJECT = "subject.delete_many";
export const SET_LOADING_SUBJECT = "subject.set_loading";
export const GET_DETAIL_SUBJECT_SUCCESS = "subject.get_detail_subject_success";
export const GET_DETAIL_SUBJECT_FAILED = "subject.get_detail_subject_failed";
export const UPDATE_DETAIL_SUBJECT_SUCCESS = "subject.update_subject_success";
export const UPDATE_DETAIL_SUBJECT_FAILED = "subject.update_subject_failed";
//#endregion

//department
export const ARCHIVE_MANY_DEPARTMENT = "department.archive_many";
export const CREATE_DEPARTMENT = "department.create";
export const CREATE_DEPARTMENT_SUCCESS = "department.create_success";
export const CREATE_DEPARTMENT_FAILED = "department.create_failed";
export const DELETE_MANY_DEPARTMENT = "department.delete_many";
export const DELETE_ONE_DEPARTMENT = "department.delete_one";
export const GET_DETAIL_DEPARTMENT = "department.get_detail";
export const GET_DETAIL_DEPARTMENT_SUCCESS = "department.get_detail_success";
export const GET_DETAIL_DEPARTMENT_FAILED = "department.get_detail_failed";
export const GET_LIST_DEPARTMENT = "department.get_list";
export const GET_LIST_DEPARTMENT_SUCCESS = "department.get_list_success";
export const GET_LIST_DEPARTMENT_FAILED = "department.get_list_failed";
export const SET_LOADING_DEPARTMENT = "department.set_loading";
export const UNARCHIVE_MANY_DEPARTMENT = "department.unarchive_many";
export const UPDATE_DETAIL_DEPARTMENT = "department.update_detail";
export const UPDATE_DETAIL_DEPARTMENT_SUCCESS =
  "department.update_detail_success";
export const UPDATE_DETAIL_DEPARTMENT_FAILED =
  "department.update_detail_failed";

// school hour
export const ARCHIVE_MANY_SCHOOL_HOUR = "school_hour.archive_many";
export const CREATE_SCHOOL_HOUR = "school_hour.create";
export const CREATE_SCHOOL_HOUR_FAILED = "school_hour.create_failed";
export const DELETE_MANY_SCHOOL_HOUR = "school_hour.delete_many";
export const DELETE_ONE_SCHOOL_HOUR = "school_hour.delete_one";
export const GET_DETAIL_SCHOOL_HOUR = "school_hour.get_detail";
export const GET_DETAIL_SCHOOL_HOUR_FAILED = "school_hour.get_detail_failed";
export const GET_DETAIL_SCHOOL_HOUR_SUCCESS = "school_hour.get_detail_success";
export const GET_LIST_SCHOOL_HOUR = "school_hour.get_list";
export const GET_LIST_SCHOOL_HOUR_SUCCESS = "school_hour.get_list_success";
export const GET_LIST_SCHOOL_HOUR_FAILED = "school_hour.get_list_failed";
export const SET_LOADING_SCHOOL_HOUR = "school_hour.set_loading";
export const UNARCHIVE_MANY_SCHOOL_HOUR = "school_hour.unarchive_many";
export const UPDATE_DETAIL_SCHOOL_HOUR = "school_hour.update_detail";
export const UPDATE_DETAIL_SCHOOL_HOUR_FAILED =
  "school_hour.update_detail_failed";

// teacher role
//department
export const ARCHIVE_MANY_TEACHER_ROLE = "teacher_role.archive_many";
export const CREATE_TEACHER_ROLE = "teacher_role.create";
export const CREATE_TEACHER_ROLE_SUCCESS = "teacher_role.create_success";
export const CREATE_TEACHER_ROLE_FAILED = "teacher_role.create_failed";
export const DELETE_MANY_TEACHER_ROLE = "teacher_role.delete_many";
export const DELETE_ONE_TEACHER_ROLE = "teacher_role.delete_one";
export const GET_DETAIL_TEACHER_ROLE = "teacher_role.get_detail";
export const GET_DETAIL_TEACHER_ROLE_SUCCESS =
  "teacher_role.get_detail_success";
export const GET_DETAIL_TEACHER_ROLE_FAILED = "teacher_role.get_detail_failed";
export const GET_LIST_TEACHER_ROLE = "teacher_role.get_list";
export const GET_LIST_TEACHER_ROLE_SUCCESS = "teacher_role.get_list_success";
export const GET_LIST_TEACHER_ROLE_FAILED = "teacher_role.get_list_failed";
export const SET_LOADING_TEACHER_ROLE = "teacher_role.set_loading";
export const UNARCHIVE_MANY_TEACHER_ROLE = "teacher_role.unarchive_many";
export const UPDATE_DETAIL_TEACHER_ROLE = "teacher_role.update_detail";
export const UPDATE_DETAIL_TEACHER_ROLE_SUCCESS =
  "teacher_role.update_detail_success";
export const UPDATE_DETAIL_TEACHER_ROLE_FAILED =
  "teacher_role.update_detail_failed";

// employee
export const ARCHIVE_MANY_EMPLOYEE = "employee.archive_many";
export const CREATE_EMPLOYEE = "employee.create";
export const CREATE_EMPLOYEE_FAILED = "employee.create_failed";
export const DELETE_MANY_EMPLOYEE = "employee.delete_many";
export const DELETE_ONE_EMPLOYEE = "employee.delete_one";
export const GET_DETAIL_EMPLOYEE = "employee.get_detail";
export const GET_DETAIL_EMPLOYEE_FAILED = "employee.get_detail_failed";
export const GET_DETAIL_EMPLOYEE_SUCCESS = "employee.get_detail_success";
export const GET_LIST_EMPLOYEE = "employee.get_list";
export const GET_LIST_EMPLOYEE_SUCCESS = "employee.get_list_success";
export const GET_LIST_EMPLOYEE_FAILED = "employee.get_list_failed";
export const SET_LOADING_EMPLOYEE = "employee.set_loading";
export const UNARCHIVE_MANY_EMPLOYEE = "employee.unarchive_many";
export const UPDATE_DETAIL_EMPLOYEE = "employee.update_detail";
export const UPDATE_DETAIL_EMPLOYEE_FAILED = "employee.update_detail_failed";

// teacher
export const ARCHIVE_MANY_TEACHER = "teacher.archive_many";
export const CREATE_TEACHER = "teacher.create";
export const CREATE_TEACHER_SUCCESS = "teacher.create_success";
export const CREATE_TEACHER_FAILED = "teacher.create_failed";
export const DELETE_MANY_TEACHER = "teacher.delete_many";
export const DELETE_ONE_TEACHER = "teacher.delete_one";
export const GET_DETAIL_TEACHER = "teacher.get_detail";
export const GET_DETAIL_TEACHER_SUCCESS = "teacher.get_detail_success";
export const GET_DETAIL_TEACHER_FAILED = "teacher.get_detail_failed";
export const GET_LIST_TEACHER = "teacher.get_list";
export const GET_LIST_TEACHER_SUCCESS = "teacher.get_list_success";
export const GET_LIST_TEACHER_FAILED = "teacher.get_list_failed";
export const SET_LOADING_TEACHER = "teacher.set_loading";
export const UNARCHIVE_MANY_TEACHER = "teacher.unarchive_many";
export const UPDATE_DETAIL_TEACHER = "teacher.update_detail";
export const UPDATE_DETAIL_TEACHER_SUCCESS = "teacher.update_detail_success";
export const UPDATE_DETAIL_TEACHER_FAILED = "teacher.update_detail_failed";
