import {
  EuiButton,
  EuiPageContentBody,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import { useForm } from "react-hook-form";
import CustomizeForm from "../../../components/common/form";
import { validation } from "../../../utils";
import { ActionTypes, Paths } from "../../../constants";
import { IUpsertLocation } from "../../../typing/location";
import CustomBackPage from "../../../components/Page/Header";
import PDW from "../../../components/ProvinceDistrictWard";
import { createLocation } from "../services";
import history from "../../../navigation";
import { useState } from "react";
import { useDispatch } from "react-redux";


const Create: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);

  const { handleSubmit, control, formState: { errors }, setValue, getValues } = useForm<IUpsertLocation>({
    defaultValues: {
      name: "",
      code: "",
      address: "",
      provinceId: "",
      districtId: "",
      wardId: "",
      fullAddress: "",
      parkingAddress: "",
      hotline: ""
    },
    resolver: validation.location.create(),
  });

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await createLocation(data);
      dispatch({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: "Cập nhật thông tin thành công",
          message: data?.message || "",
          type: "success",
        },
      })
      history.push("/locations");
    } catch (e) {
      dispatch({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: "Cập nhật thông tin thất bại",
          message: `${e}` || "",
          type: "danger",
        },
      })
    } finally {
      setLoading(false);
    }
  };

  const ars = [
    { name: "name", label: "Tên địa điểm", type: "input" },
    { name: "code", label: "Mã địa điểm", type: "input" },
    { name: "parkingAddress", label: "Địa điểm đỗ xe", type: "input" },
    { name: "hotline", label: "Đường dây nóng", type: "input" },
  ];

  return (
    <>
      <CustomBackPage pageTitle="Thêm địa điểm mới" pagePath={Paths.LOCATION.LIST} />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {ars.map((item, index: number) => (
              <CustomizeForm
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                type={item.type}
              />
            ))}
            <PDW type="CREATE" control={control} errors={errors} setValue={setValue} getValues={getValues} />
            <EuiButton isLoading={isLoading} type="submit" fill >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Create;
