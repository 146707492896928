import {
  ARCHIVE_MANY_USER,
  CREATE_USER,
  DELETE_MANY_USER,
  DELETE_ONE_USER,
  GET_DETAIL_USER,
  GET_LIST_USER,
  SET_LOADING_USER,
  UNARCHIVE_MANY_USER,
  UPDATE_DETAIL_USER,
} from '../../constants';
import {
  IArchiveManyPayloadUser,
  IDeleteManyPayloadUser,
  IDeleteOnePayloadUser,
  ILoadingUser,
  IUnarchiveManyPayloadUser,
} from '../types';

export const getListUser = (payload: any) => {
  return {
    type: GET_LIST_USER,
    payload,
  };
};

export const getDetailUser = (payload: any) => {
  return {
    type: GET_DETAIL_USER,
    payload,
  };
};

export const updateDetailUser = (payload: any) => {
  return {
    type: UPDATE_DETAIL_USER,
    payload,
  };
};

export const createUser = (payload: any) => {
  return {
    type: CREATE_USER,
    payload,
  };
};

export const deleteOneUser = (payload: IDeleteOnePayloadUser) => {
  return {
    type: DELETE_ONE_USER,
    payload,
  };
};

export const deleteManyUser = (payload: IDeleteManyPayloadUser) => {
  return {
    type: DELETE_MANY_USER,
    payload,
  };
};

export const archiveManyUser = (payload: IArchiveManyPayloadUser) => {
  return {
    type: ARCHIVE_MANY_USER,
    payload,
  };
};

export const unarchiveManyUser = (payload: IUnarchiveManyPayloadUser) => {
  return {
    type: UNARCHIVE_MANY_USER,
    payload,
  };
};

export const setLoadingUser = (payload: ILoadingUser) => {
  return {
    type: SET_LOADING_USER,
    payload,
  };
};
