import {
  EuiButton,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import isEmpty from "lodash/isEmpty";
import React, { FC, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomizeForm from "../../../components/common/form";
import { Paths } from "../../../constants";
import {
  getDetailDepartment,
  updateDetailDepartment,
} from "../../../redux/actions/department";
import { validation } from "../../../utils";
import { getDepartmentsApi, getEmployeesApi } from '../../../network';

interface IFormInput {
  name: string;
  parentDepartmentId: string;
  employeeIdManagement: string;
  isDefault: boolean;
  isSpecialize: boolean;
  isOperator: boolean;
}

interface IEmployee {
  _id: string;
  name: string;
}

interface IDepartment {
  _id: string;
  name: string;
}

const Edit: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id }: any = useParams();

  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [employeeSelected, setEmployeeSelected] = useState<any>([]);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [departmentSelected, setDepartmentSelected] = useState<any>([]);

  const departmentState = useSelector(
    (state: RootStateOrAny) => state.department
  );

  const departmentDetail = useSelector(
    (state: RootStateOrAny) => state.department.detail.departments
  );

  const getListEmployee = async (searchValue?: string) => {
    const responseEmployee = await getEmployeesApi({name: searchValue});
    if (responseEmployee && responseEmployee.data) {
      setEmployees(responseEmployee.data);
    }
  }

  const getListDepartment = async (searchValue?: string) => {
    const response = await getDepartmentsApi({name: searchValue});
    if (response && response.data) {
      setDepartments(response.data);
    }
  }

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { ...departmentDetail },
    resolver: validation.department.edit(),
  });

  useEffect(() => {
    reset({});
    dispatch(getDetailDepartment(id));
    getListEmployee();
    getListDepartment();
  }, []);

  useEffect(() => {
    if (!isEmpty(departmentDetail) && departmentDetail._id === id) {
      reset({ ...departmentDetail });

      if (departmentDetail?.employeeManagement) {
        setEmployeeSelected([{
          _id: departmentDetail.employeeManagement?._id,
          value: departmentDetail.employeeManagement?._id,
          label: departmentDetail.employeeManagement?.name,
        }]);
      }

      if (departmentDetail?.parentDepartment) {
        setDepartmentSelected([{
          _id: departmentDetail?.parentDepartment?._id,
          value: departmentDetail?.parentDepartment?._id,
          label: departmentDetail?.parentDepartment?.name,
        }]);
      }
    }
  }, [departmentDetail]);

  const onSubmit = (data: any) => {
    console.log({data});
    dispatch(updateDetailDepartment({ departmentId: id, data}));
  };

  const employeeOptions = [
    ...employees.filter((item: any) => item._id !== departmentDetail?.employeeManagement?._id),
  ];
  if (departmentDetail?.employeeManagement?._id) {
    employeeOptions.unshift({
      _id: departmentDetail.employeeManagement?._id,
      name: departmentDetail.employeeManagement?.name,
    });
  }

  const departmentOptions = [
    {_id: '', name: 'Chọn'},
    ...departments.filter((item: any) => item._id !== id),
  ];
  if (departmentDetail?.parentDepartment?._id) {
    departmentOptions.unshift({
      _id: departmentDetail?.parentDepartment?._id,
      name: departmentDetail?.parentDepartment?.name,
    });
  }

  const formFields = [
    { name: "name", label: "Tên", type: "input" },
    {
      name: "parentDepartmentId",
      label: "Thuộc phòng ban",
      type: "selectSearch",
      fullWidth: true,
      options: departmentOptions
        .filter((item: any) => item._id !== departmentDetail.parentDepartmentId)
        .map((item: any) => ({
          id: item._id,
          value: item._id,
          label: item.name,
      })),
      customSelected: departmentSelected,
      // onSearchChange: getListDepartment,
      onChangeValue: (value: string) => {
        setDepartmentSelected(null);
        setValue('parentDepartmentId', value || '');
      }
    },
    {
      name: "employeeIdManagement",
      label: "Nhân viên quản lý",
      type: "selectSearch",
      required: true,
      fullWidth: true,
      options: employeeOptions.map((item: any) => ({
        id: item._id,
        value: item._id,
        label: item.name,
      })),
      customSelected: employeeSelected,
      // onSearchChange: getListEmployee,
      onChangeValue: (value: string) => {
        setEmployeeSelected(null);
        setValue('employeeIdManagement', value || '');
      }
    },
    {
      id: "isDefault",
      name: "isDefault",
      label: "Phòng ban cơ bản",
      type: "checkbox",
    },
    {
      id: "isSpecialize",
      name: "isSpecialize",
      label: "Phòng ban chuyên môn",
      type: "checkbox",
    },
    {
      name: "isOperator",
      label: "Phòng ban vận hành",
      type: "checkbox",
    },
  ];

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Cập nhật"
        rightSideItems={[
          <EuiButton
            iconSide="left"
            iconType="arrowLeft"
            onClick={() => navigate(Paths.DEPARTMENT.LIST)}
          >
            Quay lại
          </EuiButton>,
        ]}
      />
      <EuiSpacer />
      <EuiPageContentBody>
        <EuiPanel hasBorder>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formFields.map((item: any, index: number) => (
              <CustomizeForm
                {...item}
                key={index}
                name={item.name}
                control={control}
                label={item.label}
                errors={errors}
                isDisable={item?.isDisable || false}
                type={item.type}
                options={item.options}
              />
            ))}

            <EuiButton
              isLoading={departmentState?.isUpdateRequest || false}
              type="submit"
              fill
            >
              Lưu
            </EuiButton>
          </form>
        </EuiPanel>
      </EuiPageContentBody>
    </>
  );
};

export default Edit;
