import React from 'react';
import { CheckboxGroupForm } from './CheckboxGroupForm';
import { InputForm } from './InputForm';
import { SelectForm } from './SelectForm';
import { TextAreaForm } from './TextAreaForm';
import { PasswordForm } from './PasswordForm';
import { InputNumberForm } from './NumberForm';
import { SelectSearchForm } from './SelectSearchForm';
import { CheckboxForm } from './Checkbox';
import { TimeForm } from './Time';
import { DateForm } from './Date';
import LocationForTeaching from '../../Location';

interface ICustomizeForm {
  key?: any;
  control: any;
  name: string;
  require?: boolean | false;
  label: string;
  errors: any;
  isDisable?: boolean | false;
  type: string;
  options?: any;
  initValue?: any;
  actionType?: "CREATE" | "READ" | "UPDATE";
  mode?: 'multiple' | 'single' | any;
  [key: string]: any,
}

export default function CustomizeForm(props: ICustomizeForm) {
  switch (props.type) {
    case "input":
    case "text":
      return (
        <InputForm
          name={props.name}
          control={props.control}
          label={props.label}
          errors={props.errors}
          isDisable={props?.isDisable || false}
        />
      );
    case "password":
      return (
        <PasswordForm
          name={props.name}
          control={props.control}
          label={props.label}
          errors={props.errors}
          isDisable={props?.isDisable || false}
          initValue={props?.initValue}
        />
      );
    case "number":
      return (
        <InputNumberForm
          name={props.name}
          control={props.control}
          label={props.label}
          errors={props.errors}
          isDisable={props?.isDisable || false}
        />
      );
    case "textarea":
      return (
        <TextAreaForm
          name={props.name}
          control={props.control}
          label={props.label}
          errors={props.errors}
          isDisable={props?.isDisable || false}
          require={props?.require || false}
        />
      );
    case "selectSearch":
      return (
        <SelectSearchForm
          {...props}
          customSelected={props.customSelected}
          setCustomSelected={props.setCustomSelected}
          onChangeValue={props.onChangeValue}
          name={props.name}
          control={props.control}
          label={props.label}
          errors={props.errors}
          isDisable={props?.isDisable || false}
          options={props.options || []}
          fullWidth={props.fullWidth || false}
        />);
    case "location":
      return (
        <LocationForTeaching
            mode={props.mode}
          name={props.name}
          control={props.control}
          label={props.label}
          errors={props.errors}
          isDisable={props?.isDisable || false}
        />
      )
    case "select":
      return (
        <SelectForm
          name={props.name}
          control={props.control}
          label={props.label}
          errors={props.errors}
          isDisable={props?.isDisable || false}
          options={props.options || []}
        />
      );
    case "checkboxGroup":
      return (
        <CheckboxGroupForm
          name={props.name}
          control={props.control}
          label={props.label}
          errors={props.errors}
          isDisable={props?.isDisable || false}
          options={props.options}
        />
      );
    case "checkbox":
      return (
        <CheckboxForm
          {...props}
          name={props.name}
          control={props.control}
          label={props.label}
          errors={props.errors}
          isDisable={props?.isDisable || false}
        />
      );
    case "time":
      return (
        <TimeForm
          {...props}
          name={props.name}
          control={props.control}
          label={props.label}
          errors={props.errors}
          isDisable={props?.isDisable || false}
        />
      );
    case "date":
      return (
        <DateForm
          {...props}
          name={props.name}
          control={props.control}
          label={props.label}
          errors={props.errors}
          isDisable={props?.isDisable || false}
        />
      );
    default:
      return <></>;
  }
}
