import { EuiPageBody, EuiPageContent } from "@elastic/eui";
import { FC, useState } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "./Header";

export const MainLayout: FC = () => {
  const [navIsOpen, setNavIsOpen]: any = useState(false);

  return (
    <>
      <Header
        setNavIsOpen={(isOpen: boolean) => setNavIsOpen(isOpen)}
        navIsOpen={navIsOpen}
      />

      <EuiPageBody className="legacy-page-body">
        <EuiPageContent>
          <Outlet />
        </EuiPageContent>
      </EuiPageBody>
    </>
  );
};
