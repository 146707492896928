/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { SelectSearchForm } from "../common/form/SelectSearchForm";
import { getListLocation } from "./services";
import { ILocationQueryParams } from "./typing";

const LocationForTeaching: React.FC<ILocationQueryParams> = (props) => {
  const [isLoaded, setisLoaded] = useState<boolean>(false);
  const [listLocation, setListLocation] = useState<any[]>([]);

  useEffect(() => {
    (async function fetchList() {
      setisLoaded(false)
      const locations = await getListLocation({ limit: props.limit || 1000 });
      setListLocation(locations?.data?.map((p: any) => {
        return {
          id: p._id,
          label: p.fullAddress,
        }
      }) || [])
      setisLoaded(true)
    })();
  }, []);

  return <>{isLoaded ?
    <SelectSearchForm
      fullWidth={true}
      mode={'single'}
      control={props.control}
      name={props.name}
      label={props.label}
      errors={props?.errors}
      options={listLocation}
    /> : null}</>
}

export default LocationForTeaching