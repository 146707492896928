/* eslint-disable react-hooks/exhaustive-deps */
import { EuiBadge, EuiButton, EuiPageHeader, EuiSpacer } from "@elastic/eui";
import i18n from "i18n-js";
import { FC, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomizeFilter,
  CustomizeTablePagination,
  DeleteAction,
  EditAction,
  FilterInput,
  Access,
} from "../../../components";
import { Paths, PERMISSIONS } from "../../../constants";
import {
  archiveManyUser,
  deleteManyUser,
  deleteOneUser,
  getListUser,
  unarchiveManyUser,
} from "../../../redux/actions/user";
import { getInitParams, formatToLocalDate } from "../../../utils";

const List: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState(getInitParams());

  useEffect(() => {
    dispatch(getListUser(params));
  }, [params]);

  const { users, total } = useSelector(
    (state: RootStateOrAny) => state.user.list
  );

  const { isLoadingRequest } = useSelector(
    (state: RootStateOrAny) => state.user
  );

  const onChangePage = (value: any) => {
    setParams({ ...params, offset: value });
  };

  const onDeleteMany = (ids: string[]) => {
    dispatch(deleteManyUser({ ids }));
  };

  const onArchiveMany = (ids: string[]) => {
    dispatch(archiveManyUser({ ids, isArchived: true }));
  };

  const onUnarchiveMany = (ids: string[]) => {
    dispatch(unarchiveManyUser({ ids, isArchived: false }));
  };

  const columns = [
    // {
    //   field: "code",
    //   name: "Mã NV",
    //   sortable: false,
    //   render: (code: string) => <EuiBadge color="warning">{code}</EuiBadge>,
    // },
    {
      field: "name",
      name: "Tên",
      sortable: false,
    },
    {
      field: "username",
      name: "Tên đăng nhập",
    },
    {
      field: "email",
      name: "Email",
      sortable: false,
    },
    {
      field: "phone",
      name: "Số ĐT",
      sortable: false,
    },
    {
      field: "status",
      name: "Trạng thái",
      sortable: false,
      // width: 100,
      render: (status: string) => {
        let color = "primary";
        let name = "Khởi tạo";
        switch (status) {
          case "ACTIVE": {
            name = "Hoạt động";
            color = "success";
            break;
          }
          case "BLOCKED": {
            color = "danger";
            name = "Đã khoá";
            break;
          }
          case "DELETED": {
            color = "danger";
            name = "Đã xoá";
            break;
          }
        }

        return <EuiBadge color={color}>{name}</EuiBadge>;
      },
    },
    // {
    //   field: "isArchived",
    //   name: "Lưu trữ",
    //   sortable: false,
    //   width: 120,
    //   render: (isArchived: boolean) => (
    //     <EuiBadge color={isArchived ? "warning" : "ghost"}>
    //       {isArchived ? "Đã lưu trữ" : "Không"}
    //     </EuiBadge>
    //   ),
    // },
    {
      field: "createdAt",
      name: "Khởi tạo",
      render: (time: string) => <p>{formatToLocalDate(time)}</p>,
    },
    {
      field: "updatedAt",
      name: "Cập nhật",
      render: (time: string) => <p>{formatToLocalDate(time)}</p>,
    },
    {
      name: "Hành động",
      actions: [
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.USER_UPDATE} >
                <EditAction link={Paths.USER.EDIT.replace(":id", item._id)} />
              </Access>
            );
          },
        },
        {
          render: (item: any) => {
            return (
              <Access permissionKey={PERMISSIONS.USER_DELETE} >
                <DeleteAction
                  onOk={() => {
                    dispatch(deleteOneUser({ id: item._id }));
                  }}
                />
              </Access>
            );
          },
        },
      ],
    },
  ];

  const onChangeItemsPerPage = (per: any) => {
    setParams({ ...params, limit: per });
  };

  const filterInputs: FilterInput[] = [
    { name: "name", label: "Tên", type: "input", visible: true },
    // { name: "username", label: "Tên đăng nhập", type: "input", visible: true },
    { name: "email", label: "Email", type: "input", visible: true },
    { name: "phone", label: "Số điện thoại", type: "input" },
    {
      name: "status",
      label: "Trạng thái",
      type: "select",
      options: [
        { value: "", text: "Tất cả" },
        { value: "ACTIVE", text: "Hoạt động" },
        { value: "BLOCKED", text: "Đã khoá" },
      ],
    },
    {
      name: "isArchived",
      label: "Lưu trữ",
      type: "select",
      options: [
        { value: "", text: "Tất cả" },
        { value: "true", text: "Đã lưu trữ" },
        { value: "false", text: "Không" },
      ],
    },
  ];

  const onFilter = (data: any) => {
    const newFilter: any = { ...params, ...data };
    for (let key in newFilter) {
      if (newFilter[key] === "" || newFilter[key] === undefined) {
        delete newFilter[key];
      }
    }
    setParams({ ...newFilter, offset: 0 });
  };

  return (
    <>
      <EuiPageHeader
        bottomBorder
        className="mtb-page-header"
        pageTitle="Quản lý người dùng"
        rightSideItems={[
          <Access permissionKey={PERMISSIONS.USER_CREATE} >
            <EuiButton
              iconSide="left"
              iconType="plusInCircle"
              fill
              onClick={() => navigate({ pathname: Paths.USER.CREATE })}
            >
              {i18n.t("common.createButton")}
            </EuiButton>
          </Access>,
        ]}
      />
      <EuiSpacer size="s" />

      <CustomizeFilter
        list={filterInputs}
        onFilter={onFilter}
        page={Paths.USER.LIST}
      />

      <EuiSpacer size="s" />

      <CustomizeTablePagination
        columns={columns}
        body={users}
        isLoading={isLoadingRequest}
        pagination={params}
        total={total}
        isSelectable={true}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onDeleteMany={onDeleteMany}
        onArchiveMany={onArchiveMany}
        onUnarchiveMany={onUnarchiveMany}
      />
    </>
  );
};

export default List;
