import i18n from "i18n-js";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { ActionTypes, Paths } from "../../constants";
import * as actionTypes from "../../constants/ActionTypes";
import history from "../../navigation";
import {
  archiveManyTeacherRoleApi,
  createTeacherRoleApi,
  deleteManyTeacherRoleApi,
  deleteTeacherRoleApi,
  getTeacherRoleDetailApi,
  getTeacherRolesApi,
  updateTeacherRoleApi,
} from "../../network";
import { convertObjectParamsToStringParams, getInitParams } from "../../utils";
import teacherRole from "../reducers/teacher-role";
import { getTeacherRoleState } from "../selectors";
import { ITeacherRoleState } from "../types";

interface IPayload {
  type: string;
  payload: any;
}
interface IResponse {
  data: any;
  total: number;
}

function* getListTeacherRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacherRole: ITeacherRoleState = yield select<any>(
      getTeacherRoleState
    );

    // validate and set requesting
    if (teacherRole.isLoadingRequest) return;
    yield put({
      type: ActionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isLoadingRequest: true },
    });

    const { data, total }: IResponse = yield call(
      getTeacherRolesApi,
      action.payload
    );
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`);
    yield put({
      type: ActionTypes.GET_LIST_TEACHER_ROLE_SUCCESS,
      payload: { data, total },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_LIST_TEACHER_ROLE_FAILED,
      payload: error,
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isLoadingRequest: false },
    });
  }
}

function* createTeacherRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacherRole: ITeacherRoleState = yield select<any>(
      getTeacherRoleState
    );

    // validate and set requesting
    if (teacherRole.isCreateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isCreateRequest: true },
    });

    // call api create
    const { data, total }: IResponse = yield call(
      createTeacherRoleApi,
      action.payload
    );

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    });

    // navigate
    history.push("/teacher-roles");
  } catch (error) {
    yield put({ type: actionTypes.CREATE_TEACHER_ROLE_FAILED, payload: error });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isCreateRequest: false },
    });
  }
}

function* deleteOneTeacherRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacherRole: ITeacherRoleState = yield select<any>(
      getTeacherRoleState
    );

    // validate and set requesting
    if (teacherRole.isDeleteRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isDeleteRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      deleteTeacherRoleApi,
      action.payload.id
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_TEACHER_ROLE,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isDeleteRequest: false },
    });
  }
}

function* updateDetailTeacherRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacherRole: ITeacherRoleState = yield select<any>(
      getTeacherRoleState
    );

    // validate and set requesting
    if (teacherRole.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isUpdateRequest: true },
    });

    // call api update
    const { data, total }: IResponse = yield call(
      updateTeacherRoleApi,
      action.payload.teacherRoleId,
      action.payload.data
    );

    // yield put({
    //   type: actionTypes.UPDATE_DETAIL_USER_SUCCESS,
    // });

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    });
    history.push(Paths.TEACHER_ROLE.LIST);
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_DETAIL_TEACHER_ROLE_FAILED,
      payload: error,
    });
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isUpdateRequest: false },
    });
  }
}

function* getDetailTeacherRole(action: IPayload) {
  try {
    const { data, total }: IResponse = yield call(
      getTeacherRoleDetailApi,
      action.payload
    );

    yield put({
      type: actionTypes.GET_DETAIL_TEACHER_ROLE_SUCCESS,
      payload: { data, total },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DETAIL_TEACHER_ROLE_FAILED,
      payload: error,
    });
  }
}

function* deleteManyTeacherRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacherRole: ITeacherRoleState = yield select<any>(
      getTeacherRoleState
    );

    // validate and set requesting
    if (teacherRole.isDeleteRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isDeleteRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      deleteManyTeacherRoleApi,
      action.payload.ids
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_TEACHER_ROLE,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    console.log(error);
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isDeleteRequest: false },
    });
  }
}

function* archiveManyTeacherRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacherRole: ITeacherRoleState = yield select<any>(
      getTeacherRoleState
    );

    // validate and set requesting
    if (teacherRole.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isUpdateRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      archiveManyTeacherRoleApi,
      action.payload
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_TEACHER_ROLE,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    console.log(error);
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isUpdateRequest: false },
    });
  }
}

function* unarchiveManyTeacherRole(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacherRole: ITeacherRoleState = yield select<any>(
      getTeacherRoleState
    );

    // validate and set requesting
    if (teacherRole.isUpdateRequest) return;
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isUpdateRequest: true },
    });

    // call api delete
    const { data, total }: IResponse = yield call(
      archiveManyTeacherRoleApi,
      action.payload
    );

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      });

      // reload list
      yield put({
        type: actionTypes.GET_LIST_TEACHER_ROLE,
        payload: getInitParams(),
      });
    }
  } catch (error: any) {
    console.log(error);
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "danger",
      },
    });
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_TEACHER_ROLE,
      payload: { isUpdateRequest: false },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_LIST_TEACHER_ROLE, getListTeacherRole),
    takeLatest(actionTypes.GET_DETAIL_TEACHER_ROLE, getDetailTeacherRole),
    takeLatest(ActionTypes.CREATE_TEACHER_ROLE, createTeacherRole),
    takeLatest(actionTypes.DELETE_ONE_TEACHER_ROLE, deleteOneTeacherRole),
    takeLatest(actionTypes.UPDATE_DETAIL_TEACHER_ROLE, updateDetailTeacherRole),
    takeLatest(actionTypes.DELETE_MANY_TEACHER_ROLE, deleteManyTeacherRole),
    takeLatest(actionTypes.ARCHIVE_MANY_TEACHER_ROLE, archiveManyTeacherRole),
    takeLatest(
      actionTypes.UNARCHIVE_MANY_TEACHER_ROLE,
      unarchiveManyTeacherRole
    ),
  ]);
}
